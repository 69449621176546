<script>

export default {
    props: {
        compra: { type: Object }
    },
    data() {
        return {
            viewAll: false,
            visibleTransp: true,
            fields: [
                { label: "ID", key: "id", sortable: true },
                { label: "Nome do Produto", key: "produto_nome", sortable: true },
                { label: "Quantidade", key: "quantidade", sortable: true },
                { label: "Preço", key: "preco", sortable: true, formatter: "formatCurrency" },
                { label: "Custo", key: "custo", sortable: true, formatter: "formatCurrency" },
                { label: "Referência", key: "referencia", sortable: true},
                { label: "Lote", key: "lote", sortable: true},
            ],
        }
   },
   computed: {
       apelidoFornecedor() {
           return (this.compra.fornecedor.pes_apelido.length <= 23) ? this.compra.fornecedor.pes_apelido : this.compra.fornecedor.pes_apelido.substring(0,21)+"..";
       }
   },
   methods: {
        getViewAll() {
            this.viewAll = !this.viewAll;
        },
        // eslint-disable-next-line no-unused-vars
        formatCurrency(value, key, item) {
            return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
        },
   },
}
</script>

<template>
<div class="vds-bodycontents vds-detailbodycontents">
    <div class="detailBody">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-8 col-12 px-3 pl-4 py-3 mb-2 bg-grey-hue-7">
                    <div class="container-fluid d-flex flex-row flex-wrap mb-3 bg-white border border-grey-0 rounded-4 pr-0">
                        <div class="d-flex pt-1 my-1 px-0 justify-content-between w-100">
                            <div class="col p-0 d-flex flex-row align-items-center flex-wrap">
                                <div class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Nome do Fornecedor">Fornecedor <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="compra.fornecedor.pes_apelido" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ apelidoFornecedor }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Série">Série <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="'Série '+compra.serie" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ compra.serie }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Entrada/Saída">Entrada/Saída <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div title="Tipo NF: Entrada" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                Entrada
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Data Emissão">Data Emissão <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="'Data Emissão '+compra.dataemissao" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ compra.dataemissao }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="viewAll" class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Data Saída">Data Saída <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="'Data Saída '+compra.datasaida" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ compra.datasaida }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="viewAll" class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Base do ICMS">Base do ICMS <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="'Bc ICMS '+formatterCurrBR(parseFloat(compra.baseicms))" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ formatterCurrBR(parseFloat(compra.baseicms)) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="viewAll" class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Valor do ICMS">Valor do ICMS <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="'Vlr ICMS '+formatterCurrBR(parseFloat(compra.valoricms))" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ formatterCurrBR(parseFloat(compra.valoricms)) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="viewAll" class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Bc ICMS ST">Bc ICMS ST  <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="'Bc ICMS ST '+formatterCurrBR(parseFloat(compra.baseicmssubs))" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ formatterCurrBR(parseFloat(compra.baseicmssubs)) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="viewAll" class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Valor do ICMS ST">Valor ICMS ST  <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="'Vlr ICMS ST '+formatterCurrBR(parseFloat(compra.valoricmssubs))" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ formatterCurrBR(parseFloat(compra.valoricmssubs)) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="viewAll" class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Valor Imp. Importação">Valor Imp. Importação  <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="'Vlr II '+formatterCurrBR(parseFloat(compra.vii))" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ formatterCurrBR(parseFloat(compra.vii)) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="viewAll" class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Valor Total dos Produtos">Valor Total dos Produtos <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="'Vlr Prod '+formatterCurrBR(parseFloat(compra.valortotalprod))" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ formatterCurrBR(parseFloat(compra.valortotalprod)) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="viewAll" class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Valor Frete">Valor Total do Frete <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="'Vlr Frete '+formatterCurrBR(parseFloat(compra.vfrete))" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ formatterCurrBR(parseFloat(compra.vfrete)) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="viewAll" class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Valor Seguro">Valor Seguro <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="'Vlr Seguro '+formatterCurrBR(parseFloat(compra.vseg))" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ formatterCurrBR(parseFloat(compra.vseg)) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="viewAll" class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Valor Desconto">Valor Total dos Descontos <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="'Vlr Desc '+formatterCurrBR(parseFloat(compra.vdesc))" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ formatterCurrBR(parseFloat(compra.vdesc)) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="viewAll" class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Valor Outras Despesas">Valor Outras Despesas <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="'Vlr Outros '+formatterCurrBR(parseFloat(compra.voutro))" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ formatterCurrBR(parseFloat(compra.voutro)) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="viewAll" class="pr-3 headerField detailKeyFields">
                                    <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                                        <div class="flex flex-column col-9 mw-col-10 p-0 pl-2">
                                            <div class="label font-12 text-grey-2 p-0 d-flex">
                                                <label title="Valor IPI">Valor IPI <span class="text-warning mr-1">*</span></label>
                                            </div>
                                            <div :title="'Vlr IPI '+formatterCurrBR(parseFloat(compra.vipi))" class="d-flex align-items-center col-12 p-0 text-truncate">
                                                {{ formatterCurrBR(parseFloat(compra.vipi)) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-pointer mr-2 moreHeaderFields mt-2">
                                <div @click="getViewAll()" class="fa-sm p-1-5 border border-grey-0 rounded-4 fa" :class="(viewAll) ? 'fa-chevron-up text-blue-5' : 'fa-chevron-down'" style="cursor: pointer"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row bg-white">
                        <div class="container">
                            <div class="table-responsive">
                                <b-table
                                 :items="compra.entradas"
                                 :fields="fields"
                                 responsive="sm"
                                 :per-page="10"
                                >
                                    <template #cell(produto_nome)="row">
                                        {{ row.item.produto.produto_nome }}
                                    </template>
                                    <template #cell(quantidade)="row">
                                        {{ row.item.quantidade.toLocaleString(undefined, {minimumFractionDigits: 3}) }}/{{ row.item.produto.um }}
                                    </template>
                                    <template #cell(lote)="row">
                                        {{ row.index+1 }}{{ Math.floor(Math.random() * 1000) }}
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-grey-hue-7 col-lg-4 col-12 r-0 pr-0 pl-0">
                    <div class="d-flex h-100">
                        <div class="flex-grow-1 px-3 pb-3 pt-3 overflow-y mr-5 pr-4">
                            <div id="viewList">
                                <div class="d-flex justify-content-between align-items-center pb-2">
                                    <div class="d-flex font-16">
                                        detalhamento...
                                    </div>
                                </div>
                                <div class="mb-3 rounded-6 bg-white border-grey-0 py-1">
                                    <div class="mb-3 rounded-6 bg-white border-grey-0 py-1">
                                        <div @click="visibleTransp = !visibleTransp" v-b-toggle.collapse-2 class="py-2 mx-3 d-flex justify-content-between align-items-center bg-white">
                                            <h5 href="#collapsableRelatedBlock110" class="c-pointer mb-0 d-flex w-100 align-items-center" data-original-title="" title="" aria-controls="collapsableRelatedBlock110" aria-expanded="true" role="button">
                                                <span v-if="!visibleTransp" class="when-opened mr-1"><i class="fa fa-chevron-down fa-sm"></i></span>
                                                <span v-if="visibleTransp" class="when-closed mr-2"><i class="fa fa-chevron-right fa-sm"></i></span>
                                                <span class="font-15">
                                                    Transportadora
                                                    <!---->
                                                </span>
                                            </h5>
                                        </div>
                                        <b-collapse id="collapse-2">
                                            <div>
                                                <div role="tab" class="border-top border-grey-10 rounded-0 bg-white">
                                                    <div class="d-flex mt-0 mw-col-12 col-12 pl-0 pr-2 c-pointer align-items-center bg-white collapsed" aria-controls="relatedId_497" aria-expanded="false" role="button">
                                                        <div class="py-2 ml-3 mr-2 widget-image">
                                                            <div aria-hidden="true" :title="compra.transp_xnome" class="text-capitalize text-white circle-icon circle-icon-widget-md border-grey-6 p-0" style="background-color: rgb(229, 115, 115);">
                                                                <div class="h4 mt-1">
                                                                    <i class="font-16 fa-accounts"></i>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </div>
                                                        <div class="col-10 pr-0 pb-2 mt-1 p-0">
                                                            <h6 class="d-flex align-items-center mb-1">
                                                                <div :title="compra.transp_xnome" class="text-truncate font-13 c-pointer">{{ compra.transp_xnome }}</div>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!---->
                                        </b-collapse>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.mw-col-10 {
    max-width: 83.33333%;
}
.vds-detailbodycontents {
    position: relative;
}
.vds-bodycontents.vds-detailbodycontents {
    top: auto;
}
.detailBody {
    width: 100%;
}
.bg-grey-hue-7 {
    background-color: #F9F9FA !important
}
.bg-white {
    background-color: #fff!important;
}
.border-grey-0 {
    border: 1px solid #f0f1f3 !important;
}
.border-grey-8 {
    border-color: #D5D7D9 !important;
}
.text-blue-5 {
    color: #3978FE;
}
.text-grey-2 {
    color: #7B7F85;
}
.rounded-4 {
    border-radius: 0.25rem !important;
}
.rounded-6 {
    border-radius: 6px !important;
}
.font-12 {
    font-size: 12px !important;
}
.font-16 {
    font-size: 16px;
}
</style>