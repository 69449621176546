<template>
  <!-- <h1>Estou no PDF</h1> -->
  <div>

  </div>
</template>

<script>
import { http } from "@/helpers/easyindustriaapi/config";
export default {
    props:{
        objeto_show_pdf:{type: Object}
    },
  data() {
    return {
      objeto_pdf: null,
    };
  },
  created() {
   this.objeto_pdf = this.objeto_show_pdf
    
  },
  mounted() {
    this.post_display_nfe_pdf(this.objeto_pdf);
  },
  methods: {
    async post_display_nfe_pdf(objeto) {
      console.log("Estou no post download");
      console.log(objeto);

      try {
        const res = await http.post("nfe/downloadXml", objeto, {
          responseType: "arraybuffer",
        });

        // Converta os dados do arraybuffer para um blob
        const blob = new Blob([res.data], { type: "application/pdf" });

        // Crie um URL para o blob
        const url = window.URL.createObjectURL(blob);

        // Abra o PDF na mesma aba
        window.open(url, "_self");

        // Revogue o URL do objeto após o uso
        window.URL.revokeObjectURL(url);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
  },
};
</script>

<style>
</style>