<template>
  <div class="mb-4">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.devolucao variant="light"
        >Iformações de Devolução</b-button
      >
    </b-card-header>
    <b-collapse id="devolucao" accordion="pedido-accordion" role="tabpanel">
      <div class="row">
        <div class="col-md-12">
          <Label>{{ "*Chave" }}</Label>
          <input
            type="text"
            placeholder="Digite a Chave"
            v-model="devolucao.chave"
            class="form-control text-left"
            @input="recebe_vlr_devolucao()"
          />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      devolucao: {
        chave: null,
      },
      
    };
  },
  methods: {
    recebe_vlr_devolucao() {
     
      this.$emit("recebe_vlr_devolucao", this.devolucao);
    },
  },
};
</script>

<style>
</style>