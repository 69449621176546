<template>
  <b-modal
    v-model="abrir1"
    size="xl"
    title="Manifestos"
    hide-footer
    @hidden="back()"
  >
    <div class="card">
      <div class="card-body">
        <div class="row mb-4 d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <button class="btn btn-secondary" @click="importa_pedido_xml()">
              Upload
            </button>
          </div>
        </div>
        <div class="row" v-if="upload_bool">
          <div class="col-md-12">
            <b-table
              bordered
              hover
              :items="files_array"
              id="doc-itens"
              :fields="fields"
            >
            <template #cell(check)="row">
              <div>
                   <b-form-checkbox v-model="row.item.check" @change="seleciona_xml_fora_bd(row.item,row.item.check,row.index)">

                   </b-form-checkbox>
              </div>
            </template>
            </b-table>
          </div>
        </div>
        <div class="row" v-if="boll_arquivos">
          <div class="col-md-12">
            <b-table
              bordered
              hover
              :items="files_array_get"
              id="doc-itens"
              :fields="fields2"
            >
              <template #cell(created_at)="row">
                <div>
                  <b-badge
                    data-name="Total"
                    class="field-status"
                    variant="info"
                  >
                    {{ formatDateTimeBR(row.item.created_at) }}
                  </b-badge>
                </div>
              </template>
              <template #cell(nome)="row">
                <div>
                  <b-badge
                    data-name="Total"
                    class="field-status"
                    variant="primary"
                  >
                    {{ row.item.nome }}
                  </b-badge>
                </div>
              </template>
              <template #cell(checkbox)="row">
                <div>
                  <b-form-checkbox
                    v-model="row.item.checkbox"
                    name="checkbox-1"
                    @change="seleciona_check(row.item,row.item.checkbox, row.index)"
                  >
                    
                  </b-form-checkbox>
                </div>
              </template>
              <template #cell(acoes)="row">
                <!-- <div>
                <button class="btn btn-secondary" @click.prevent="selecinando_xml_nfe(row.item)">Selecionar</button>
              </div> -->
                <b-dropdown
                  v-bind:id="'dropdown-' + row.item.id"
                  class="m-md-2"
                >
                  <template #button-content>
                    <i data-v-6289eca4="" class="bx ri-menu-line"></i>
                  </template>
                  <b-dropdown-item @click="selecinando_xml_nfe(row.item)"
                    ><i class="bx ri-eraser-fill"></i>
                    Selecionar</b-dropdown-item
                  >
                  <b-dropdown-item @click="selecinando_criar_pre_nfe(row.item)"
                    ><i class="bx ri-eraser-fill"></i>Criar um
                    Pre-nfe</b-dropdown-item
                  >
                  <b-dropdown-item
                    @click="selecinando_criar_pre_nfe_todos(row.item)"
                    ><i class="bx ri-eraser-fill"></i>Todos
                    Xmls</b-dropdown-item
                  >
                  <b-dropdown-item @click="exluir(row.item)"
                    ><i class="bx ri-eraser-fill"></i>Exlcuir</b-dropdown-item
                  >
                </b-dropdown>
              </template>
            </b-table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <input
              @change="handleFileChange"
              type="file"
              id="fileInput"
              style="display: none"
              webkitdirectory
              directory
              multiple
            />
          </div>
        </div>
        <div
          class="row mt-4 d-flex justify-content-center align-items-center"
        >
          <div class="col-md-12 text-center" v-if="upload_bool">
            <button class="btn btn-secondary" @click.prevent="submitFiles()">
              Enviar
            </button>
          </div>
        </div>
        <div
          class="row mt-4 d-flex justify-content-center align-items-center"
          v-if="vetor_new_pre_nfe.length > 0"
        >
          <div class="col-md-12 text-center">
            <button class="btn btn-secondary" @click.prevent="envia_pre_nfe_selecionadados()">
              Enviar Xml Selec.
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { http } from "../../../../helpers/easyindustriaapi/config";
export default {
  props: {
    abrir: { type: Boolean },
    Lista_Produtos: { type: Array },
    pessoas_vet: { type: Array },
  },
  data() {
    return {
      //arquivos:[]
      array_pessoas:[],
      produtos_vet:[],
      array_files_sem_check:[],
      files_array2:[],
      upload_bool:false,
      vetor_new_pre_nfe:[],
      abrir1: false,
      boll_arquivos: false,
      files_array: [],
      files_array_get: [],
      name: "ola",
      fields: [
        {
          label: "Selecione",
          key: "check",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Nome",
          key: "name",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Tamanho",
          key: "size",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Data",
          key: "lastModifiedDate",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      fields2: [
        {
          label: "Selecione",
          key: "checkbox",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Nome",
          key: "nome",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "light",
        },
        {
          label: "Data",
          key: "created_at",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "light",
        },
        {
          label: "Açoes",
          key: "acoes",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "light",
        },
      ],
    };
  },
  created() {
    //this.files_array = this.files;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.abrir1 = this.abrir;
    this.produtos_vet = this.Lista_Produtos
    this.array_pessoas = this.pessoas_vet
  },
  mounted() {
    this.get_arquivos();
  },
  methods: {
    // usado para selecionar a list de xmls que NÂO estão no banco
    seleciona_xml_fora_bd(objeto, status, index){
      console.log("estou no selecionda fora do BD")
      //console.log(objeto)
      // console.log(status)
      // console.log(index)
     // if()
    //  // this.files_array = []
    //  if(objeto.file instanceof File){
    //    console.log("tem o obejto")
    //  }


    //  let file= new File(["conteúdo"], `${objeto.name}`, {
    //     type: `${objeto.type}`,
    //     size:`${objeto.size}`
    // })
   // console.log(file)
   console.log(index)
       console.log("mostrando o array sme o check33");
       console.log(this.array_files_sem_check)
      delete objeto.check;
      if(status == true){
        this.files_array2.push({id:index})
       }else {
        this.files_array2.splice(index,1)
       }
       console.log(this.files_array2)
    },
    // usado para selecionar a list de xmls que ja estão no banco
    seleciona_check(objeto, status, index){
       console.log("Checkbox selector")
       console.log(objeto)
       console.log(status)
       console.log(index)

       if(status === true){
        this.vetor_new_pre_nfe.push({...objeto})
       }else if(status !== true) {
       // this.vetor_new_pre_nfe.splice(index,1)
       this.vetor_new_pre_nfe = this.vetor_new_pre_nfe.filter(item => item.id !== objeto.id);
       }
       
      // this.$emit("selecinando_xml_nfe",objeto)
      console.log(this.vetor_new_pre_nfe)
     
    },
    envia_pre_nfe_selecionadados(){
        this.vetor_new_pre_nfe.forEach((f) => {
        this.ler_xml_criar_nfe(f);
      });
    },
    exluir(obejto) {
      console.log("exluir xml uploadaddo");
      console.log(obejto);
    },
    back() {
      this.$emit("back2");
    },
    selecinando_xml_nfe(objeto) {
      console.log("mostrando conteudo no obejto de aqruivos de BD ");
      console.log(objeto);
      this.$emit("selecinando_xml_nfe", objeto);
    },
    selecinando_criar_pre_nfe(objeto) {
      console.log("mostrando Pre_nfe");
      console.log(objeto);
      // this.$emit("selecinando_xml_nfe",objeto)
      this.ler_xml_criar_nfe(objeto);
    },
    selecinando_criar_pre_nfe_todos(objeto) {
      console.log("mostrando Pre_nfe");
      console.log(objeto);
      // this.$emit("selecinando_xml_nfe",objeto)
      this.files_array_get.forEach((f) => {
        this.ler_xml_criar_nfe(f);
      });
    },
    async salvarNfe(objeto) {
      // objeto= this.Nfe
      objeto.empresa_id = this.currentEmpresa.id;
      objeto.status = 2;
      console.log("estuo em salvar Nfe não autorizadas");
      console.log(objeto);
      try {
        let response = await http.post(
          "nfe/salvarnfe?empresa_id=" + this.currentEmpresa.id,
          objeto
        );
        if (response.status == 200) {
          // console.log(response.data);
          // let msg = response.data;
          console.log(response.data);
          this.makeToast("success", "Registros salvos");
        }
      } catch (e) {
        console.log(e.data);
        this.makeToast("danger", e.data);
      }
    },
    importa_pedido_xml() {
      this.files_array = []
      this.upload_bool = true;
      this.boll_arquivos = false
      document.getElementById("fileInput").click();
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    async submitFiles() {
      const formData = new FormData();
      console.log("mostrando aarray auxliar")
      console.log(this.files_array2);
      console.log(this.files_array);
      if(this.files_array2.length > 0){
        this.files_array = []
        this.files_array2.forEach((f)=>{
          this.files_array.push(this.array_files_sem_check[f.id])
        })
      }
      this.files_array.forEach((file) => {
        formData.append("files[]", file);
      });
      let empresa_id = this.currentEmpresa.id;
      try {
        const response = await http.post(
          `nfe/arquivo?empresa_id=${empresa_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.get_arquivos();
        console.log("Upload successful:", response.data.message);
        this.makeToast("success", response.data.message);

      } catch (error) {
        console.error("Error uploading files:", error);
      }
    },
    async get_arquivos() {
      const formData = new FormData();
      this.files_array.forEach((file) => {
        formData.append("files[]", file);
      });
      let empresa_id = this.currentEmpresa.id;
      try {
        const response = await http.post(
          `nfe/getarquivo?empresa_id=${empresa_id}`
        );
        if (response.status == 200) {
          console.log(response.data);
          this.files_array_get = response.data;
          if (this.files_array_get.length > 0) {
            this.boll_arquivos = true;
            this.upload_bool = false;
          } else {
            this.boll_arquivos = false;
          }
          this.makeToast("success", "carregado vetor de xmls");
        }
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    },
    handleFileChange(event) {
      // não era aqui para fazer esse logica é na função de enviar 
        const file = Array.from(event.target.files);
        this.array_files_sem_check = [...file];
        this.files_array = [...file];
      console.log("estou no hanfle mostrando os files");
      console.log(this.files_array);
      console.log(this.files_array[0].textContent);

      // const formData = new FormData();
      // this.files_array.forEach(file => {
      //   formData.append('files[]', file);
      // });

      // if (file) {
      //   this.readFileContent(file)
      //     .then((content) => {
      //       this.fileContent = content;
      //       this.ler_xml();
      //     })
      //     .catch((error) => {
      //       console.error("Erro ao ler o conteúdo do arquivo:", error);
      //     });
      // }
    },

    readFileContent(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          resolve(e.target.result);
        };

        reader.onerror = (e) => {
          reject(e.target.error);
        };

        reader.readAsText(file);
      });
    },
    ler_xml() {
      // console.log("estou em ler xml ");
      console.log(this.fileContent);
      if (typeof this.fileContent === "string") {
        console.log("é uma srting");
      }
      // xml em string //
      let string_xml = this.fileContent;
      // varivel que vai receber o conteudo convertido de string para xml
      let xmlDocument = new DOMParser().parseFromString(string_xml, "text/xml");
      console.log(xmlDocument);
      let nomes = xmlDocument.querySelectorAll("xProd");
      let listaNomes = [];

      nomes.forEach((nome) => {
        listaNomes.push(nome.textContent);
      });
      console.log(listaNomes);
    },
    ler_xml_criar_nfe(objeto) {
      console.log(objeto);
      // console.log("estou em ler xml ");
      // console.log(this.fileContent);
      let conteudo = objeto.conteudo;
      if (typeof conteudo === "string") {
        console.log("é uma srting");
      }
      // xml em string //
      let string_xml = conteudo;
      // varivel que vai receber o conteudo convertido de string para xml
      let xmlDocument = new DOMParser().parseFromString(string_xml, "text/xml");
      console.log(xmlDocument);
      let nomes = xmlDocument.querySelectorAll("xProd");
      let cProds = xmlDocument.querySelectorAll("cProd");
      let qComs = xmlDocument.querySelectorAll("qCom");
      let listaNomes = [];

      nomes.forEach((nome,index) => {
        let objeto={
          xProd:nome.textContent, 
          cProd:cProds[index].textContent,
          qCom:qComs[index].textContent
        }
        listaNomes.push(
          {...objeto});
      });
      console.log("Mostrando Lista de Obejto de Itens New")
      console.log(listaNomes);

      let emitElement = xmlDocument.querySelector("emit");
      let emitObj = {
        CNPJ: emitElement.querySelector("CNPJ").textContent,
        xNome: emitElement.querySelector("xNome").textContent,
        xFant: emitElement.querySelector("xFant").textContent,
        enderEmit: {
          xLgr: emitElement.querySelector("enderEmit > xLgr").textContent,
          nro: emitElement.querySelector("enderEmit > nro").textContent,
          xCpl: emitElement.querySelector("enderEmit > xCpl").textContent,
          xBairro: emitElement.querySelector("enderEmit > xBairro").textContent,
          cMun: emitElement.querySelector("enderEmit > cMun").textContent,
          xMun: emitElement.querySelector("enderEmit > xMun").textContent,
          UF: emitElement.querySelector("enderEmit > UF").textContent,
          CEP: emitElement.querySelector("enderEmit > CEP").textContent,
          cPais: emitElement.querySelector("enderEmit > cPais").textContent,
          xPais: emitElement.querySelector("enderEmit > xPais").textContent,
        },
      };
      console.log("mostrando os valores do emitente ");
      console.log(emitObj);
      // ---------------------------------------
      let destElement = xmlDocument.querySelector("dest");
      let destObj = {
        CNPJ: destElement.querySelector("CNPJ").textContent,
        xNome: destElement.querySelector("xNome").textContent,
        xFant: destElement.querySelector("xFant").textContent,
        enderDest: {
          xLgr: destElement.querySelector("enderDest > xLgr").textContent,
          nro: destElement.querySelector("enderDest > nro").textContent,
          xCpl: destElement.querySelector("enderDest > xCpl").textContent,
          xBairro: destElement.querySelector("enderDest > xBairro").textContent,
          cMun: destElement.querySelector("enderDest > cMun").textContent,
          xMun: destElement.querySelector("enderDest > xMun").textContent,
          UF: destElement.querySelector("enderDest > UF").textContent,
          CEP: destElement.querySelector("enderDest > CEP").textContent,
          cPais: destElement.querySelector("enderDest > cPais").textContent,
          xPais: destElement.querySelector("enderDest > xPais").textContent,
        },
      };
      console.log("mostrando os valores do destinatario");
      console.log(destObj);
      console.log(destObj.xNome);

      // Processar a tag <ide>
      let ideElement = xmlDocument.querySelector("ide");
      let ideObj = {
        cNF: ideElement.querySelector("cNF").textContent,
        natOp: ideElement.querySelector("natOp").textContent,
        dhEmi: ideElement.querySelector("dhEmi").textContent,
        dSaida: ideElement.querySelector("dSaida").textContent,
      };

      console.log("dados do Ide");
      console.log(ideObj);

      let objeto_nfe_pre = {};
      objeto_nfe_pre.DestxNome = destObj.xNome;
      objeto_nfe_pre.EmitCNPJ = emitObj.CNPJ;
      objeto_nfe_pre.DestCNPJ = destObj.CNPJ;
      let cont_achou =0
      listaNomes.forEach((l)=>{
        let objeto_prod_selecionado = this.produtos_vet.find((p)=>p.produto_cod_int == l.cProd)
        if(objeto_prod_selecionado == undefined){
           this.makeToast("danger", `produto ${l.xProd} não esta cadastrado`)
        }else {
          cont_achou ++; 
        }
      })
      //pes_cpfcnpj
      let selec_cnpj_dest = this.array_pessoas.find((p)=>p.pes_cpfcnpj == destObj.CNPJ)
       
      console.log("Mostrando o Selecionar Dest")
      console.log(selec_cnpj_dest)
        if(selec_cnpj_dest == undefined){
          this.makeToast("danger","Destinatario do XMl não Cadastrado no sistema")
        }
      console.log("mostrando conte achou")
      console.log(cont_achou)
      console.log(this.produtos_vet)
      console.log(this.array_pessoas)

      objeto_nfe_pre.vet2 = listaNomes
      console.log("mostrando objeto depois do ler xml preNfe");
      console.log(objeto_nfe_pre);
       
      if(objeto_nfe_pre.vet2.length > 0){

      
      let new_vet = []
      objeto_nfe_pre.vet2.forEach((item)=>{
        // produto_cod_int
            let selecionado_new = this.produtos_vet.find((p)=>p.produto_cod_int == item.cProd)
           let obj_selecionado = {
            descricao: selecionado_new
           }
           new_vet.push({...obj_selecionado})  
           
      })
      console.log("mostrando new vet ")
      console.log(new_vet)
      objeto_nfe_pre.vet = new_vet
      console.log(objeto_nfe_pre.vet)
    }
      
      
      if(cont_achou > 0 && selec_cnpj_dest != undefined){
        this.salvarNfe(objeto_nfe_pre);
      }
      
    },
  },
};
</script>

<style></style>