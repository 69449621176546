<template>
  <b-modal
    v-model="show_modal"
    size="xxl"
    title="Impostos"
    hide-footer
    @hidden="back()"
  >
    <div class="row">
      <div class="col-md-12">
        <b-card no-body>
          <b-tabs pills card vertical active-nav-item-class="font-weight-bold ">
            <b-tab active>
              <template #title>
                <span
                  >ICMS<span
                    :class="select_cson || select_cst_normal ? '' : 'cor'"
                    >*</span
                  ></span
                >
                <!-- <span :class="select_cson ? '' : 'cor' ">{{ "*ICMS" }}</span> -->
              </template>
              <div class="row">
                <!-- Regime Normal -->
                <div
                  :class="
                    (cst40 == true && cst41 != true) ||
                    cst02 == true ||
                    cst50 == true ||
                    cst90 == true
                      ? 'col-md-6'
                      : 'col-md-4'
                  "
                  v-if="reg_normal"
                >
                  <Label
                    ><b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        select_cst_normal != null && select_cst_normal != ''
                          ? 'success'
                          : 'danger'
                      "
                    >
                      {{ "*CST" }}
                    </b-badge></Label
                  >
                  <multiselect
                    v-model="select_cst_normal"
                    :options="csts_reg_normais"
                    label="nome"
                    track-by="nome"
                    @select="seleciona_cst_normal($event)"
                    :option-height="104"
                    :custom-label="customLabelcsts_normal"
                    :show-labels="false"
                    deselect-label="ENTER para remover"
                    select-label="Pressione ENTER"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option__desc">
                        <span class="option__title"
                          >{{ props.option.id }} - {{ props.option.nome }}</span
                        >
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title"
                          >{{ props.option.id }} - {{ props.option.nome }}</span
                        >
                      </div> </template
                    ><span slot="noResult"
                      >Opa! Nenhum registro encontrado</span
                    >
                  </multiselect>
                </div>
                <!-- Regime Normal  Fim -->
                <div class="col-md-4" v-if="!reg_normal">
                  <!--  CST ou CSON-simples nacional -->
                  <Label
                    ><b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="icms_vetifica ? 'success' : 'danger'"
                    >
                      {{ "*CST" }}
                    </b-badge></Label
                  >
                  <multiselect
                    v-model="select_cson"
                    :options="csts"
                    label="nome"
                    track-by="nome"
                    @select="seleciona_cst($event)"
                    :option-height="104"
                    :custom-label="customLabelcsts"
                    :show-labels="false"
                    deselect-label="ENTER para remover"
                    select-label="Pressione ENTER"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option__desc">
                        <span class="option__title"
                          >{{ props.option.id }} - {{ props.option.nome }}</span
                        >
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title"
                          >{{ props.option.id }} - {{ props.option.nome }}</span
                        >
                      </div> </template
                    ><span slot="noResult"
                      >Opa! Nenhum registro encontrado</span
                    >
                  </multiselect>
                </div>
                <div
                  :class="
                    (cst40 == true && cst41 != true) ||
                    cst02 == true ||
                    cst50 == true ||
                    cst90 == true
                      ? 'col-md-6'
                      : 'col-md-4'
                  "
                >
                  <label for="">Origem da Mercadoria</label>
                  <multiselect
                    v-model="select_origem"
                    :options="origens"
                    label="nome"
                    @select="seleciona_origem($event)"
                  >
                  </multiselect>
                </div>
                <!-- cst51 -->
                <div class="col-md-4" v-if="cst51">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        select_mod_bc_norm !== null && select_mod_bc_norm !== '' && select_mod_bc_norm != -1
                          ? 'success'
                          : 'danger'
                      "
                    >
                      {{ "*Modalidade de BC" }}
                    </b-badge>
                  </Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="select_mod_bc_norm"
                    @change="seleciona_mod_bc_norm($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in modalidades_bcs"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
                <!-- cst51 FIM -->

                <!-- cst41 e 60-->
                <div class="col-md-4" v-if="cst41 || cst60">
                  <Label>{{ "*Repasse de ICMS ST retido" }}</Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="select_repasse_icms"
                    @change="seleciona_repasse_cims($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in repassaes_icms_retidos"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
                <!-- FIM cst41 -->

                <!-- Inicio do cst 20 _ regime Normal -->
                <div class="col-md-4" v-if="cst_icms_20 && !cst40">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        select_mod_bc_norm !== null &&
                        select_mod_bc_norm !== '' &&
                        select_mod_bc_norm != -1
                          ? 'success'
                          : 'danger'
                      "
                    >
                      {{ "*Modalidade de BC" }}
                    </b-badge>
                  </Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="select_mod_bc_norm"
                    @change="seleciona_mod_bc_norm($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in modalidades_bcs"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
                <!-- codigo 101 - inicio -->
                <div class="col-md-4" v-if="codigo_101">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.pCredSN != null && Nfe.pCredSN != ''
                          ? 'success'
                          : 'danger'
                      "
                    >
                      {{ "*Aliquota para Credito Simples Nacional" }}
                    </b-badge>
                  </Label>
                  <input
                    placeholder="Digite"
                    v-model="Nfe.pCredSN"
                    type="text"
                    class="form-control text-left"
                    @input="
                      formatInputFloat($event, Nfe, 'pCredSN'),
                        calculo_cst_101()
                    "
                  />
                </div>
                <div class="col-md-4" v-if="codigo_101">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.vCredICMSSN != null && Nfe.pCredSN != ''
                          ? 'success'
                          : 'danger'
                      "
                    >
                      {{ "*Valor para Credito Simples Nacional" }}
                    </b-badge>
                  </Label>
                  <input
                    placeholder="Digite"
                    v-model="Nfe.vCredICMSSN"
                    type="text"
                    class="form-control text-left"
                    @input="formatInputFloat($event, Nfe, 'vCredICMSSN')"
                  />
                </div>
                <!-- codigo 101 -fim  -->
                <div class="col-md-4" v-if="show_inputs">
                  <!--  -->
                  <label for=""
                    ><b-badge
                      data-name="Total"
                      class="field-status"
                      variant="danger"
                    >
                      {{ "*BC ICMS Retido" }}
                    </b-badge></label
                  >
                  <input
                    v-model="Nfe.vBCSTRet"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'vBCSTRet')"
                  />
                </div>
              </div>
              <!-- continuação de cst 20 -->
              <div class="row mt-4" v-if="cst_icms_20">
                <div class="col-md-3" v-if="!cst00 && !cst40">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant=" Nfe.pRedBC != null && Nfe.pRedBC != '' ? 'success' : 'danger' "
                    >
                    {{ "* % Redução de BC" }}
                    </b-badge>
                  </Label>
                  <input
                    v-model="Nfe.pRedBC"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'pRedBC'), redcalculo($event)"
                  />
                </div>
                <div
                  :class="cst00 == true ? 'col-md-4' : 'col-md-3'"
                  v-if="!cst40"
                >
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.vBC != null && Nfe.vBC != '' ? 'success' : 'danger'
                      "
                    >
                      {{ "*Base de calculo" }}
                    </b-badge>
                  </Label>
                  <input
                    v-model="Nfe.vBC"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vBC')"
                  />
                </div>
                <div
                  :class="cst00 == true ? 'col-md-4' : 'col-md-3'"
                  v-if="!cst40"
                >
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.pICMS != null && Nfe.pICMS != ''
                          ? 'success'
                          : 'danger'
                      "
                    >
                      {{ "*Aliquota" }}
                    </b-badge>
                  </Label>
                  <input
                    v-model="Nfe.pICMS"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="
                      formatInputFloat($event, Nfe, 'pICMS'),
                        calc_valor_vbc_cst00($event)
                    "
                  />
                </div>
                <div
                  :class="cst00 == true ? 'col-md-4' : 'col-md-3'"
                  v-if="!cst40"
                >
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.vICMS != null && Nfe.vICMS != ''
                          ? 'success'
                          : 'danger'
                      "
                    >
                      {{ "*Valor do Icms" }}
                    </b-badge>
                  </Label>
                  <input
                    v-model="Nfe.vICMS"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vICMS')"
                  />
                </div>
              </div>
              <div class="row mt-2" v-if="cst_icms_20">
                <div
                  :class="cst40 == true ? 'col-md-6' : 'col-md-4'"
                  v-if="!cst00"
                >
                  <Label>{{ "*Valor do ICMS Desonerado" }}</Label>
                  <input
                    v-model="Nfe.vICMSDeson"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vICMSDeson')"
                  />
                </div>
                <div
                  :class="cst40 == true ? 'col-md-6' : 'col-md-4'"
                  v-if="!cst00"
                >
                  <Label>{{ "*Motivo da Desoneração" }}</Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="select_mot_deso"
                    @change="seleciona_mot_deso($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in motivos_desonrados"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4" v-if="!cst00 && !cst40">
                  <Label>{{ "*BC FCP" }}</Label>
                  <input
                    v-model="Nfe.vBCFCP"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vBCFCP')"
                  />
                </div>
                <!--
                <div
                  :class="cst40 == true ? 'col-md-12 mt-2' : 'col-md-9 mt-2'"
                  v-if="!cst00"
                >
                  <Label>{{
                    "*Descontar valor do ICMS desonerado do total da NF-e"
                  }}</Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="desc_icms"
                    @change="seleciona_desc_icms($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in desconttos_icms"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div> -->
              </div>
              <!-- para colocar o desconto de icms aqui -->

              <!-- CST40 -->
              <div class="row mt-4" v-if="cst40">
                <div class="col-md-4">
                  <Label>{{ "*Alíquota suportada pelo consumidor" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    v-model="Nfe.pST"
                    @input="formatInputFloat($event, Nfe, 'pST')"
                  />
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Valor do ICMS próprio do substituto" }}</Label>
                  <input
                    v-model="Nfe.vICMSSubstituto"
                    type="text"
                    class="form-control text-left"
                    @input="formatInputFloat($event, Nfe, 'vICMSSubstituto')"
                  />
                </div>
                <div class="col-md-4">
                  <Label>{{
                    "*Percent de redução da base de cálculo efetiva"
                  }}</Label>
                  <input
                    v-model="Nfe.pRedBCEfet"
                    type="text"
                    class="form-control text-left"
                    @input="formatInputFloat($event, Nfe, 'pRedBCEfet')"
                  />
                </div>
              </div>

              <div class="row" v-if="cst40">
                <div class="col-md-4">
                  <Label>{{ "*Valor da base de cálculo efetiva" }}</Label>
                  <input
                    v-model="Nfe.vBCEfet"
                    type="text"
                    class="form-control text-left"
                    @input="formatInputFloat($event, Nfe, 'vBCEfet')"
                  />
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Alíquota do ICMS efetiva" }}</Label>
                  <input
                    v-model="Nfe.pICMSEfet"
                    type="text"
                    class="form-control text-left"
                    @input="formatInputFloat($event, Nfe, 'pICMSEfet')"
                  />
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Valor do ICMS efetivo" }}</Label>
                  <input
                    v-model="Nfe.vICMSEfet"
                    type="text"
                    class="form-control text-left"
                    @input="formatInputFloat($event, Nfe, 'vICMSEfet')"
                  />
                </div>
              </div>

              <!-- Fim cst40 -->
              <!-- continuação cst 20  -->
              <div class="row mt-4" v-if="cst_icms_20">
                <div
                  :class="cst00 == true ? 'col-md-6' : 'col-md-3'"
                  v-if="!cst40"
                >
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.pFCP != null && Nfe.pFCP != ''
                          ? 'success'
                          : 'danger'
                      "
                    >
                      {{ "*% relativo ao FCP" }}
                    </b-badge>
                  </Label>
                  <input
                    v-model="Nfe.pFCP"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="
                      formatInputFloat($event, Nfe, 'pFCP'),
                        calc_vFCP_cst00($event)
                    "
                  />
                </div>
                <div
                  :class="cst00 == true ? 'col-md-6' : 'col-md-3'"
                  v-if="!cst40"
                >
                  <Label>
                    <!-- variavel errada para esse input não é vFCP -->
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.vFCP != null && Nfe.vFCP != ''
                          ? 'success'
                          : 'danger'
                      "
                    >
                      {{ "*% Valor do ICMS relativo ao FCP" }}
                    </b-badge>
                  </Label>
                  <input
                    v-model="Nfe.vFCP"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vFCP')"
                  />
                </div>
                <div
                  :class="cst40 == true ? 'col-md-12 mt-2' : 'col-md-6 '"
                  v-if="!cst00"
                >
                  <Label>{{
                    "*Descontar valor do ICMS desonerado do total da NF-e"
                  }}</Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="desc_icms"
                    @change="seleciona_desc_icms($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in desconttos_icms"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- fim de cst 20 -->

              <!-- cst02 -->
              <div class="row" v-if="cst02">
                <div class="col-md-4">
                  <Label>{{ "*Quantidade Tributada" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Alíquota ad rem do Imposto " }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Valor do ICMS próprio" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
              </div>
              <!-- fim cst02 -->

              <!-- cst41  tem um inut perto da origem da mercadorial-->
              <div class="row mt-2" v-if="cst41">
                <div class="col-md-4">
                  <Label>{{ "*Valor do ICMS Desonerado" }}</Label>
                  <input
                    v-model="Nfe.vICMSDeson"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vICMSDeson')"
                  />
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Motivo da Desoneração" }}</Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="select_mot_deso"
                    @change="seleciona_mot_deso($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in motivos_desonrados"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Alíquota suportada pelo consumidor" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
              </div>
              <div class="row mt-4" v-if="cst41">
                <div class="col-md-4">
                  <Label>{{ "*Valor do ICMS próprio do substituto" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
                <div class="col-md-4">
                  <Label>{{
                    "*Percentual de redução da base de cálculo efet"
                  }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Valor da base de cálculo efetiva" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
              </div>
              <div class="row mt-2" v-if="cst41">
                <div class="col-md-4">
                  <Label>{{ "*Alíquota do ICMS efetiva" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Valor do ICMS efetivo" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
                <div class="col-md-4">
                  <Label>{{
                    "*Desc vlr do ICMS desonerado do total da NF-e"
                  }}</Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="desc_icms"
                    @change="seleciona_desc_icms($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in desconttos_icms"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- FIM cst41 -->

              <!-- cst 50  -->
              <div class="row mt-2" v-if="cst50">
                <div class="col-md-4">
                  <Label>{{ "*Valor do ICMS Desonerado" }}</Label>
                  <input
                    v-model="Nfe.vICMSDeson"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vICMSDeson')"
                  />
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Motivo da Desoneração" }}</Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="select_mot_deso"
                    @change="seleciona_mot_deso($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in motivos_desonrados"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Alíquota suportada pelo consumidor" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
              </div>
              <div class="row mt-4" v-if="cst50">
                <div class="col-md-4">
                  <Label>{{ "*Valor do ICMS próprio do substituto" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
                <div class="col-md-4">
                  <Label>{{
                    "*Percentual de redução da base de cálculo efet"
                  }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Valor da base de cálculo efetiva" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
              </div>
              <div class="row mt-2" v-if="cst50">
                <div class="col-md-4">
                  <Label>{{ "*Alíquota do ICMS efetiva" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Valor do ICMS efetivo" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
                <div class="col-md-4">
                  <Label>{{
                    "*Desc vlr do ICMS desonerado do total da NF-e"
                  }}</Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="desc_icms"
                    @change="seleciona_desc_icms($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in desconttos_icms"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- cst 50 FIM  -->

              <!-- cst 51 Inicio -->
              <div class="row" v-if="cst51">
                <div class="col-md-3">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.pRedBC != null && Nfe.pRedBC != ''
                          ? 'success'
                          : 'danger'
                      "
                    >
                    {{ "* % Redução de BC" }}
                    </b-badge>
                  </Label>
                  <input
                    v-model="Nfe.pRedBC"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'pRedBC')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.vBC != null && Nfe.vBC != ''
                          ? 'success'
                          : 'danger'
                      "
                    >
                    {{ "*Base de calculo" }}
                    </b-badge>
                  </Label>
                  <input
                    v-model="Nfe.vBC"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vBC')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.pICMS != null && Nfe.pICMS != ''
                          ? 'success'
                          : 'danger'
                      "
                    >
                    {{ "*Aliquota" }}
                    </b-badge>
                  </Label>
                  <input
                    v-model="Nfe.pICMS"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'pICMS'), calc_valor_vbc_cst00($event)"
                  />
                </div>
                <div class="col-md-3">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.vICMS != null && Nfe.vICMS != ''
                          ? 'success'
                          : 'danger'
                      "
                    >
                    {{ "*Valor do Icms" }}
                    </b-badge>
                  </Label>
                  <input
                    v-model="Nfe.vICMS"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vICMS')"
                  />
                </div>
              </div>
              <div class="row mt-2" v-if="cst51">
                <div class="col-md-3">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.vICMSDeson != null && Nfe.vICMSDeson != ''
                          ? 'success'
                          : 'danger'
                      "
                    >
                    {{ "*Valor do ICMS Desonerado" }}
                    </b-badge>
                  </Label>
                  <input
                    v-model="Nfe.vICMSDeson"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vICMSDeson')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        select_mot_deso !== null && select_mot_deso !== '' && select_mot_deso !== -1 
                          ? 'success'
                          : 'danger'
                      "
                    >
                    {{ "*Motivo da Desoneração" }}
                    </b-badge>
                  </Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="select_mot_deso"
                    @change="seleciona_mot_deso($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in motivos_desonrados"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.vBCFCP != null && Nfe.vBCFCP != ''  
                          ? 'success'
                          : 'danger'
                      "
                    >
                    {{ "*BC FCP" }}
                    </b-badge>
                  </Label>
                  <input
                    v-model="Nfe.vBCFCP"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vBCFCP')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.pFCP != null && Nfe.pFCP != ''  
                          ? 'success'
                          : 'danger'
                      "
                    >
                    {{ "*% relativo ao FCP" }}
                    </b-badge>
                  </Label>
                  <input
                    v-model="Nfe.pFCP"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'pFCP'), calc_vFCP_cst00($event)"
                  />
                </div>
              </div>
              <div class="row mt-2" v-if="cst51">
                <div class="col-md-6">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        Nfe.pFCP != null && Nfe.pFCP != ''  
                          ? 'success'
                          : 'danger'
                      "
                    >
                    {{ "*% Valor do ICMS relativo ao FCP" }}
                    </b-badge>
                  </Label>
                  <input
                    v-model="Nfe.vFCP"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vFCP')"
                  />
                </div>
                <div class="col-md-6">
                  <Label>{{
                    "*Descontar valor do ICMS desonerado do total da NF-e"
                  }}</Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="desc_icms"
                    @change="seleciona_desc_icms($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in desconttos_icms"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- cst 51 FIM -->
              <!-- cst 60 -->
              <div class="row" v-if="cst60">
                <div class="col-md-3">
                  <label for="">{{ "*BC ICMS retido" }}</label>
                  <input
                    type="text"
                    placeholder="Digite"
                    class="form-control text-left"
                  />
                </div>
                <div class="col-md-3">
                  <label for="">{{ "*Valor do ICMS retido" }}</label>
                  <input
                    v-model="Nfe.vICMSSTRet"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'vICMSSTRet')"
                  />
                </div>
                <div class="col-md-3">
                  <label for="">{{ "*BC FCP retido" }}</label>
                  <input
                    v-model="Nfe.vBCFCPST"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'vBCFCPST')"
                  />
                </div>
                <div class="col-md-3">
                  <label for="">{{ "*% FCP retido" }}</label>
                  <input
                    v-model="Nfe.pFCPST"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'pFCPST')"
                  />
                </div>
              </div>
              <div class="row" v-if="cst60">
                <div class="col-md-4">
                  <Label>{{ "*Valor do FCP retido*" }}</Label>
                  <input
                    v-model="Nfe.vFCPST"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'vFCPST')"
                  />
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Alíquota suportada pelo consumidor" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>

                <div class="col-md-4">
                  <Label>{{ "*Valor do ICMS próprio do substituto" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                  />
                </div>
              </div>
              <div class="row" v-if="cst60">
                <div class="col-md-4">
                  <Label>{{
                    "*Percentual de redução da base de cálculo efet"
                  }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                  />
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Valor da base de cálculo efetiva" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                  />
                </div>
                <div class="col-md-4">
                  <Label>{{ "*Alíquota do ICMS efetiva" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
              </div>

              <div class="row" v-if="cst60">
                <div class="col-md-4">
                  <Label>{{ "*Valor do ICMS efetivo" }}</Label>
                  <input type="text" class="form-control text-left" />
                </div>
              </div>
              <!-- cst 60  FIM-->

              <!-- cst90 -->
              <div class="row" v-if="cst90">
                <div class="col-md-3">
                  <Label>{{ "* % Redução de BC" }}</Label>
                  <input
                    v-model="Nfe.pRedBC"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'pRedBC')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Base de calculo" }}</Label>
                  <input
                    v-model="Nfe.vBC"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vBC')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Aliquota" }}</Label>
                  <input
                    v-model="Nfe.pICMS"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="
                      formatInputFloat($event, Nfe, 'pICMS'),
                        calc_valor_vbc_cst00($event)
                    "
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Valor do Icms" }}</Label>
                  <input
                    v-model="Nfe.vICMS"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vICMS')"
                  />
                </div>
              </div>
              <div class="row" v-if="cst90">
                <div class="col-md-3">
                  <Label>
                    <b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="
                        select_mod_bc_norm !== null && select_mod_bc_norm !== ''
                          ? 'success'
                          : 'danger'
                      "
                    >
                      {{ "*Modalidade de BC" }}
                    </b-badge>
                  </Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="select_mod_bc_norm"
                    @change="seleciona_mod_bc_norm($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in modalidades_bcs"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Modalidade BC para ICMS ST" }}</Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="select_mod_bc_st"
                    @change="seleciona_mod_bc_st($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in modalidades_bcs_sts"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <Label>{{ "% adicionado do ICMS ST*" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    v-model="Nfe.pICMSST"
                    @input="formatInputFloat($event, Nfe, 'pICMSST')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "% de redução BC ICMS ST*" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    v-model="Nfe.pRedBCST"
                    @input="formatInputFloat($event, Nfe, 'pRedBCST')"
                  />
                </div>
              </div>
              <div class="row" v-if="cst90">
                <div class="col-md-3">
                  <Label>{{ "*BC ICMS ST" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    v-model="Nfe.vBCST"
                    @input="formatInputFloat($event, Nfe, 'vBCST')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Alíquota ICMS ST" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    v-model="Nfe.pICMSST"
                    @input="formatInputFloat($event, Nfe, 'pICMSST')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Valor do ICMS ST" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    v-model="Nfe.vICMSST"
                    @input="formatInputFloat($event, Nfe, 'vICMSST')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*UF ICMS ST" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                  />
                </div>
              </div>
              <div class="row" v-if="cst90">
                <div class="col-md-3">
                  <Label>{{ "*Valor do ICMS desonerado" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Valor do ICMS Desonerado" }}</Label>
                  <input
                    v-model="Nfe.vICMSDeson"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vICMSDeson')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Motivo da Desoneração" }}</Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="select_mot_deso"
                    @change="seleciona_mot_deso($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in motivos_desonrados"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <Label>{{ "*BC da operação própria" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                  />
                </div>
              </div>
              <div class="row" v-if="cst90">
                <div class="col-md-3">
                  <Label>{{ "*BC FCP" }}</Label>
                  <input
                    v-model="Nfe.vBCFCP"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vBCFCP')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "% relativo ao FCP*" }}</Label>
                  <input
                    v-model="Nfe.pFCP"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'pFCP')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Valor do ICMS relativo ao FCP" }}</Label>
                  <input
                    v-model="Nfe.vFCP"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vFCP')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*BC FCP ST" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                  />
                </div>
              </div>
              <div class="row" v-if="cst90">
                <div class="col-md-3">
                  <Label>{{ "% FCP ST*" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Valor do FCP ST" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Valor do ICMS- ST desonerado" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Motivo da deson do ICMS- ST" }}</Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="select_mot_deso_st"
                    @change="seleciona_mot_deso_st($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in motivos_desonrados_sts"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- FIM cst90 -->
              <div class="row mt-4" v-if="show_inputs">
                <div class="col-md-3">
                  <!-- <Label>{{ "*Valor do ICMS Retido" }}</Label> -->
                  <label for=""
                    ><b-badge
                      data-name="Total"
                      class="field-status"
                      variant="danger"
                    >
                      {{ "*Valor do ICMS Retido" }}
                    </b-badge></label
                  >
                  <input
                    v-model="Nfe.vICMSSTRet"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'vICMSSTRet')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*BC FCP retido" }}</Label>
                  <input
                    v-model="Nfe.vBCFCPST"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'vBCFCPST')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*%FCP retido" }}</Label>
                  <input
                    v-model="Nfe.pFCPST"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'pFCPST')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Valor do FCP retido*" }}</Label>
                  <input
                    v-model="Nfe.vFCPST"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'vFCPST')"
                  />
                </div>
              </div>
              <div class="row mt-4" v-if="show_inputs">
                <div class="col-md-3">
                  <Label>{{ "*Aliquota Suport pelo Consumidor" }}</Label>
                  <input
                    v-model="Nfe.pST"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'pST')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Valor do ICMS Próprio do Substituto" }}</Label>
                  <input
                    v-model="Nfe.vICMSSubstituto"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'vICMSSubstituto')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{
                    "*Percentual de Redução da Base de Cálculo Efetiva"
                  }}</Label>
                  <input
                    v-model="Nfe.pRedBCEfet"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'pRedBCEfet')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Valor  da Base de Cálculo Efetiva" }}</Label>
                  <input
                    v-model="Nfe.vBCEfet"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'vBCEfet')"
                  />
                </div>
              </div>
              <div class="row mt-4" v-if="show_inputs">
                <div class="col-md-6">
                  <Label>{{ "*Aliquota do ICMS Efetiva" }}</Label>
                  <input
                    v-model="Nfe.pICMSEfet"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'pICMSEfet')"
                  />
                </div>
                <div class="col-md-6">
                  <Label>{{ "*Valor do ICMS Efetivo" }}</Label>
                  <input
                    v-model="Nfe.vICMSEfet"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'vICMSEfet')"
                  />
                </div>
              </div>
              <!-- Inputs para Alterar os valores do IBPT -->
              <div class="row" v-if="show_inputs">
                   <div class="col-md-3">
                    <Label>{{ "*Valor Estadual" }}</Label>
                  <input
                    v-model="Nfe.ValorTributoEstadual"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'ValorTributoEstadual')"
                  />
                   </div>
                   <div class="col-md-3">
                    <Label>{{ "*Valor Federal" }}</Label>
                  <input
                    v-model="Nfe.ValorTributoNacional"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'ValorTributoNacional')"
                  />
                   </div>
                   <div class="col-md-3">
                    <Label>{{ "*% Estadual" }}</Label>
                  <input
                    v-model="Nfe.estadual_imposto"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'estadual_imposto'), calcula_vlr_ibpt_estadual($event)"
                  />
                  <!--  -->
                   </div>
                   <div class="col-md-3">
                    <Label>{{ "*% Federal" }}</Label>
                  <input
                    v-model="Nfe.federal_imposto"
                    type="text"
                    class="form-control text-left"
                    placeholder="0,00"
                    @input="formatInputFloat($event, Nfe, 'federal_imposto'), calcula_vlr_ibpt_federal($event)"
                  />
                   </div>
              </div>
              <div class="row" v-if="!codigo_101">
                <div class="col-md-6 mt-2">
                  <b-form-checkbox
                    ><b-badge
                      data-name="Total"
                      class="field-status"
                      variant="info"
                    >
                      {{
                        "não descontar valor do ICMS desonerado do Total da NF-e:"
                      }}
                    </b-badge></b-form-checkbox
                  >
                </div>
              </div>
            </b-tab>
            <b-tab title="ICMS Inter Estadual">
              <div class="row">
                <div class="col-md-4">
                  <Label for="BC_FCP">BC do FCP na UF Destino</Label>
                  <input
                    v-model="Nfe.vBCFCPUFDest"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vBCFCPUFDest')"
                  />
                </div>
                <div class="col-md-4">
                  <Label for="BC_FCP">{{ "% Icms FCP UF Destino*" }}</Label>
                  <input
                    v-model="Nfe.pFCPUFDest"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'pFCPUFDest')"
                  />
                </div>
                <div class="col-md-4">
                  <Label for="BC_FCP">{{
                    "Valor Icms FCP na UF Destino*"
                  }}</Label>
                  <input
                    v-model="Nfe.vFCPUFDest"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vFCPUFDest')"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-4">
                  <Label for="BC_FCP">{{
                    "BC do ICMS da UF de Destino*"
                  }}</Label>
                  <input
                    v-model="Nfe.vBCUFDest"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vBCUFDest')"
                  />
                </div>
                <div class="col-md-4">
                  <Label>{{ "Aliquota Interestadual*" }}</Label>
                  <input
                    v-model="Nfe.pICMSInter"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'pICMSInter')"
                  />
                </div>
                <div class="col-md-4">
                  <Label>{{ "% Provisório de Partilha de ICMS*" }}</Label>
                  <input
                    v-model="Nfe.pICMSInterPart"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'pICMSInterPart')"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-4">
                  <Label>{{ "Aliquota interna da UF destino*" }}</Label>
                  <input
                    v-model="Nfe.pICMSUFDest"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'pICMSUFDest')"
                  />
                </div>
                <div class="col-md-4">
                  <Label>{{ "Valor ICMS IntEst para UF destino*" }}</Label>
                  <input
                    v-model="Nfe.vICMSUFDest"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vICMSUFDest')"
                  />
                </div>
                <div class="col-md-4">
                  <Label>{{ "Valor ICMS IntEst para UF remetente" }}</Label>
                  <input
                    v-model="Nfe.vICMSUFRemet"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vICMSUFRemet')"
                  />
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template #title>
                <span>PIS<span :class="select_cst ? '' : 'cor'">*</span></span>
              </template>
              <div class="row">
                <div class="col-md-12">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.pis_id variant="primary"
                      >PIS</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    id="pis_id"
                    accordion="pedido-accordion"
                    role="tabpanel"
                    visible
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <Label
                          ><b-badge
                            data-name="Total"
                            class="field-status"
                            :variant="pis_vetifica ? 'success' : 'danger'"
                          >
                            {{ "*CST" }}
                          </b-badge></Label
                        >
                        <multiselect
                          v-model="select_cst"
                          placeholder="Selecione"
                          label="name"
                          track-by="name"
                          :options="csts_array"
                          :option-height="104"
                          :custom-label="customLabel_id_cst"
                          :show-labels="false"
                          deselect-label="ENTER para remover"
                          select-label="Pressione ENTER"
                          @select="seleciona_pis($event)"
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                              <span class="option__title"
                                >{{ props.option.id }} -
                                {{ props.option.name }}</span
                              >
                            </span>
                          </template>
                          <template slot="option" slot-scope="props">
                            <div class="option__desc">
                              <span class="option__title"
                                >{{ props.option.id }} -
                                {{ props.option.name }}</span
                              >
                            </div>
                          </template>
                          <span slot="noResult"
                            >Opa! Nenhum registro encontrado</span
                          >
                          <button
                            @click="abrir_modal_cliente()"
                            slot="noResult"
                            class="btn btn-primary ml-2"
                          >
                            Cadastrar cliente
                          </button>
                          <template>
                            <span slot="noOptions" class="mr-2"
                              >Lista Vazia Cadastre o Cliente</span
                            >
                            <button
                              slot="noOptions"
                              class="btn btn-primary ml-2"
                            >
                              Cadastrar cliente
                            </button>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                    <div class="row mt-4" v-if="pis_inputs">
                      <div class="col-sm-6 col-md-3">
                        <Label>{{ "*Tipo de Cálculo de PIS" }} </Label>
                        <multiselect
                          v-model="select_calculo_pis"
                          :options="tipos_calculos_pis"
                          label="name"
                          @select="seleciona_tp_calculo_pis($event)"
                        >
                        </multiselect>
                      </div>
                      <div class="col-sm-6 col-md-3">
                        <Label>
                          <b-badge
                            data-name="Total"
                            class="field-status"
                            :variant="
                              Nfe.pisvBC != null && Nfe.pisvBC != ''
                                ? 'success'
                                : 'danger'
                            "
                          >
                            {{ "*Base de Cálculo" }}
                          </b-badge>
                        </Label>
                        <input
                          v-model="Nfe.pisvBC"
                          type="text"
                          class="form-control text-left"
                          placeholder="Digite"
                          @input="formatInputFloat($event, Nfe, 'pisvBC')"
                        />
                      </div>
                      <div class="col-sm-6 col-md-3">
                        <Label>
                          <b-badge
                            data-name="Total"
                            class="field-status"
                            :variant="
                              Nfe.pPIS != null && Nfe.pPIS != ''
                                ? 'success'
                                : 'danger'
                            "
                          >
                            {{ "*%Aliquota" }}
                          </b-badge>
                        </Label>
                        <input
                          v-model="Nfe.pPIS"
                          type="text"
                          class="form-control text-left"
                          placeholder="Digite"
                          @input="formatInputFloat($event, Nfe, 'pPIS')"
                        />
                      </div>
                      <div class="col-sm-6 col-md-3">
                        <Label>
                          <b-badge
                            data-name="Total"
                            class="field-status"
                            :variant="
                              Nfe.vPIS != null && Nfe.vPIS != ''
                                ? 'success'
                                : 'danger'
                            "
                          >
                            {{ "*Valor do Pis" }}
                          </b-badge>
                        </Label>
                        <input
                          v-model="Nfe.vPIS"
                          type="text"
                          class="form-control text-left"
                          placeholder="Digite"
                          @input="formatInputFloat($event, Nfe, 'vPIS')"
                        />
                      </div>
                    </div>
                  </b-collapse>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.pis_id2 variant="info"
                      >PIS ST</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    id="pis_id2"
                    accordion="pedido-accordion"
                    role="tabpanel"
                    visible
                  >
                    <div class="row">
                      <div class="col-md-3">
                        <Label>{{ "*Tipo de Cálculo do PIS ST*" }}</Label>
                        <multiselect
                          v-model="select_tp_c_pis_st"
                          :options="pis_sts"
                          label="name"
                        ></multiselect>
                      </div>
                      <div class="col-md-3">
                        <Label>{{ "*Quantidade Vendida" }}</Label>
                        <input
                          type="text"
                          class="form-control text-left"
                          placeholder="Digite"
                        />
                      </div>
                      <div class="col-md-3">
                        <Label>{{ "*Alquota(R$)" }}</Label>
                        <input
                          type="text"
                          class="form-control text-left"
                          placeholder="Digite"
                        />
                      </div>
                      <div class="col-md-3">
                        <Label>{{ "*Valor do PIS ST*" }}</Label>
                        <input
                          type="text"
                          class="form-control text-left"
                          placeholder="Digite"
                        />
                      </div>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template #title>
                <span
                  >COFINS<span :class="select_cst_confins ? '' : 'cor'"
                    >*</span
                  ></span
                >
                <!-- <span :class="select_cst_confins ? '' : 'cor' ">{{ "*COFINS" }}</span> -->
              </template>
              <div class="row">
                <div class="col-md-12">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.confins_id variant="primary"
                      >COFINS</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    id="confins_id"
                    accordion="confins-accordion"
                    role="tabpanel"
                    visible
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <!-- cofins_vetifica -->
                        <Label
                          ><b-badge
                            data-name="Total"
                            class="field-status"
                            :variant="cofins_vetifica ? 'success' : 'danger'"
                          >
                            {{ "*CST" }}
                          </b-badge></Label
                        >
                        <multiselect
                          v-model="select_cst_confins"
                          placeholder="Selecione"
                          label="name"
                          track-by="name"
                          :options="csts_array_cofins"
                          :option-height="104"
                          :custom-label="customLabel_id_cst"
                          :show-labels="false"
                          deselect-label="ENTER para remover"
                          select-label="Pressione ENTER"
                          @select="seleciona_cofins($event)"
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                              <span class="option__title"
                                >{{ props.option.id }} -
                                {{ props.option.name }}</span
                              >
                            </span>
                          </template>
                          <template slot="option" slot-scope="props">
                            <div class="option__desc">
                              <span class="option__title"
                                >{{ props.option.id }} -
                                {{ props.option.name }}</span
                              >
                            </div>
                          </template>
                          <span slot="noResult"
                            >Opa! Nenhum registro encontrado</span
                          >
                          <button
                            @click="abrir_modal_cliente()"
                            slot="noResult"
                            class="btn btn-primary ml-2"
                          >
                            Cadastrar cliente
                          </button>
                          <template>
                            <span slot="noOptions" class="mr-2"
                              >Lista Vazia Cadastre o Cliente</span
                            >
                            <button
                              slot="noOptions"
                              class="btn btn-primary ml-2"
                            >
                              Cadastrar cliente
                            </button>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                    <div class="row mt-4" v-if="cofins_inputs">
                      <div class="col-sm-6 col-md-3">
                        <Label>{{ "*Tipo de Cálculo de COFINS" }} </Label>
                        <multiselect
                          v-model="select_calculo_cofins2"
                          :options="tipos_calculos_cofins"
                          label="name"
                          @select="seleciona_tp_calculo_confins($event)"
                        >
                        </multiselect>
                      </div>
                      <div class="col-sm-6 col-md-3">
                        <Label>
                          <b-badge
                            data-name="Total"
                            class="field-status"
                            :variant="
                              Nfe.confinsvBC != null && Nfe.confinsvBC != ''
                                ? 'success'
                                : 'danger'
                            "
                          >
                            {{ "*Base de Cálculo" }}
                          </b-badge>
                        </Label>
                        <input
                          v-model="Nfe.confinsvBC"
                          type="text"
                          class="form-control text-left"
                          placeholder="Digite"
                          @input="formatInputFloat($event, Nfe, 'confinsvBC')"
                        />
                      </div>
                      <div class="col-sm-6 col-md-3">
                        <Label>
                          <b-badge
                            data-name="Total"
                            class="field-status"
                            :variant="
                              Nfe.pCOFINS != null && Nfe.pCOFINS != ''
                                ? 'success'
                                : 'danger'
                            "
                          >
                            {{ "*%Aliquota" }}
                          </b-badge>
                        </Label>
                        <input
                          v-model="Nfe.pCOFINS"
                          type="text"
                          class="form-control text-left"
                          placeholder="Digite"
                          @input="formatInputFloat($event, Nfe, 'pCOFINS')"
                        />
                      </div>
                      <div class="col-sm-6 col-md-3">
                        <Label>
                          <b-badge
                            data-name="Total"
                            class="field-status"
                            :variant="
                              Nfe.vCOFINS != null && Nfe.vCOFINS != ''
                                ? 'success'
                                : 'danger'
                            "
                          >
                            {{ "*Valor do COFINS" }}
                          </b-badge>
                        </Label>
                        <input
                          v-model="Nfe.vCOFINS"
                          type="text"
                          class="form-control text-left"
                          placeholder="Digite"
                          @input="formatInputFloat($event, Nfe, 'vCOFINS')"
                        />
                      </div>
                    </div>
                  </b-collapse>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.pis_id2 variant="info"
                      >COFINS ST</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    id="pis_id2"
                    accordion="pedido-accordion"
                    role="tabpanel"
                    visible
                  >
                    <div class="row">
                      <div class="col-md-3">
                        <Label>{{ "*Tipo de Cálculo do COFINS ST*" }}</Label>
                        <multiselect
                          v-model="select_tp_c_confins_st"
                          :options="pis_sts"
                          label="name"
                        ></multiselect>
                      </div>
                      <div class="col-md-3">
                        <Label>{{ "*Quantidade Vendida" }}</Label>
                        <input
                          type="text"
                          class="form-control text-left"
                          placeholder="Digite"
                        />
                      </div>
                      <div class="col-md-3">
                        <Label>{{ "*Alquota(R$)" }}</Label>
                        <input
                          type="text"
                          class="form-control text-left"
                          placeholder="Digite"
                        />
                      </div>
                      <div class="col-md-3">
                        <Label>{{ "*Valor do COFiNS ST*" }}</Label>
                        <input
                          type="text"
                          class="form-control text-left"
                          placeholder="Digite"
                        />
                      </div>
                    </div>
                  </b-collapse>
                </div></div
            ></b-tab>
            <b-tab title="ISSQN"> </b-tab>
            <b-tab>
              <template #title>
                <span
                  >IPI<span :class="selec_cst_ipi ? '' : 'cor'">*</span></span
                >
                <!-- <span :class="selec_cst_ipi ? '' : 'cor' ">{{ "*IPI" }}</span> -->
              </template>
              <div class="row">
                <div class="col-md-4">
                  <Label>{{ "*Cód enquandramento legal" }}</Label>
                  <input
                    v-model="Nfe.cEnq"
                    type="text"
                    class="form-control text-left"
                  />
                </div>
                <div class="col-md-8">
                  <Label
                    ><b-badge
                      data-name="Total"
                      class="field-status"
                      :variant="ipi_verifica ? 'success' : 'danger'"
                    >
                      {{ "*CST/CSOSN" }}
                    </b-badge></Label
                  >
                  <multiselect
                    @select="seleciona_ipi($event)"
                    label="nome"
                    :options="ipis"
                    v-model="selec_cst_ipi"
                    :option-height="104"
                    :custom-label="customLabelIPI"
                    :show-labels="false"
                    deselect-label="ENTER para remover"
                    select-label="Pressione ENTER"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option__desc">
                        <span class="option__title"
                          >{{ props.option.id }} - {{ props.option.nome }}</span
                        >
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title"
                          >{{ props.option.id }} - {{ props.option.nome }}</span
                        >
                      </div> </template
                    ><span slot="noResult"
                      >Opa! Nenhum registro encontrado</span
                    >
                  </multiselect>
                </div>
              </div>
              <div class="row mt-2" v-if="ipi_inputs">
                <div class="col-md-3">
                  <Label>{{ "*Tipo de Calculo" }}</Label>
                  <multiselect
                    :options="tipos_calculos_pis"
                    v-model="select_calc_ipi"
                    label="name"
                    @select="selec_tp_calc_ipi($event)"
                  >
                  </multiselect>
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Quantidade Total" }}</Label>
                  <input
                    v-model="Nfe.qUnid"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'qUnid')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Valor Por Unidade" }}</Label>
                  <input
                    v-model="Nfe.vUnid"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vUnid')"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Valor do IPI" }}</Label>
                  <input
                    v-model="Nfe.vIPIitem"
                    type="text"
                    class="form-control text-left"
                    placeholder="Digite"
                    @input="formatInputFloat($event, Nfe, 'vIPIitem')"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-3">
                  <Label>Clas Enq Cigarros e Beb</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="digite"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "CNPJ Prod d Mercadoria" }}</Label>
                  <input
                    v-model="Nfe.CNPJProd"
                    type="text"
                    class="form-control text-left"
                    placeholder="digite"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "Cod Selo" }}</Label>
                  <input
                    v-model="Nfe.cSelo"
                    type="text"
                    class="form-control text-left"
                    placeholder="digite"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "Qtd d Selo" }}</Label>
                  <input
                    v-model="Nfe.qSelo"
                    type="text"
                    class="form-control text-left"
                    placeholder="digite"
                  />
                </div>
              </div>
            </b-tab>
            <b-tab title="II">
              <div class="row">
                <div class="col-md-3">
                  <Label>{{ "*base de Calculo" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="digite"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Despesas Aduaneiras" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="digite"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Valor do II " }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="digite"
                  />
                </div>
                <div class="col-md-3">
                  <Label>{{ "*Valor do IOF" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="digite"
                  />
                </div>
              </div>
            </b-tab>
            <b-tab title="Tributos Devolvidos">
              <div class="row">
                <div class="col-md-6">
                  <Label>{{ "% De Mercadoria Devolvida*" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="digite"
                  />
                </div>
                <div class="col-md-6">
                  <Label>{{ "Valor do IPI Devolvida*" }}</Label>
                  <input
                    type="text"
                    class="form-control text-left"
                    placeholder="digite"
                  />
                </div>
              </div>
            </b-tab>
            <b-tab title="Inform Adicionais do Item">
              <div class="row">
                <div class="col-md-12">
                  <Label>{{ "Informação Adicional do Produto" }}</Label>
                  <textarea
                    class="form-control text-left"
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    v-model="Nfe.infProdinfAdProd"
                  ></textarea>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-md-12 text-right">
        <button class="btn btn-secondary" @click.prevent="salvar()">
          Salvar
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Multiselect from "vue-multiselect";
import { http } from "@/helpers/easyindustriaapi/config";
export default {
  props: {
    impostos_modal: { type: Boolean },
    posicao_no_vetor: { type: Number },
    item: { type: Object },
    array_produto: { type: Array },
    obj_nfe: { type: Object },
    Uf_dest_new:{Type: String}
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      motivos_desonrados_sts: [
        {
          id: 3,
          nome: "Uso na agropecuária",
        },
        {
          id: 9,
          nome: "Outros",
        },
        {
          id: 12,
          nome: "Órgão de fomento e desenvolvimento agropecuário",
        },
      ],
      select_mot_deso_st: null,
      modalidades_bcs_sts: [
        {
          id: 1,
          nome: "Lista negativa (valor)",
        },
        {
          id: 2,
          nome: "Lista positiva (valor)",
        },
        {
          id: 3,
          nome: "Lista neutra (valor)",
        },
        {
          id: 4,
          nome: "Margem valor agregado (%)",
        },
        {
          id: 5,
          nome: "Pauta (valor)",
        },
        {
          id: 6,
          nome: "Valor da operação",
        },
      ],
      select_mod_bc_st: null,
      cst60: false,
      cst51: false,
      cst50: false,
      cst90: false,
      select_repasse_icms: -1,
      repassaes_icms_retidos: [
        {
          id: 0,
          nome: "não",
        },
        {
          id: 1,
          nome: "sim",
        },
      ],
      cst41: false,
      cst02: false,
      cst40: false,
      cst00: false,
      desc_icms: -1,
      desconttos_icms: [
        {
          id: 1,
          nome: "sim",
        },
        {
          id: 2,
          nome: "não",
        },
      ],
      motivos_desonrados: [
        {
          id: 1,
          nome: "Táxi",
        },
        {
          id: 3,
          nome: "Produtor agropecuário/uso na agropecuária",
        },
        {
          id: 4,
          nome: "Frotista/locadora",
        },
        {
          id: 5,
          nome: "Diplomático/consular",
        },
        {
          id: 6,
          nome: "Utilitários e motocicletas da amazônia ocidental e áreas de livre comércio",
        },
        {
          id: 7,
          nome: "SUFRAMA",
        },
        {
          id: 8,
          nome: "Venda a órgão público",
        },
        {
          id: 9,
          nome: "Outros",
        },
        {
          id: 10,
          nome: "Deficiente condutor",
        },
        {
          id: 11,
          nome: "Deficiente não condutor",
        },
        {
          id: 12,
          nome: "Orgão Agropecuário",
        },
        {
          id: 16,
          nome: "Olimpíadas Rio 2016",
        },
        {
          id: 90,
          nome: "Solicitato pelo fisco",
        },
      ],
      select_mot_deso: null, // valor desonerado motivo
      modalidades_bcs: [
        {
          id: 0,
          nome: "Margem valor agregado (%)",
        },
        {
          id: 1,
          nome: "Pauta (Valor)",
        },
        {
          id: 2,
          nome: "Preço tabelado máx. (valor)",
        },
        {
          id: 3,
          nome: "Valor da operação",
        },
      ],
      select_mod_bc_norm: -1,
      cst_icms_20: false,
      select_cst_normal: null,
      reg_normal: false,
      csts_reg_normais: [
        {
          id: 0,
          nome: "Tributada integralmente",
          id_es: "00",
        },
        {
          id: 2,
          nome: "Tributação monofásica própria sobre combustíveis",
          id_es: "02",
        },
        {
          id: 10,
          nome: "Tributada e com cobrança do ICMS por substituição tributária",
          id_es: "10",
        },
        {
          id: 20,
          nome: "Com redução da BC",
          id_es: "20",
        },
        {
          id: 30,
          nome: "Isenta / não tributada e com cobrança do ICMS por substituição tributária",
          id_es: "30",
        },
        {
          id: 40,
          nome: "Isenta",
          id_es: "40",
        },
        {
          id: 41,
          nome: "Não tributada",
          id_es: "41",
        },
        {
          id: 50,
          nome: "Com suspensão",
          id_es: "50",
        },
        {
          id: 51,
          nome: "Com diferimento",
          id_es: "51",
        },
        {
          id: 60,
          nome: "ICMS cobrado anteriormente por substituição tributária",
          id_es: "60",
        },
        {
          id: 70,
          nome: "Com redução da BC e cobrança do ICMS por substituição tributária",
          id_es: "70",
        },
        {
          id: 90,
          nome: "Outras",
          id_es: "90",
        },
      ],
      select_calc_ipi: null,
      select_calculo_cofins2: null,
      vetor_de_produtos: [],
      select_calc_cofins: null,
      pis_inputs: false,
      cofins_inputs: false,
      ipi_inputs: false,
      select_calculo_pis: null,
      tipos_calculos_pis: [
        {
          id: 1,
          name: "Valor",
        },
        {
          id: 2,
          name: "Percentual",
        },
      ],
      tipos_calculos_cofins: [
        {
          id: 1,
          name: "Valor",
        },
        {
          id: 2,
          name: "Percentual",
        },
      ],
      pis_sts: [
        {
          id: 1,
          name: "Valor",
        },
        {
          id: 2,
          name: "Percentual",
        },
      ],
      select_cst: null,
      select_cst_confins: null,
      select_tp_c_pis_st: null,
      select_tp_c_confins_st: null,
      selec_cst_ipi: null,
      ipis: [
        {
          id: 0,
          nome: "Entrada  Com Recuperação de Credito",
        },
        {
          id: 1,
          nome: "Entrada Tributada Com aliquota Zero",
        },
        {
          id: 2,
          nome: "Entrada Isenta",
        },
        {
          id: 3,
          nome: "Entrada Não tributada",
        },
        {
          id: 4,
          nome: "Entrada Imune ",
        },
        {
          id: 5,
          nome: "Entrada Com Suspensão",
        },
        {
          id: 49,
          nome: "Outras Entradas",
        },
        {
          id: 50,
          nome: "Saída Tributada",
        },
        {
          id: 51,
          nome: "Saída Tributada Com Aliquota Zero",
        },
        {
          id: 52,
          nome: "Saída Isenta",
        },
        {
          id: 53,
          nome: "Saída Não Tributada",
        },
        {
          id: 54,
          nome: "Saída Imune",
        },
        {
          id: 55,
          nome: "Saída Com Suspensão",
        },
        {
          id: 99,
          nome: "Outras Saídas",
        },
      ],
      csts_array: [
        {
          id: 1,
          name: "Operação Tributavel (BC = valor da operação aliquota normal(cumulativa/não cumulativa))",
        },
        {
          id: 2,
          name: "Operação Tributavel (BC = valor da operação (aliquota Diferenciada))",
        },
        {
          id: 3,
          name: "Operação Tributavel (BC = Quantiade Vendida X Aliquota Por Unidade de Produto)",
        },
        {
          id: 4,
          name: "Operação Tributavel (Tributação Monofásica(alíquota Zero))",
        },
        {
          id: 5,
          name: "Operação Tributavel (Substituição Tributária)",
        },
        {
          id: 6,
          name: "Operação Tributavel (Aliquota Zero)",
        },
        {
          id: 7,
          name: "Operação Isenta de Contribuição",
        },
        {
          id: 8,
          name: "Operação sem Icidência da Contribuição",
        },
        {
          id: 9,
          name: "Operação - Com Suspeção de Contribuição",
        },
        {
          id: 49,
          name: "Outras Operções de Saída",
        },
        {
          id: 50,
          name: "Operação Com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno ",
        },
        {
          id: 51,
          name: "Operação Com Direito a Crédito - Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno ",
        },
        {
          id: 52,
          name: "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação",
        },
        {
          id: 53,
          name: "Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno",
        },
        {
          id: 54,
          name: "Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação",
        },
        {
          id: 55,
          name: "Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação",
        },
        {
          id: 56,
          name: "Ope. com Dir a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação",
        },
        {
          id: 60,
          name: "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno",
        },
        {
          id: 61,
          name: "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno",
        },
        {
          id: 62,
          name: "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação",
        },
        {
          id: 63,
          name: "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno",
        },
        {
          id: 64,
          name: "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação",
        },
        {
          id: 65,
          name: "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação",
        },
        {
          id: 66,
          name: "Créd. Presumido - Oper. de Aqui. Vinculada a Receitas Tribut. e Não-Tributadas no Mercado Interno, e de Exportação",
        },
        {
          id: 67,
          name: "67 - Crédito Presumido - Outras Operações",
        },
        {
          id: 70,
          name: "Operação de Aquisição sem Direito a Crédito",
        },
        {
          id: 71,
          name: "Operação de Aquisição com Isenção",
        },
        {
          id: 72,
          name: "Operação de Aquisição com Suspensão",
        },
        {
          id: 73,
          name: "Operação de Aquisição a Alíquota Zero",
        },
        {
          id: 74,
          name: "Operação de Aquisição sem Incidência da Contribuição",
        },
        {
          id: 75,
          name: "Operação de Aquisição por Substituição Tributária",
        },
        {
          id: 98,
          name: "Outras Operações de Entrada",
        },
        {
          id: 99,
          name: "99 - Outras Operações",
        },
      ],
      csts_array_cofins: [
        {
          id: 1,
          name: "Operação Tributavel (BC = valor da operação aliquota normal(cumulativa/não cumulativa))",
        },
        {
          id: 2,
          name: "Operação Tributavel (BC = valor da operação (aliquota Diferenciada))",
        },
        {
          id: 3,
          name: "Operação Tributavel (BC = Quantiade Vendida X Aliquota Por Unidade de Produto)",
        },
        {
          id: 4,
          name: "Operação Tributavel (Tributação Monofásica(alíquota Zero))",
        },
        {
          id: 5,
          name: "Operação Tributavel (Substituição Tributária)",
        },
        {
          id: 6,
          name: "Operação Tributavel (Aliquota Zero)",
        },
        {
          id: 7,
          name: "Operação Isenta de Contribuição",
        },
        {
          id: 8,
          name: "Operação sem Icidência da Contribuição",
        },
        {
          id: 9,
          name: "Operação - Com Suspeção de Contribuição",
        },
        {
          id: 49,
          name: "Outras Operções de Saída",
        },
        {
          id: 50,
          name: "Operação Com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno ",
        },
        {
          id: 51,
          name: "Operação Com Direito a Crédito - Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno ",
        },
        {
          id: 52,
          name: "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação",
        },
        {
          id: 53,
          name: "Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno",
        },
        {
          id: 54,
          name: "Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação",
        },
        {
          id: 55,
          name: "Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação",
        },
        {
          id: 56,
          name: "Ope. com Dir a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação",
        },
        {
          id: 60,
          name: "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno",
        },
        {
          id: 61,
          name: "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno",
        },
        {
          id: 62,
          name: "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação",
        },
        {
          id: 63,
          name: "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno",
        },
        {
          id: 64,
          name: "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação",
        },
        {
          id: 65,
          name: "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação",
        },
        {
          id: 66,
          name: "Créd. Presumido - Oper. de Aqui. Vinculada a Receitas Tribut. e Não-Tributadas no Mercado Interno, e de Exportação",
        },
        {
          id: 67,
          name: "67 - Crédito Presumido - Outras Operações",
        },
        {
          id: 70,
          name: "Operação de Aquisição sem Direito a Crédito",
        },
        {
          id: 71,
          name: "Operação de Aquisição com Isenção",
        },
        {
          id: 72,
          name: "Operação de Aquisição com Suspensão",
        },
        {
          id: 73,
          name: "Operação de Aquisição a Alíquota Zero",
        },
        {
          id: 74,
          name: "Operação de Aquisição sem Incidência da Contribuição",
        },
        {
          id: 75,
          name: "Operação de Aquisição por Substituição Tributária",
        },
        {
          id: 98,
          name: "Outras Operações de Entrada",
        },
        {
          id: 99,
          name: "99 - Outras Operações",
        },
      ],
      index: null,
      codigo_101: false,
      select_cson: null,
      select_origem: null,
      origens: [
        {
          id: 0,
          nome: "nacional",
        },
        {
          id: 1,
          nome: "não nacional",
        },
      ],
      show_modal: false,
      show_inputs: false,
      csts: [
        {
          id: 101,
          nome: "Tributada pelo Simples Nacional com permissão de crédito",
        },
        {
          id: 102,
          nome: "Tributada pelo Simples Nacional sem permissão de crédito",
        },
        {
          id: 103,
          nome: "Isenção do ICMS no Simples Nacional para faixa de receita bruta",
        },
        {
          id: 201,
          nome: "Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
        },
        {
          id: 202,
          nome: "Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
        },
        {
          id: 203,
          nome: "Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária",
        },
        {
          id: 300,
          nome: "Imune",
        },
        {
          id: 400,
          nome: "Não tributada pelo Simples Nacional",
        },
        {
          id: 500,
          nome: "ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
        },
        {
          id: 900,
          nome: "Outros",
        },
      ],
      cst_cson: null,
      vetor_nfe: [
        {
          // codigo 101
          pCredSN: null,
          vCredICMSSN: null,
          //fim 101

          // codigo 500
          orig: 0,
          vBCSTRet: "0.00", // é obirgatorio para simples // base incial sempres vai ser o total dos produtos//
          vICMSSTRet: "0.00", // valor de icms retido // é obirgatorio para simples  // é percentula de imcs sobre o total dos produtos;
          vBCFCPST: "0.00",
          pFCPST: "0.00",
          vFCPST: "0.00",
          pST: "0.00",
          vICMSSubstituto: "0.00",
          pRedBCEfet: "0.00",
          vBCEfet: "0.00",
          pICMSEfet: "0.00",
          vICMSEfet: "0.00",
          produto_picms: null,
          //fim 500

          // node ipi
          clEnq: null,
          CNPJProd: null,
          cSelo: null,
          qSelo: null,
          cEnq: "999",
          ipiCST: "50",
          vIPIitem: 150.0,
          ipivBC: "0.00",
          pIPI: 15.0,
          qUnid: null,
          vUnid: null,

          // pis
          CST: null,

          // confins
          confinsCST: null,
        },
      ],
      vetor_iten_imposto: [
        {
          // codigo 101
          pCredSN: null,
          vCredICMSSN: null,
          //fim 101

          // codigo 500
          orig: 0,
          vBCSTRet: "0.00", // é obirgatorio para simples // base incial sempres vai ser o total dos produtos//
          vICMSSTRet: "0.00", // valor de icms retido // é obirgatorio para simples  // é percentula de imcs sobre o total dos produtos;
          vBCFCPST: "0.00",
          pFCPST: "0.00",
          vFCPST: "0.00",
          pST: "0.00",
          vICMSSubstituto: "0.00",
          pRedBCEfet: "0.00",
          vBCEfet: "0.00",
          pICMSEfet: "0.00",
          vICMSEfet: "0.00",
          produto_picms: null,
          //fim 500

          // node ipi
          clEnq: null,
          CNPJProd: null,
          cSelo: null,
          qSelo: null,
          cEnq: "999",
          ipiCST: "50",
          vIPIitem: 150.0,
          ipivBC: "0.00",
          pIPI: 15.0,
          qUnid: null,
          vUnid: null,

          // pis
          CST: null,

          // confins
          confinsCST: null,
        },
      ],
      Nfe: {
        // codigo 101
        pCredSN: null,
        vCredICMSSN: null,
        //fim 101

        // codigo 500
        orig: 0,
        vBCSTRet: "0.00", // é obirgatorio para simples // base incial sempres vai ser o total dos produtos//
        vICMSSTRet: "0.00", // valor de icms retido // é obirgatorio para simples  // é percentula de imcs sobre o total dos produtos;
        vBCFCPST: "0.00",
        pFCPST: "0.00",
        vFCPST: "0.00",
        pST: "0.00",
        vICMSSubstituto: "0.00",
        pRedBCEfet: "0.00",
        vBCEfet: "0.00",
        pICMSEfet: "0.00",
        vICMSEfet: "0.00",
        produto_picms: null,
        //fim 500

        // node ipi
        clEnq: null,
        CNPJProd: null,
        cSelo: null,
        qSelo: null,
        cEnq: "999",
        ipiCST: "50",
        vIPIitem: 150.0,
        ipivBC: "0.00",
        pIPI: 15.0,
        qUnid: null,
        vUnid: null,

        // pis
        CST: null,

        // confins
        confinsCST: null,
      },
      pis_vetifica: false,
      cofins_vetifica: false,
      ipi_verifica: false,
      icms_vetifica: false,
    };
  },
  created() {
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.show_modal = this.impostos_modal;
    this.index = this.posicao_no_vetor;
    this.Nfe.produto_picms = this.new_convert_srting_float(
      this.item.produto_picms
    );
    // this.vetor_de_produtos = [...this.array_produto];
    // this.vetor_de_produtos[this.index] = {...this.array_produto[this.index]};
    //this.vetor_iten_imposto = [...this.array_produto];
    this.vetor_de_produtos = this.array_produto;
    this.Nfe.estadual_imposto = "0,00"
       this.Nfe.federal_imposto = "0,00"
       this.Nfe.ValorTributoEstadual = "0,00"
       this.Nfe.ValorTributoNacional = "0,00"
  },
  mounted() {
     //console.log(this.item);
    // console.log("item e p icms:");
    // console.log(this.Nfe.produto_picms);
    // console.log("mostrando o vetor com os valores novamente");
    // console.log(this.vetor_de_produtos);

    this.valor_inicial_nfe();
    this.valores_interestaduais();
    this.preenhce_csts();
    console.log("Mostrando o Produto 0");
    console.log(this.vetor_de_produtos[this.index]);
    console.log(this.vetor_de_produtos[this.index].descricao.tabela_icms_ufs);
    console.log(this.Uf_dest_new);
    console.log("Mostrando Nfe objeto nos Impostos");
    console.log(this.obj_nfe.EmitCRT);
    this.verifica_crt();
    this.inicia_valor_vbc_cst00(); 
    this.preenche_reducao();
    this.verifica_calculo_red();
    this.pesquisa_ibpt_produto();
    
  },
  methods: {
    calcula_vlr_ibpt_federal(event){
      console.log(event.target.value)
      let aliquota_federal=this.new_convert_srting_float(event.target.value)
      console.log("mostrando a aliquta federal")
      console.log(aliquota_federal)
      let bruto = this.new_convert_srting_float(this.vetor_de_produtos[this.index].bruto)
      console.log(bruto)
      this.Nfe.ValorTributoNacional = this.formatterCurrBR(bruto * (aliquota_federal / 100))
    },
    calcula_vlr_ibpt_estadual(event){
      console.log(event.target.value)
      let aliquota_estadual=this.new_convert_srting_float(event.target.value)
      console.log("mostrando a aliquta esadual")
      console.log(aliquota_estadual)
      let bruto = this.new_convert_srting_float(this.vetor_de_produtos[this.index].bruto)
      console.log(bruto)
      this.Nfe.ValorTributoEstadual = this.formatterCurrBR(bruto * (aliquota_estadual / 100))
      console.log("Mostnaod valor estudal Final")
      console.log(this.Nfe.ValorTributoEstadual)
      // 
    },
    converte_vlr_IBPT(){
      this.Nfe.estadual_imposto = this.new_convert_srting_float(this.Nfe.estadual_imposto)
       this.Nfe.federal_imposto = this.new_convert_srting_float(this.Nfe.federal_imposto)
       this.Nfe.ValorTributoEstadual = this.new_convert_srting_float(this.Nfe.ValorTributoEstadual)
       this.Nfe.ValorTributoNacional = this.new_convert_srting_float(this.Nfe.ValorTributoNacional)
    },
    salva_ibpt_impostos(objeto){
       console.log("Impostos Ibpt")
       console.log(objeto)
       this.Nfe.estadual_imposto = objeto.Estadual
       this.Nfe.federal_imposto = objeto.Nacional
       this.Nfe.ValorTributoEstadual = objeto.ValorTributoEstadual
       this.Nfe.ValorTributoNacional = objeto.ValorTributoNacional
       console.log(this.Nfe.estadual_imposto)
       console.log(this.Nfe.federal_imposto)
       console.log(this.Nfe.ValorTributoEstadual)
       console.log(this.Nfe.ValorTributoNacional)
    },
    async pesquisa_ibpt_produto() {
     
     console.log("Estou nos inspostos do produto-"+ this.index);
     console.log("Mostrando NCM do PRoduto atual");
     console.log(this.vetor_de_produtos[this.index].descricao.produto_ncm);
     console.log("Mostrando a UF da empresa");
     console.log(this.currentEmpresa.uf);
     let ncm_atual =  this.vetor_de_produtos[this.index].descricao.produto_ncm
     let quantidade_produto = this.new_convert_srting_float(this.vetor_de_produtos[this.index].quantidade)
     console.log(quantidade_produto)
     let valor_bruto = this.new_convert_srting_float(this.vetor_de_produtos[this.index].bruto)
     console.log(valor_bruto)
     let descri_produto = this.vetor_de_produtos[this.index].descricao.produto_descricao
     console.log(descri_produto)
     let gtin_prod = this.vetor_de_produtos[this.index].descricao.produto_cean
     console.log(gtin_prod)
     console.log(this.vetor_de_produtos[this.index])
     let unidade_prod = this.vetor_de_produtos[this.index].unidade.DescricaoUn
     console.log(unidade_prod)
      try {
        let objeto = {
          cnpj: 50625040000105,
          uf: this.currentEmpresa.uf,
          ncm: ncm_atual,
          quantidade:quantidade_produto,
          valor:valor_bruto,
          descricao:descri_produto,
          gtin: gtin_prod,
          unidade: unidade_prod

        };
        console.log(objeto)
        let response = await http.post(
          "/nfe/ibptprod?empresa_id=" + this.currentEmpresa.id,
          objeto
        );
        if (response.status === 200) {
          response.data;
          console.log("Motrando IBPT");
          console.log(response.data);
          this.salva_ibpt_impostos(response.data)
         if(response.data.Codigo != null){
          this.makeToast("success","Impostos IBPT carregados" );
         }else {
          this.makeToast("danger","NCM INVALIDO" );
         }
          
           // tempo para carregar a tela;
        } else {
          this.listPedidos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    preenche_reducao(){
      let vet_ufs = this.vetor_de_produtos[this.index].descricao.tabela_icms_ufs
      let uf = this.Uf_dest_new
      let selec = vet_ufs.find((u)=> u.uf.toUpperCase() == uf.toUpperCase())
      let bruto = this.vetor_de_produtos[this.index].bruto
      //console.log(vet_ufs)

      if(selec != undefined && bruto != null && bruto != undefined && bruto != '' ){
        this.Nfe.pRedBC = selec.red_base 
        this.Nfe.vBC = this.new_convert_srting_float(bruto) *  (this.new_convert_srting_float(selec.red_base) / 100)
      }
      console.log("Mostrando Seleção")
      console.log(this.Uf_dest_new)
      console.log(selec)
    },
    redcalculo(event){
      console.log("estou na redução de base ")
      console.log(event.target.value)
      console.log(this.Nfe.pRedBC)
      let pRedBC = this.new_convert_srting_float(this.Nfe.pRedBC)
      let vBC =  this.new_convert_srting_float(this.Nfe.vBC)
      
      console.log(pRedBC)
      console.log(vBC)
      let newvBC = this.new_convert_srting_float(this.vetor_de_produtos[this.index].bruto) *  (pRedBC/100)
      
      console.log("nova base com redução")
      console.log(newvBC)
      this.Nfe.vBC = null
      this.Nfe.vBC = this.formatterCurrBR(newvBC) 
      

    },
    converte_vlr_cst90() {
      this.Nfe.pICMSST = this.new_convert_srting_float(this.Nfe.pICMSST);
      this.Nfe.pRedBCST = this.new_convert_srting_float(this.Nfe.pRedBCST);
      this.Nfe.vBCST = this.new_convert_srting_float(this.Nfe.vBCST);
      this.Nfe.pICMSST = this.new_convert_srting_float(this.Nfe.pICMSST);
      this.Nfe.vICMSST = this.new_convert_srting_float(this.Nfe.vICMSST);
    },
    converte_vlr_cst_40() {
      //
      this.Nfe.vICMSDeson = this.new_convert_srting_float(this.Nfe.vICMSDeson);
      this.Nfe.pST = this.new_convert_srting_float(this.Nfe.pST); // this.Nfe.pST
      this.Nfe.vICMSSubstituto = this.new_convert_srting_float(
        this.Nfe.vICMSSubstituto
      ); // Valor do ICMS próprio do substituto
      this.Nfe.pRedBCEfet = this.new_convert_srting_float(this.Nfe.pRedBCEfet); // Percent de redução da base de cálculo efetiva
      this.Nfe.vBCEfet = this.new_convert_srting_float(this.Nfe.vBCEfet); // Valor da base de cálculo efetiva
      this.Nfe.pICMSEfet = this.new_convert_srting_float(this.Nfe.pICMSEfet); //Alíquota do ICMS efetiva
      this.Nfe.vICMSEfet = this.new_convert_srting_float(this.Nfe.vICMSEfet); // Valor do ICMS efetivo
    },
    inicia_valor_vbc_cst00() {
      let bruto = this.vetor_de_produtos[this.index].bruto;
      console.log(bruto);
      this.Nfe.vBC = bruto;
    },
    verifica_calculo_red(){
      let bruto = this.new_convert_srting_float(
        this.vetor_de_produtos[this.index].bruto
      );
      console.log(bruto);
      console.log("Mostrando Es");
      console.log(this.Nfe.cst_reg_normal);
      if(this.vetor_de_produtos[this.index].new_nfe_objeto.cst_reg_normal != undefined && this.vetor_de_produtos[this.index].new_nfe_objeto.cst_reg_normal != null && this.vetor_de_produtos[this.index].new_nfe_objeto.cst_reg_normal !="" ){

      if(this.Nfe.cst_reg_normal.id_es == 20){
        let resultado =
        bruto * (this.Nfe.pRedBC/100) * this.Nfe.pICMS / 100;
        this.Nfe.vICMS = this.formatterCurrBR(resultado);
      }
      }
    },
    calc_valor_vbc_cst00(event) {
      console.log(event);
      let aliquota = event.target.value;
      console.log("mostrando Aliquota");
      console.log(aliquota);
      let bruto = this.new_convert_srting_float(
        this.vetor_de_produtos[this.index].bruto
      );
      console.log(bruto);

      if(this.Nfe.cst_reg_normal.id_es == 20){
        let resultado =
        (bruto * (this.new_convert_srting_float(this.Nfe.pRedBC)/100) * this.new_convert_srting_float(event.target.value)) / 100;
        this.Nfe.vICMS = this.formatterCurrBR(resultado);
      }else{
        let resultado =
        (bruto * this.new_convert_srting_float(event.target.value)) / 100;
      this.Nfe.vICMS = this.formatterCurrBR(resultado);
      }
      
    },
    calc_vFCP_cst00(event) {
      console.log("mostrnado valor de vFCP");
      console.log(event.target.value);

      let bruto = this.new_convert_srting_float(
        this.vetor_de_produtos[this.index].bruto
      );
      let resultado =
        (bruto * this.new_convert_srting_float(event.target.value)) / 100;
      this.Nfe.vFCP = this.formatterCurrBR(resultado);
    },
    seleciona_mot_deso_st(event) {
      console.log("motivo desonn icms  st");
      console.log(event);
    },
    seleciona_mod_bc_st(event) {
      console.log("modalidade icms  st");
      console.log(event);
      this.Nfe.modBCST = event.target.value;
    },
    seleciona_repasse_cims(event) {
      console.log("estuo em repasse retidos");
      console.log(event);
    },
    converte_vlr_cst20() {
      this.Nfe.vFCP = this.new_convert_srting_float(this.Nfe.vFCP);
      this.Nfe.pFCP = this.new_convert_srting_float(this.Nfe.pFCP);
      this.Nfe.vBCFCP = this.new_convert_srting_float(this.Nfe.vBCFCP);
      this.Nfe.vICMSDeson = this.new_convert_srting_float(this.Nfe.vICMSDeson);
      this.Nfe.vICMS = this.new_convert_srting_float(this.Nfe.vICMS);
      this.Nfe.pICMS = this.new_convert_srting_float(this.Nfe.pICMS);
      this.Nfe.vBC = this.new_convert_srting_float(this.Nfe.vBC);
      this.Nfe.pRedBC = this.new_convert_srting_float(this.Nfe.pRedBC);
    },
    converte_vlr_cst00() {
      this.Nfe.vBC = this.new_convert_srting_float(this.Nfe.vBC);
      this.Nfe.pICMS = this.new_convert_srting_float(this.Nfe.pICMS);
      this.Nfe.vICMS = this.new_convert_srting_float(this.Nfe.vICMS);
      this.Nfe.pFCP = this.new_convert_srting_float(this.Nfe.pFCP);
      this.Nfe.vFCP = this.new_convert_srting_float(this.Nfe.vFCP);
    },
    seleciona_desc_icms(event) {
      console.log("estou no desconteo icms");
      console.log(event.target.value);
      console.log(
        "Implemetar logica para Reduzir o ICMS desonerado do total da NFe"
      );
    },
    seleciona_mot_deso(event) {
      console.log("esou em motivo desonerado");
      console.log(event);
      this.Nfe.motDesICMS = event.target.value;
      this.Nfe.select_mot_deso = event.target.value;
      console.log(this.Nfe.motDesICMS);
    },
    seleciona_mod_bc_norm(event) {
      console.log("Estou no Mod bc norm");
      // console.log(event.target.value);
      if (event.target.value == -1) {
        this.Nfe.modBC = null;
      } else {
        this.Nfe.modBC = event.target.value;
        this.Nfe.select_mod_bc_norm = event.target.value;
        console.log(this.Nfe.modBC);
      }
    },
    // para mudar o multiselect do icms de acordo com o CRT do emitente;
    // e é simples ou Normal.
    verifica_crt() {
      let crt_emit = this.obj_nfe.EmitCRT;
      if (crt_emit == 3) {
        this.codigo_101 = false;
        this.show_inputs = false;
        this.reg_normal = true;
        //this.Nfe.reg_normal = true
      } else {
        this.reg_normal = false;
        // this.Nfe.reg_normal = false
      }
    },
    preenhce_csts() {
      // console.log("Mostrando Obejto item" )
      //  console.log(this.item)

      if (
        this.Nfe.select_cson == null ||
        this.Nfe.select_cson == undefined ||
        this.Nfe.select_cson == ""
      ) {
        if (this.item.produto_cst != null && this.item.produto_cst != 0) {
          console.log("");
          let objeto_cst = this.csts.find(
            (cst) => cst.id == this.item.produto_cst
          );
          // console.log("O objeto ICMS cst foi selecioando nos impostos")
          console.log(objeto_cst);
          this.select_cson = objeto_cst;
          this.Nfe.select_cson = objeto_cst;
          this.seleciona_cst(objeto_cst);
        }
      }

      //PIS
      if (
        this.Nfe.select_cst == null ||
        this.Nfe.select_cst == undefined ||
        this.Nfe.select_cst == ""
      ) {
        if (this.item.produto_cstpis != null && this.item.produto_cstpis != 0) {
          //console.log("")
          let objeto_cst_pis = this.csts_array.find(
            (cst) => cst.id == this.item.produto_cstpis
          );
          console.log("O objeto PIS cst foi selecioando nos impostos");
          console.log(objeto_cst_pis);
          this.select_cst = objeto_cst_pis;
          // this.Nfe.select_cst = objeto_cst_pis
          this.seleciona_pis(objeto_cst_pis);
        }
      }

      //COFINS
      //produto_cstcofins
      if (
        this.Nfe.select_cst_confins == null ||
        this.Nfe.select_cst_confins == undefined ||
        this.Nfe.select_cst_confins == ""
      ) {
        if (
          this.item.produto_cstcofins != null &&
          this.item.produto_cstcofins != 0
        ) {
          //console.log("")
          let objeto_cst_cofins = this.csts_array_cofins.find(
            (cst) => cst.id == this.item.produto_cstcofins
          );
          // console.log("O objeto Cofins cst foi selecioando nos impostos")
          console.log(objeto_cst_cofins);
          this.select_cst_confins = objeto_cst_cofins;
          this.Nfe.confinsCST = objeto_cst_cofins.id;
          this.Nfe.select_cst_confins = objeto_cst_cofins;
          this.seleciona_cofins(objeto_cst_cofins);
        }
      }
      // IPI
      if (
        this.Nfe.selec_cst_ipi == null ||
        this.Nfe.selec_cst_ipi == undefined ||
        this.Nfe.selec_cst_ipi == ""
      ) {
        if (this.item.produto_cstipi != null && this.item.produto_cstipi != 0) {
          //console.log("")
          let objeto_cst_ipi = this.ipis.find(
            (cst) => cst.id == this.item.produto_cstipi
          );
          // console.log("O objeto IPI cst foi selecioando nos impostos")
          console.log(objeto_cst_ipi);
          this.selec_cst_ipi = objeto_cst_ipi;
          this.Nfe.selec_cst_ipi = objeto_cst_ipi; // não precisava desse dois aqui pois ja esta dentro da function
          this.Nfe.ipiCST = objeto_cst_ipi.id;
          this.seleciona_ipi(objeto_cst_ipi);
        }
      }

      if (
        this.Nfe.select_origem == null ||
        this.Nfe.select_origem == undefined ||
        this.Nfe.select_origem == ""
      ) {
        if (
          this.item.produto_orig != null &&
          this.item.produto_orig != undefined &&
          this.item.produto_orig != ""
        ) {
          //console.log("")
          let objeto_origem = this.origens.find(
            (cst) => cst.id == this.item.produto_orig
          );
          console.log("O objeto origem cst foi selecioando nos impostos");
          console.log(objeto_origem);
          this.select_origem = objeto_origem;
          this.seleciona_origem(objeto_origem);
          // this.select_cst = objeto_cst_pis;
          // this.Nfe.select_cst = objeto_cst_pis
          //this.seleciona_pis(objeto_cst_pis);
        }
      }
    },

    valores_interestaduais() {
      this.Nfe.vBCUFDest = "0,00"; // valor da base de cálculo no ICMS de destino
      this.Nfe.vBCFCPUFDest = "0,00"; // Valor da Base de Cálculo do FCP na UF de destino
      this.Nfe.pFCPUFDest = "0,00"; // Percentual do ICMS relativo ao Fundo de Combate à Pobreza (FCP) na UFcde destino
      this.Nfe.pICMSUFDest = "0,00"; // Alíquota interna da UF de destino
      this.Nfe.pICMSInter = "0,00"; // Alíquota interestadual das UF envolvidas
      this.Nfe.pICMSInterPart = "0,00"; // Percentual provisório de partilha do ICMS Interestadual
      this.Nfe.vFCPUFDest = "0,00"; //  Valor do ICMS relativo ao Fundo de Combate à Pobreza (FCP) da UF de  destino
      this.Nfe.vICMSUFDest = "0,00"; // Valor do ICMS Interestadual para a UF de destino
      this.Nfe.vICMSUFRemet = "0,00";
    },
    selec_tp_calc_ipi(event) {
      this.Nfe.select_calc_ipi = event;
    },
    seleciona_tp_calculo_confins(event) {
      this.Nfe.select_calculo_cofins2 = event;
    },
    seleciona_tp_calculo_pis(event) {
      this.Nfe.select_calculo_pis = event;
    },
    // seleciona_tp_calculo(event) {

    //   this.Nfe.select_calculo_cofins = event;
    // },
    //  serve para não perde os valores selecionados ao fecha o modal , eu coloca ele num array ;
    valor_inicial_nfe() {
      console.log("index novamnete");
      console.log(this.index);
      console.log("mostrando o vetro dentro de iniclal valores");
      console.log(this.vetor_de_produtos[this.index]);
      // console.log(this.vetor_de_produtos[this.index].new_nfe_objeto)
      // if(this.vetor_de_produtos[this.index].new_nfe_objeto ){
      //   this.Nfe = this.vetor_de_produtos[this.index].new_nfe_objeto
      // }
      //this.seleciona_tp_calculo
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        if (
          this.vetor_de_produtos[this.index].new_nfe_objeto != null &&
          this.vetor_de_produtos[this.index].new_nfe_objeto != undefined &&
          this.vetor_de_produtos[this.index].new_nfe_objeto != "" &&
          Object.keys(this.vetor_de_produtos[this.index].new_nfe_objeto)
            .length !== 0
        ) {
          console.log("entrei aqui");
          this.Nfe = { ...this.vetor_de_produtos[this.index].new_nfe_objeto };
          // this.Nfe.produto_picms = this.item.produto_picms;
          console.log("mostrando p icms novamnte");
          console.log(this.Nfe);
        }
      }

      // serve para verifica o cson seleciona deopis que salvar os impostos , isso quando abrir o modal

      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        // select_origem
        if (
          "select_cson" in this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          // 'select_cson' existe

          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_cson !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_cson !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_cson != ""
          ) {
            this.select_cson =
              this.vetor_de_produtos[this.index].new_nfe_objeto.select_cson;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        // select_origem
        if (
          "select_calc_ipi" in this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          // 'select_calc_ipi' existe

          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_calc_ipi !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_calc_ipi !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_calc_ipi !=
              ""
          ) {
            this.select_calc_ipi =
              this.vetor_de_produtos[this.index].new_nfe_objeto.select_calc_ipi;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        // select_origem
        if (
          "select_calculo_pis" in
          this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          // 'select_calculo_pis' existe

          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_calculo_pis != null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_calculo_pis != undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_calculo_pis != ""
          ) {
            this.select_calculo_pis =
              this.vetor_de_produtos[
                this.index
              ].new_nfe_objeto.select_calculo_pis;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        // select_origem
        if (
          "select_calculo_cofins2" in
          this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          // 'select_calculo_cofins' existe

          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_calculo_cofins2 != null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_calculo_cofins2 != undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_calculo_cofins2 != ""
          ) {
            this.select_calculo_cofins2 =
              this.vetor_de_produtos[
                this.index
              ].new_nfe_objeto.select_calculo_cofins2;
          }
        }
      }
      //
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        // select_origem
        if ("select_cst" in this.vetor_de_produtos[this.index].new_nfe_objeto) {
          // 'select_cst' existe

          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_cst !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_cst !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_cst != ""
          ) {
            this.select_cst =
              this.vetor_de_produtos[this.index].new_nfe_objeto.select_cst;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        // select_origem
        if (
          "seleciona_tp_calculo" in
          this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          // 'seleciona_tp_calculo' existe

          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .seleciona_tp_calculo != null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .seleciona_tp_calculo != undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .seleciona_tp_calculo != ""
          ) {
            this.seleciona_tp_calculo =
              this.vetor_de_produtos[
                this.index
              ].new_nfe_objeto.seleciona_tp_calculo;
          }
        }
      }

      // seleciona_tp_calculo
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        // select_origem
        if (
          "select_cst_confins" in
          this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          // 'select_cst_confins' existe

          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_cst_confins != null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_cst_confins != undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_cst_confins != ""
          ) {
            this.select_cst_confins =
              this.vetor_de_produtos[
                this.index
              ].new_nfe_objeto.select_cst_confins;
          }
        }
      }
      //
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        // select_origem
        if (
          "selec_cst_ipi" in this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          // 'select_cson' existe

          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.selec_cst_ipi !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.selec_cst_ipi !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.selec_cst_ipi !=
              ""
          ) {
            this.selec_cst_ipi =
              this.vetor_de_produtos[this.index].new_nfe_objeto.selec_cst_ipi;
          }
        }
      }

      // this.Nfe.selec_cst_ipi
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        // select_origem
        if (
          "select_origem" in this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          // 'select_cson' existe

          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_origem !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_origem !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_origem !=
              ""
          ) {
            this.select_origem =
              this.vetor_de_produtos[this.index].new_nfe_objeto.select_origem;
          }
        }
      }

      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        if (
          "show_inputs" in this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.show_inputs !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.show_inputs !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.show_inputs != ""
          ) {
            this.show_inputs =
              this.vetor_de_produtos[this.index].new_nfe_objeto.show_inputs;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        if ("ipi_inputs" in this.vetor_de_produtos[this.index].new_nfe_objeto) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.ipi_inputs !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.ipi_inputs !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.ipi_inputs != ""
          ) {
            this.ipi_inputs =
              this.vetor_de_produtos[this.index].new_nfe_objeto.ipi_inputs;
          }
        }
      }
      // ipi_inputs
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        if ("pis_inputs" in this.vetor_de_produtos[this.index].new_nfe_objeto) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.pis_inputs !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.pis_inputs !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.pis_inputs != ""
          ) {
            this.pis_inputs =
              this.vetor_de_produtos[this.index].new_nfe_objeto.pis_inputs;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        if (
          "cofins_inputs" in this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.cofins_inputs !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cofins_inputs !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cofins_inputs !=
              ""
          ) {
            this.cofins_inputs =
              this.vetor_de_produtos[this.index].new_nfe_objeto.cofins_inputs;
          }
        }
      }
      //
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'
        if ("codigo_101" in this.vetor_de_produtos[this.index].new_nfe_objeto) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.codigo_101 !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.codigo_101 !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.codigo_101 != ""
          ) {
            this.codigo_101 =
              this.vetor_de_produtos[this.index].new_nfe_objeto.codigo_101;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'

        if (
          "icms_vetifica" in this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.icms_vetifica !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.icms_vetifica !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.icms_vetifica !=
              ""
          ) {
            this.icms_vetifica =
              this.vetor_de_produtos[this.index].new_nfe_objeto.icms_vetifica;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'

        if (
          "pis_vetifica" in this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.pis_vetifica !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.pis_vetifica !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.pis_vetifica != ""
          ) {
            this.pis_vetifica =
              this.vetor_de_produtos[this.index].new_nfe_objeto.pis_vetifica;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'

        if (
          "cofins_vetifica" in this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.cofins_vetifica !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cofins_vetifica !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cofins_vetifica !=
              ""
          ) {
            this.cofins_vetifica =
              this.vetor_de_produtos[this.index].new_nfe_objeto.cofins_vetifica;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'

        if (
          "ipi_verifica" in this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.ipi_verifica !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.ipi_verifica !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.ipi_verifica != ""
          ) {
            this.ipi_verifica =
              this.vetor_de_produtos[this.index].new_nfe_objeto.ipi_verifica;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'

        if (
          "select_cst_normal" in
          this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_cst_normal != null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_cst_normal != undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_cst_normal != ""
          ) {
            this.select_cst_normal =
              this.vetor_de_produtos[
                this.index
              ].new_nfe_objeto.select_cst_normal;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'

        if (
          "cst_icms_20" in this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst_icms_20 !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst_icms_20 !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst_icms_20 != ""
          ) {
            this.cst_icms_20 =
              this.vetor_de_produtos[this.index].new_nfe_objeto.cst_icms_20;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'

        if ("cst00" in this.vetor_de_produtos[this.index].new_nfe_objeto) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst00 != null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst00 !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst00 != ""
          ) {
            this.cst00 =
              this.vetor_de_produtos[this.index].new_nfe_objeto.cst00;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'

        if (
          "select_mod_bc_norm" in
          this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_mod_bc_norm != null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_mod_bc_norm != undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto
              .select_mod_bc_norm != ""
          ) {
            this.select_mod_bc_norm =
              this.vetor_de_produtos[
                this.index
              ].new_nfe_objeto.select_mod_bc_norm;
          }
        }
      }
      //this.Nfe.cst_icms_20
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'

        if (
          "cst_icms_20" in this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst_icms_20 !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst_icms_20 !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst_icms_20 != ""
          ) {
            this.cst_icms_20 =
              this.vetor_de_produtos[this.index].new_nfe_objeto.cst_icms_20;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'

        if (
          "select_mot_deso" in this.vetor_de_produtos[this.index].new_nfe_objeto
        ) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_mot_deso !=
              null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_mot_deso !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.select_mot_deso !=
              ""
          ) {
            this.select_mot_deso =
              this.vetor_de_produtos[this.index].new_nfe_objeto.select_mot_deso;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'

        if ("cst40" in this.vetor_de_produtos[this.index].new_nfe_objeto) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst40 != null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst40 !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst40 != ""
          ) {
            this.cst40 =
              this.vetor_de_produtos[this.index].new_nfe_objeto.cst40;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'

        if ("cst90" in this.vetor_de_produtos[this.index].new_nfe_objeto) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst90 != null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst90 !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst90 != ""
          ) {
            this.cst90 =
              this.vetor_de_produtos[this.index].new_nfe_objeto.cst90;
          }
        }
      }
      if (
        this.vetor_de_produtos[this.index] &&
        this.vetor_de_produtos[this.index].new_nfe_objeto
      ) {
        // Verifica se 'select_cson' existe dentro de 'new_nfe_objeto'

        if ("cst51" in this.vetor_de_produtos[this.index].new_nfe_objeto) {
          if (
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst51 != null &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst51 !=
              undefined &&
            this.vetor_de_produtos[this.index].new_nfe_objeto.cst51 != ""
          ) {
            this.cst51 =
              this.vetor_de_produtos[this.index].new_nfe_objeto.cst51;
          }
        }
      }
      // this.Nfe.cst51
    },
    seleciona_ipi(evento) {
      if (
        evento.id === 0 ||
        evento.id === 1 ||
        evento.id === 2 ||
        evento.id === 3 ||
        evento.id === 4
      ) {
        evento.id = "0" + evento.id;
      }
      this.Nfe.selec_cst_ipi = evento;
      this.Nfe.ipiCST = evento.id;
      console.log("estou no iPI");
      console.log(this.Nfe.selec_cst_ipi);

      if (
        this.Nfe.selec_cst_ipi != null &&
        this.Nfe.selec_cst_ipi != undefined &&
        this.Nfe.selec_cst_ipi != ""
      ) {
        this.ipi_verifica = true;
      } else {
        this.ipi_verifica = false;
      }
      this.Nfe.ipi_verifica = this.ipi_verifica;
      if (
        evento.id == 0 ||
        evento.id == 49 ||
        evento.id == 50 ||
        evento.id == 99
      ) {
        this.ipi_inputs = true;
        this.Nfe.ipi_inputs = this.ipi_inputs;
      } else {
        this.ipi_inputs = false;
        this.Nfe.ipi_inputs = this.ipi_inputs;
      }
    },
    seleciona_pis(objeto) {
      if (
        objeto.id === 1 ||
        objeto.id === 2 ||
        objeto.id === 3 ||
        objeto.id === 4 ||
        objeto.id === 5 ||
        objeto.id === 6 ||
        objeto.id === 7 ||
        objeto.id === 8 ||
        objeto.id === 9
      ) {
        objeto.id = "0" + objeto.id;
      }
      console.log("selecionando PIs");
      console.log(objeto.id);
      this.Nfe.select_cst = objeto;

      if (
        this.Nfe.select_cst != null &&
        this.Nfe.select_cst != undefined &&
        this.Nfe.select_cst != ""
      ) {
        this.pis_vetifica = true;
      } else {
        this.pis_vetifica = false;
      }
      this.Nfe.pis_vetifica = this.pis_vetifica;
      this.Nfe.CST = objeto.id;
      if (
        objeto.id == 1 ||
        objeto.id == 2 ||
        objeto.id == 3 ||
        objeto.id == 49 ||
        objeto.id == 50 ||
        objeto.id == 51 ||
        objeto.id == 52 ||
        objeto.id == 53 ||
        objeto.id == 54 ||
        objeto.id == 55 ||
        objeto.id == 56 ||
        objeto.id == 60 ||
        objeto.id == 61 ||
        objeto.id == 62 ||
        objeto.id == 63 ||
        objeto.id == 64 ||
        objeto.id == 65 ||
        objeto.id == 66 ||
        objeto.id == 67 ||
        objeto.id == 70 ||
        objeto.id == 71 ||
        objeto.id == 72 ||
        objeto.id == 73 ||
        objeto.id == 74 ||
        objeto.id == 75 ||
        objeto.id == 75 ||
        objeto.id == 98 ||
        objeto.id == 99
      ) {
        this.pis_inputs = true;
        this.Nfe.pis_inputs = this.pis_inputs;
      } else {
        this.pis_inputs = false;
        this.Nfe.pis_inputs = this.pis_inputs;
      }
    },
    seleciona_cofins(objeto) {
      if (
        objeto.id === 1 ||
        objeto.id === 2 ||
        objeto.id === 3 ||
        objeto.id === 4 ||
        objeto.id === 5 ||
        objeto.id === 6 ||
        objeto.id === 7 ||
        objeto.id === 8 ||
        objeto.id === 9
      ) {
        objeto.id = "0" + objeto.id;
      }
      this.Nfe.confinsCST = objeto.id;
      this.Nfe.select_cst_confins = objeto;

      console.log("estou em selecionar Cofins");
      console.log(this.Nfe.select_cst_confins);

      if (
        this.Nfe.select_cst_confins != null &&
        this.Nfe.select_cst_confins != undefined &&
        this.Nfe.select_cst_confins != ""
      ) {
        this.cofins_vetifica = true;
      } else {
        this.cofins_vetifica = false;
      }
      this.Nfe.cofins_vetifica = this.cofins_vetifica;
      if (
        objeto.id == 1 ||
        objeto.id == 2 ||
        objeto.id == 3 ||
        objeto.id == 49 ||
        objeto.id == 50 ||
        objeto.id == 51 ||
        objeto.id == 52 ||
        objeto.id == 53 ||
        objeto.id == 54 ||
        objeto.id == 55 ||
        objeto.id == 56 ||
        objeto.id == 60 ||
        objeto.id == 61 ||
        objeto.id == 62 ||
        objeto.id == 63 ||
        objeto.id == 64 ||
        objeto.id == 65 ||
        objeto.id == 66 ||
        objeto.id == 67 ||
        objeto.id == 70 ||
        objeto.id == 71 ||
        objeto.id == 72 ||
        objeto.id == 73 ||
        objeto.id == 74 ||
        objeto.id == 75 ||
        objeto.id == 75 ||
        objeto.id == 98 ||
        objeto.id == 99
      ) {
        this.cofins_inputs = true;
        this.Nfe.cofins_inputs = this.cofins_inputs;
      } else {
        this.cofins_inputs = false;
        this.Nfe.cofins_inputs = this.cofins_inputs;
      }
    },
    customLabel_id_cst({ id, name }) {
      return `${id} – ${name}`;
    },
    customLabelcsts({ id, nome }) {
      return `${id} – ${nome}`;
    },
    customLabelcsts_normal({ id, nome }) {
      return `${id} – ${nome}`;
    },
    customLabelIPI({ id, nome }) {
      return `${id} – ${nome}`;
    },
    calculo_cst_101() {
      let aliquota = this.new_convert_srting_float(this.Nfe.pCredSN);
      let v_prod = this.new_convert_srting_float(
        this.vetor_de_produtos[this.index].bruto
      );

      if (
        this.Nfe.pCredSN != null &&
        this.Nfe.pCredSN != undefined &&
        this.Nfe.pCredSN != ""
      ) {
        this.Nfe.vCredICMSSN = this.formatterCurrBR(v_prod * (aliquota / 100));
      }
      console.log(aliquota);
      console.log(v_prod);
    },
    seleciona_cst_normal(event) {
      console.log(event);
      this.Nfe.select_origem = this.origens[0];
      this.select_origem = this.origens[0];
      this.Nfe.select_cst_normal = event;
      let cst_id = event.id_es;
      this.Nfe.cst_reg_normal = event; // receber o objeto
      if (cst_id == 20) {
        console.log("foi selecionado o " + cst_id);
        this.cst_icms_20 = true;
        this.Nfe.cst_icms_20 = true;
      } else {
        this.cst_icms_20 = false;
        this.Nfe.cst_icms_20 = false;
      }

      if (cst_id == "00") {
        this.Nfe.cst_icms_20 = true;
        this.Nfe.cst00 = true;

        this.cst_icms_20 = true;
        this.cst00 = true;
      } else {
        this.cst00 = false;
        this.Nfe.cst00 = false;
      }

      if (cst_id == "40") {
        this.cst_icms_20 = true;
        this.cst40 = true;
        this.Nfe.cst40 = true;
        this.Nfe.cst_icms_20 = true;
      } else {
        this.Nfe.cst40 = false;
        this.cst40 = false;
      }

      if (cst_id == "02") {
        this.cst02 = true;
      } else {
        this.cst02 = false;
      }
      if (cst_id == "41") {
        this.cst41 = true;
      } else {
        this.cst41 = false;
      }
      if (cst_id == "50") {
        this.cst50 = true;
      } else {
        this.cst50 = false;
      }
      if (cst_id == "51") {
        this.cst51 = true;
        this.Nfe.cst51 = true;
      } else {
        this.Nfe.cst51 = false
        this.cst51 = false;
      }
      if (cst_id == "60") {
        this.cst60 = true;
      } else {
        this.cst60 = false;
      }
      if (cst_id == "90") {
        this.cst90 = true;
        this.Nfe.cst90 = true;
      } else {
        this.cst90 = false;
        this.Nfe.cst90 = false;
      }
    },
    seleciona_cst(event) {
      // if(event.id == 101){
      //   this.calculo_cst_101()
      // }
      console.log(event);

      this.Nfe.select_cson = event;
      if (
        this.Nfe.select_cson != null &&
        this.Nfe.select_cson != undefined &&
        this.Nfe.select_cson != ""
      ) {
        this.icms_vetifica = true;
      } else {
        this.icms_vetifica = false;
      }
      this.Nfe.icms_vetifica = this.icms_vetifica;
      if (typeof event.id == "number" && !isNaN(event.id)) {
        this.cst_cson = event.id;
      }
      if (event.id == 500) {
        this.show_inputs = true;
        this.Nfe.show_inputs = this.show_inputs;
        if (this.codigo_101 == true) {
          this.codigo_101 = false;
          this.Nfe.codigo_101 = this.codigo_101;
        }
      } else {
        this.show_inputs = false;
        this.Nfe.show_inputs = this.show_inputs;
        if (event.id == 101) {
          this.codigo_101 = true;
          this.Nfe.codigo_101 = this.codigo_101;
        } else {
          this.codigo_101 = false;
          this.Nfe.codigo_101 = this.codigo_101;
        }
      }
    },

    converte_vlr_icms() {
      //this.new_convert_srting_float()
      this.Nfe.vBCSTRet = this.new_convert_srting_float(this.Nfe.vBCSTRet);
      this.Nfe.vICMSSTRet = this.new_convert_srting_float(this.Nfe.vICMSSTRet);
      this.Nfe.vBCUFDest = this.new_convert_srting_float(this.Nfe.vBCUFDest); // valor da base de cálculo no ICMS de destino
      this.Nfe.vBCFCPUFDest = this.new_convert_srting_float(
        this.Nfe.vBCFCPUFDest
      ); // Valor da Base de Cálculo do FCP na UF de destino
      this.Nfe.pFCPUFDest = this.new_convert_srting_float(this.Nfe.pFCPUFDest); // Percentual do ICMS relativo ao Fundo de Combate à Pobreza (FCP) na UFcde destino
      this.Nfe.pICMSUFDest = this.new_convert_srting_float(
        this.Nfe.pICMSUFDest
      ); // Alíquota interna da UF de destino
      this.Nfe.pICMSInter = this.new_convert_srting_float(this.Nfe.pICMSInter); // Alíquota interestadual das UF envolvidas
      this.Nfe.pICMSInterPart = this.new_convert_srting_float(
        this.Nfe.pICMSInterPart
      ); // Percentual provisório de partilha do ICMS Interestadual
      this.Nfe.vFCPUFDest = this.new_convert_srting_float(this.Nfe.vFCPUFDest); //  Valor do ICMS relativo ao Fundo de Combate à Pobreza (FCP) da UF de  destino
      this.Nfe.vICMSUFDest = this.new_convert_srting_float(
        this.Nfe.vICMSUFDest
      ); // Valor do ICMS Interestadual para a UF de destino
      this.Nfe.vICMSUFRemet = this.new_convert_srting_float(
        this.Nfe.vICMSUFRemet
      );
      console.log("estou no converte valores de icms retido");
      console.log(this.Nfe.vBCSTRet);
      console.log(this.Nfe.vICMSSTRet);
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    converte_vlr_cst_101() {
      this.Nfe.vCredICMSSN = this.new_convert_srting_float(
        this.Nfe.vCredICMSSN
      );
      this.Nfe.pCredSN = this.new_convert_srting_float(this.Nfe.pCredSN);
    },
    converte_vlr_cofins() {
      this.Nfe.confinsvBC = this.new_convert_srting_float(this.Nfe.confinsvBC);
      this.Nfe.pCOFINS = this.new_convert_srting_float(this.Nfe.pCOFINS);
      this.Nfe.vCOFINS = this.new_convert_srting_float(this.Nfe.vCOFINS);
    },
    converte_vlr_pis() {
      this.Nfe.pisvBC = this.new_convert_srting_float(this.Nfe.pisvBC);
      this.Nfe.pPIS = this.new_convert_srting_float(this.Nfe.pPIS);
      this.Nfe.vPIS = this.new_convert_srting_float(this.Nfe.vPIS);
    },
    salvar() {
      console.log("Estou em salvar 1");
      if (this.obj_nfe.EmitCRT == 3) {
        this.salvar2();
      } else {
        if (
          !(
            this.Nfe.pisvBC != null &&
            this.Nfe.pisvBC != undefined &&
            this.Nfe.pisvBC != "" &&
            this.Nfe.pPIS != null &&
            this.Nfe.pPIS != undefined &&
            this.Nfe.pPIS != "" &&
            this.Nfe.vPIS != null &&
            this.Nfe.vPIS != undefined &&
            this.Nfe.vPIS != ""
          ) && //
          (this.Nfe.select_cst.id == "01" ||
            this.Nfe.select_cst.id == "02" ||
            this.Nfe.select_cst.id == "03" ||
            this.Nfe.select_cst.id == "49" ||
            this.Nfe.select_cst.id == "50" ||
            this.Nfe.select_cst.id == "51" ||
            this.Nfe.select_cst.id == "52" ||
            this.Nfe.select_cst.id == "53" ||
            this.Nfe.select_cst.id == "54" ||
            this.Nfe.select_cst.id == "55" ||
            this.Nfe.select_cst.id == "56" ||
            this.Nfe.select_cst.id == "60" ||
            this.Nfe.select_cst.id == "61" ||
            this.Nfe.select_cst.id == "62" ||
            this.Nfe.select_cst.id == "63" ||
            this.Nfe.select_cst.id == "64" ||
            this.Nfe.select_cst.id == "65" ||
            this.Nfe.select_cst.id == "66")
        ) {
          console.log("entrei no pis ");
          this.makeToast(
            "danger",
            "Preencher os Valores obrigatorios para PIS cst(01 - 02 - 03 )"
          );
        } else {
          if (
            !(
              this.Nfe.confinsvBC != null &&
              this.Nfe.confinsvBC != undefined &&
              this.Nfe.confinsvBC != "" &&
              this.Nfe.pCOFINS != null &&
              this.Nfe.pCOFINS != undefined &&
              this.Nfe.pCOFINS != "" &&
              this.Nfe.vCOFINS != null &&
              this.Nfe.vCOFINS != undefined &&
              this.Nfe.vCOFINS != ""
            ) &&
            (this.Nfe.confinsCST == "01" ||
              this.Nfe.confinsCST == "02" ||
              this.Nfe.confinsCST == "03" ||
              this.Nfe.confinsCST == "50" ||
              this.Nfe.confinsCST == "51" ||
              this.Nfe.confinsCST == "52" ||
              this.Nfe.confinsCST == "53" ||
              this.Nfe.confinsCST == "54" ||
              this.Nfe.confinsCST == "55" ||
              this.Nfe.confinsCST == "56" ||
              this.Nfe.confinsCST == "60" ||
              this.Nfe.confinsCST == "61" ||
              this.Nfe.confinsCST == "62" ||
              this.Nfe.confinsCST == "63" ||
              this.Nfe.confinsCST == "64" ||
              this.Nfe.confinsCST == "65" ||
              this.Nfe.confinsCST == "66" ||
              this.Nfe.confinsCST == "67" ||
              this.Nfe.confinsCST == "70" ||
              this.Nfe.confinsCST == "71" ||
              this.Nfe.confinsCST == "72" ||
              this.Nfe.confinsCST == "73" ||
              this.Nfe.confinsCST == "74" ||
              this.Nfe.confinsCST == "75" ||
              this.Nfe.confinsCST == "98" ||
              this.Nfe.confinsCST == "99")
          ) {
            this.makeToast(
              "danger",
              "preencha os valores obrigatorios do Cofins (Cst-01) "
            );
          } else {
            if (
              !(
                this.Nfe.vCredICMSSN != null &&
                this.Nfe.vCredICMSSN != undefined &&
                this.Nfe.vCredICMSSN != "" &&
                this.Nfe.pCredSN != null &&
                this.Nfe.pCredSN != undefined &&
                this.Nfe.pCredSN != ""
              ) &&
              this.Nfe.select_cson.id == 101
            ) {
              this.makeToast("danger", "Preencha os Valores em Vermelho");
            } else {
              if (
                this.Nfe.select_cst != null &&
                this.Nfe.select_cst != undefined &&
                this.Nfe.select_cst != "" &&
                this.Nfe.select_cst_confins != null &&
                this.Nfe.select_cst_confins != undefined &&
                this.Nfe.select_cst_confins != "" &&
                this.Nfe.selec_cst_ipi != null &&
                this.Nfe.selec_cst_ipi != undefined &&
                this.Nfe.selec_cst_ipi != ""
              ) {
                this.converte_vlr_IBPT();
                this.converte_vlr_cst00();
                this.converte_vlr_pis();
                this.converte_vlr_cofins();
                this.converte_vlr_icms();
                this.converte_vlr_cst_101();
                this.show_modal = false;
                let index = this.index;
                //let objeto= {...this.Nfe}
                // { objeto, index: index },
                console.log("cheguei no emit");
                this.$emit("impostos_salvos", this.cst_cson, this.Nfe, index);
              } else {
                this.makeToast("danegr", "Preencha o PIS , COFINS E IPI");
              }
            }
          }
        }
      }
    },
    salvar2() {
      //aqui no salvar 2
      console.log("Estou em salvar 2");
      // this.makeToast("danger", "esotu em salvar 2");
      if (
        !(
          this.select_mod_bc_norm !== null &&
          this.select_mod_bc_norm != undefined &&
          this.select_mod_bc_norm !== "" &&
          this.select_mod_bc_norm != -1 &&
          this.Nfe.vFCP != null &&
          this.Nfe.vFCP != undefined &&
          this.Nfe.vFCP != "" &&
          this.Nfe.pFCP != null &&
          this.Nfe.pFCP != undefined &&
          this.Nfe.pFCP != "" &&
          this.Nfe.vBCFCP != null &&
          this.Nfe.vBCFCP != undefined &&
          this.Nfe.vBCFCP != "" &&
          this.Nfe.vICMSDeson != null &&
          this.Nfe.vICMSDeson != undefined &&
          this.Nfe.vICMSDeson != "" &&
          this.Nfe.vICMS != null &&
          this.Nfe.vICMS != undefined &&
          this.Nfe.vICMS != "" &&
          this.Nfe.pICMS != null &&
          this.Nfe.pICMS != undefined &&
          this.Nfe.pICMS != "" &&
          this.Nfe.vBC != null &&
          this.Nfe.vBC != undefined &&
          this.Nfe.vBC != "" &&
          this.Nfe.pRedBC != null &&
          this.Nfe.pRedBC != undefined &&
          this.Nfe.pRedBC != ""
        ) &&
        this.Nfe.cst_reg_normal["id_es"] == "20"
      ) {
         this.makeToast("danger","Preencha os Valores em Vermelhos do cst 20")
      }else {

      
      if (
        !(
          this.select_mod_bc_norm !== null &&
          this.select_mod_bc_norm != undefined &&
          this.select_mod_bc_norm !== "" &&
          this.select_mod_bc_norm != -1 &&
          this.Nfe.vBC != null &&
          this.Nfe.vBC != undefined &&
          this.Nfe.vBC != "" &&
          this.Nfe.pICMS != null &&
          this.Nfe.pICMS != undefined &&
          this.Nfe.pICMS != "" &&
          this.Nfe.vICMS != null &&
          this.Nfe.vICMS != undefined &&
          this.Nfe.vICMS != "" &&
          this.Nfe.pFCP != null &&
          this.Nfe.pFCP != undefined &&
          this.Nfe.pFCP != "" &&
          this.Nfe.vFCP != null &&
          this.Nfe.vFCP != undefined &&
          this.Nfe.vFCP != ""
        ) &&
        this.Nfe.cst_reg_normal["id_es"] == "00"
      ) {
        this.makeToast("danger", "Preenhcar os valores do cst 00 em Vermelho");
      } else {
        if (
          !(
            this.Nfe.pisvBC != null &&
            this.Nfe.pisvBC != undefined &&
            this.Nfe.pisvBC != "" &&
            this.Nfe.pPIS != null &&
            this.Nfe.pPIS != undefined &&
            this.Nfe.pPIS != "" &&
            this.Nfe.vPIS != null &&
            this.Nfe.vPIS != undefined &&
            this.Nfe.vPIS != ""
          ) && //
          (this.Nfe.select_cst.id == "01" ||
            this.Nfe.select_cst.id == "02" ||
            this.Nfe.select_cst.id == "03" ||
            this.Nfe.select_cst.id == "49" ||
            this.Nfe.select_cst.id == "50" ||
            this.Nfe.select_cst.id == "51" ||
            this.Nfe.select_cst.id == "52" ||
            this.Nfe.select_cst.id == "53" ||
            this.Nfe.select_cst.id == "54" ||
            this.Nfe.select_cst.id == "55" ||
            this.Nfe.select_cst.id == "56" ||
            this.Nfe.select_cst.id == "60" ||
            this.Nfe.select_cst.id == "61" ||
            this.Nfe.select_cst.id == "62" ||
            this.Nfe.select_cst.id == "63" ||
            this.Nfe.select_cst.id == "64" ||
            this.Nfe.select_cst.id == "65" ||
            this.Nfe.select_cst.id == "66")
        ) {
          console.log("entrei no pis ");
          this.makeToast(
            "danger",
            "Preencher os Valores obrigatorios para PIS cst(01 - 02 - 03 )"
          );
        } else {
          if (
            !(
              this.Nfe.confinsvBC != null &&
              this.Nfe.confinsvBC != undefined &&
              this.Nfe.confinsvBC != "" &&
              this.Nfe.pCOFINS != null &&
              this.Nfe.pCOFINS != undefined &&
              this.Nfe.pCOFINS != "" &&
              this.Nfe.vCOFINS != null &&
              this.Nfe.vCOFINS != undefined &&
              this.Nfe.vCOFINS != ""
            ) &&
            (this.Nfe.confinsCST == "01" ||
              this.Nfe.confinsCST == "02" ||
              this.Nfe.confinsCST == "03" ||
              this.Nfe.confinsCST == "50" ||
              this.Nfe.confinsCST == "51" ||
              this.Nfe.confinsCST == "52" ||
              this.Nfe.confinsCST == "53" ||
              this.Nfe.confinsCST == "54" ||
              this.Nfe.confinsCST == "55" ||
              this.Nfe.confinsCST == "56" ||
              this.Nfe.confinsCST == "60" ||
              this.Nfe.confinsCST == "61" ||
              this.Nfe.confinsCST == "62" ||
              this.Nfe.confinsCST == "63" ||
              this.Nfe.confinsCST == "64" ||
              this.Nfe.confinsCST == "65" ||
              this.Nfe.confinsCST == "66" ||
              this.Nfe.confinsCST == "67" ||
              this.Nfe.confinsCST == "70" ||
              this.Nfe.confinsCST == "71" ||
              this.Nfe.confinsCST == "72" ||
              this.Nfe.confinsCST == "73" ||
              this.Nfe.confinsCST == "74" ||
              this.Nfe.confinsCST == "75" ||
              this.Nfe.confinsCST == "98" ||
              this.Nfe.confinsCST == "99")
          ) {
            this.makeToast(
              "danger",
              "preencha os valores obrigatorios do Cofins (Cst-01) "
            );
          } else {
            if (
              !(
                this.Nfe.vBC != null &&
                this.Nfe.vBC != undefined &&
                this.Nfe.vBC != "" &&
                this.Nfe.pICMS != null &&
                this.Nfe.pICMS != undefined &&
                this.Nfe.pICMS != "" &&
                this.Nfe.vICMS != null &&
                this.Nfe.vICMS != undefined &&
                this.Nfe.vICMS != "" &&
                this.Nfe.pFCP != null &&
                this.Nfe.pFCP != undefined &&
                this.Nfe.pFCP != "" &&
                this.Nfe.vFCP != null &&
                this.Nfe.vFCP != undefined &&
                this.Nfe.vFCP != ""
              ) &&
              this.Nfe.cst_reg_normal.id_es == "00"
            ) {
              this.makeToast("danger", "Preencha os Valores em Vermelho");
            } else {
              if (
                this.Nfe.select_cst != null &&
                this.Nfe.select_cst != undefined &&
                this.Nfe.select_cst != "" &&
                this.Nfe.select_cst_confins != null &&
                this.Nfe.select_cst_confins != undefined &&
                this.Nfe.select_cst_confins != "" &&
                this.Nfe.selec_cst_ipi != null &&
                this.Nfe.selec_cst_ipi != undefined &&
                this.Nfe.selec_cst_ipi != ""
              ) {
                this.converte_vlr_IBPT();
                this.converte_vlr_cst90();
                this.converte_vlr_cst_40();
                this.converte_vlr_cst20();
                this.converte_vlr_cst00();
                this.converte_vlr_pis();
                this.converte_vlr_cofins();
                this.converte_vlr_icms();
                // this.converte_vlr_cst_101();
                this.show_modal = false;
                let index = this.index;
                //let objeto= {...this.Nfe}
                // { objeto, index: index },
                console.log("cheguei no emit");
                this.$emit(
                  "impostos_salvos",
                  this.Nfe.cst_reg_normal.id_es,
                  this.Nfe,
                  index
                );
              } else {
                this.makeToast("danegr", "Preencha o PIS , COFINS E IPI");
              }
            }
          }
        }
      } // fim sele cst 00
    }// fim do else cst 20
    },
    seleciona_origem(event) {
      this.Nfe.select_origem = event;
      console.log(event);

      this.Nfe.orig = event.id;
    },
    back() {
      this.$emit("back");
    },
  },
};
</script>

<style scoped>
.cor {
  color: red !important; /* Substitua 'red' pela cor desejada */
}
</style>