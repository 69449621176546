<template>
  <div>
    <div class="row mb-4">
      <div class="col-md-12 text-center">
        <button class="btn btn-secondary" @click.prevent="selectFile2()">
          Exporta Xml +
        </button>
        <!-- <input type="file" class="form-control"> -->

        <input
          @change="handleFileChange"
          type="file"
          id="fileInput"
          style="display: none"
          webkitdirectory
          directory
          multiple
        />
        <!-- <button onclick="">Selecionar Arquivo</button> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-tabs pills card content-class="mt-3 danger" justified>
          <b-tab active>
            <template #title>
              <span class="fonte-tab">Gerais</span>
            </template>
            <div class="row">
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label">
                    <b-badge
                      data-name="Total"
                      class="field-status fonte"
                      :variant="
                        Nfe.serie != null && Nfe.serie != ''
                          ? 'success'
                          : 'danger'
                      "
                    >
                      {{ "*Série" }}
                    </b-badge>
                  </label>
                  <div class="col">
                    <input
                      v-model="Nfe.serie"
                      type="number"
                      class="form-control text-left"
                      placeholder="digite a serie"
                      id="id_serie"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label">
                    <b-badge
                      data-name="Total"
                      class="field-status fonte"
                      :variant="
                        Nfe.nNF != null && Nfe.nNF != '' ? 'success' : 'danger'
                      "
                    >
                      {{ "*Número" }}
                    </b-badge></label
                  >
                  <div class="col">
                    <input
                      v-model="Nfe.nNF"
                      type="text"
                      class="form-control text-left"
                      placeholder="Digite o Número"
                      id="insc_municipal_id"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label">
                    <b-badge
                      data-name="Total"
                      class="field-status fonte"
                      :variant="Nfe.dhEmi != '' ? 'success' : 'danger'"
                    >
                      {{ "*Data/Hora emissão" }}
                    </b-badge></label
                  >
                  <div class="col">
                    <input
                      v-model="Nfe.dhEmi"
                      type="datetime-local"
                      class="form-control text-right"
                      placeholder="seleciona a data da emissão"
                      id="insc_municipal_id"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label">
                    <b-badge
                      data-name="Total"
                      class="field-status fonte"
                      :variant="Nfe.dhSaiEnt != '' ? 'success' : 'danger'"
                    >
                      {{ "*Data/Hora Saida" }}
                    </b-badge></label
                  >
                  <div class="col">
                    <input
                      v-model="Nfe.dhSaiEnt"
                      type="datetime-local"
                      class="form-control text-right"
                      placeholder="seleciona a data da saida"
                      id="insc_municipal_id"
                    />
                  </div>
                </div>
              </div>
              <!-- <div class="col-sm-6 col-md-3">
              <div class="form-group">
                <label for="insc_municipal" class="col-form-label"
                  >Historico Padrão</label
                >
                <div class="col">
                  <multiselect
                    placeholder="Selecione"
                    label="descricao"
                    track-by="descricao"
                    :options="historicos_padr"
                    :option-height="104"
                    :custom-label="customLabelHistorico"
                    :show-labels="false"
                    deselect-label="ENTER para remover"
                    select-label="Pressione ENTER"
                    @select="selecthist($event)"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option__desc">
                        <span class="option__title"
                          >{{ props.option.id }} -
                          {{ props.option.descricao }}</span
                        >
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title"
                          >{{ props.option.id }} -
                          {{ props.option.descricao }}</span
                        >
                      </div> </template
                    ><span slot="noResult"
                      >Opa! Nenhum registro encontrado</span
                    >
                  </multiselect>
                  
                </div>
              </div>
            </div>-->
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label">
                    <b-badge
                      data-name="Total"
                      class="field-status fonte"
                      :variant="Nfe.finNFe != null ? 'success' : 'danger'"
                    >
                      {{ "*Finalidade NF-e" }}
                    </b-badge></label
                  >
                  <div class="col">
                    <multiselect
                      @select="seleciona_finalidade($event)"
                      @remove="remove_finalidade($event)"
                      v-model="select_finalidade"
                      :options="finalidades"
                      label="nome"
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label">
                    <b-badge
                      data-name="Total"
                      class="field-status fonte"
                      :variant="Nfe.indPres != null ? 'success' : 'danger'"
                    >
                      {{ "*Tipo de Atendimento" }}
                    </b-badge></label
                  >
                  <div class="col">
                    <multiselect
                      v-model="select_atendimento"
                      @select="seleciona_atendimento($event)"
                      @remove="remove_atendimento($event)"
                      :options="atendimentos"
                      label="nome"
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label fonte"
                    >Natureza Da Operação</label
                  >
                  <div class="col">
                    <input
                      v-model="Nfe.natOp"
                      type="text"
                      class="form-control text-left"
                      placeholder="digite "
                      id="natureza_id"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label">
                    <b-badge
                      data-name="Total"
                      class="field-status fonte"
                      :variant="Nfe.indFinal != null ? 'success' : 'danger'"
                    >
                      {{ "*Consumidor Final" }}
                    </b-badge></label
                  >
                  <div class="col">
                    <multiselect
                      v-model="select_consumidor"
                      @select="seleciona_consumidor($event)"
                      @remove="remove_consumidor($event)"
                      :options="consumidores"
                      label="nome"
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label fonte">{{
                    "Tipo de Movimento"
                  }}</label>
                  <div class="col">
                    <multiselect
                      v-model="select_tp_movimento"
                      label="nometpmov"
                      :options="tp_movimentos"
                      @select="select_tp_mov_preench($event)"
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label">
                    <b-badge
                      data-name="Total"
                      class="field-status fonte"
                      :variant="Nfe.mod != null ? 'success' : 'danger'"
                    >
                      {{ "*Modelo" }}
                    </b-badge></label
                  >
                  <div class="col">
                    <input
                      v-model="Nfe.mod"
                      type="text"
                      class="form-control text-left"
                      placeholder="digite Modelo"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <!-- {{ cfop_certt }} -->
                  <label for="insc_municipal" class="col-form-label fonte"
                    >CFOP</label
                  >
                  <div class="col">
                    <multiselect
                      v-model="select_cfop"
                      :options="listCFOP1"
                      label="id"
                      class="w-full md:w-20rem"
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
              <!-- <div class="col-sm-6 col-md-3">
              <div class="form-group">
                <label for="insc_municipal" class="col-form-label"
                  >Local de Estoque</label
                >
                <div class="col">
                  <multiselect label="descricao" :options="local_estoques">
                  </multiselect>
                </div>
              </div>
            </div> -->
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label fonte"
                    >CNPJ</label
                  >
                  <div class="col">
                    <!-- se for true não digita se for false digita -->
                    <input
                      :disabled="veri_intermed"
                      type="text"
                      class="form-control text-left"
                      placeholder="Digite o cnpj"
                      id="cnpj"
                      v-model="select_cnpj"
                    />
                  </div>
                </div>
              </div>
              <!-- <div class="col-sm-6 col-md-3">
              <div class="form-group">
                <label for="insc_municipal" class="col-form-label"
                  >Identificador do Vendedor</label
                >
                <div class="col">
                  <multiselect
                    placeholder="Selecione"
                    label="pes_fantasia"
                    track-by="pes_fantasia"
                    :options="pessoas"
                    :option-height="104"
                    :custom-label="customLabel_identificador_vendedor"
                    :show-labels="false"
                    deselect-label="ENTER para remover"
                    select-label="Pressione ENTER"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option__desc">
                        <span class="option__title"
                          >{{ props.option.id }} -
                          {{ props.option.pes_fantasia }}</span
                        >
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title"
                          >{{ props.option.id }} -
                          {{ props.option.pes_fantasia }}</span
                        >
                      </div> </template
                    ><span slot="noResult"
                      >Opa! Nenhum registro encontrado</span
                    >
                  </multiselect>
                </div>
              </div>
            </div> -->
              <div class="col-sm-6 col-md-4">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label"
                    >&nbsp;</label
                  >
                  <div class="col">
                    <b-form-checkbox
                      @change="intermed_veri($event)"
                      value="1"
                      id="checkbox3"
                      class="fonte"
                    >
                      Com Intermediador
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label fonte"
                    >Tipo de Documento</label
                  >
                  <div class="col">
                    <input
                      v-model="Nfe.tpNF"
                      type="radio"
                      id="option1"
                      class="ml-6"
                      value="0"
                    />
                    <label for="" class="fonte">Entrada</label>

                    <!-- <b-form-checkbox v-model="dados_pedido.tpnf" value="1" id="checkbox1">
                  Entrada
                </b-form-checkbox> -->
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label"
                    >&nbsp;</label
                  >
                  <div class="col">
                    <input
                      v-model="Nfe.tpNF"
                      type="radio"
                      id="option1"
                      class="ml-6"
                      value="1"
                    />
                    <label for="" class="fonte">Saida</label>
                    <!-- <b-form-checkbox v-model="dados_pedido.tpnf" value="2" id="checkbox2">
                  Saída
                </b-form-checkbox> -->
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <span class="fonte-tab">Dados Emitente </span>
            </template>
            <div class="row">
              <div class="col-sm-6 col-md-12">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label"
                    >Código/ Nome</label
                  >
                  <div class="col">
                    <multiselect
                      placeholder="Selecione"
                      label="pes_fantasia"
                      track-by="descricao"
                      :options="pessoas"
                      :option-height="104"
                      :custom-label="customLabel_identificador_vendedor"
                      :show-labels="false"
                      deselect-label="ENTER para remover"
                      select-label="Pressione ENTER"
                      @select="select_nome_emitente($event)"
                      v-model="select_desc_emitente"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title"
                            >{{ props.option.id }} -
                            {{ props.option.pes_apelido }}</span
                          >
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title"
                            >{{ props.option.id }} -
                            {{ props.option.pes_apelido }}</span
                          >
                        </div>
                      </template>
                      <span slot="noResult"
                        >Opa! Nenhum registro encontrado</span
                      >
                      <button
                        @click="abrir_modal_cliente()"
                        slot="noResult"
                        class="btn btn-primary ml-2"
                      >
                        Cadastrar cliente
                      </button>
                      <template>
                        <span slot="noOptions" class="mr-2"
                          >Lista Vazia Cadastre o Cliente</span
                        >
                        <button
                          @click="abrir_modal_cliente()"
                          slot="noOptions"
                          class="btn btn-primary ml-2"
                        >
                          Cadastrar cliente
                        </button>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <Label>Nome</Label>
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.EmitxNome"
                />
              </div>
              <div class="col-md-3">
                <Label>{{ "*Nome Fantasia" }}</Label>
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.EmitxFant"
                />
              </div>
              <div class="col-md-3">
                <Label>{{ "*Inscrição estadual" }}</Label>
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.EmitIE"
                />
              </div>
              <div class="col-md-3">
                <Label>Inscrição estadual ST</Label>
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.EmitIEST"
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-3">
                <Label>Inscrição Municipal</Label>
                <input
                  v-model="Nfe.EmitIM"
                  type="text"
                  class="form-control text-left"
                />
              </div>
              <div class="col-md-3">
                <Label>CNAE</Label>
                <input
                  v-model="Nfe.EmitCNAE"
                  type="text"
                  class="form-control text-left"
                />
              </div>
              <div class="col-md-3">
                <Label>CRT</Label>
                <!--  -->
                <!-- <input
                  v-model="Nfe.EmitCRT"
                  type="text"
                  class="form-control text-left"
                /> -->
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="select_crt"
                  @change="seleciona_crt($event)"
                >
                  <option value="-1">selecione um valor</option>
                  <option
                    v-for="(item, index) in crts"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.nome }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <Label>CNPj/CPF do Emitente</Label>
                <input
                  v-model="Nfe.EmitCNPJ"
                  type="text"
                  class="form-control text-left"
                  placeholder="digite o CNPJ"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.endEmitente variant="light"
                    >Endereço Emitente</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="endEmitente"
                  accordion="pedido-accordion"
                  role="tabpanel"
                >
                  <div class="row">
                    <div class="col-md-3">
                      <Label>{{ "*Logradouro" }}</Label>
                      <input
                        v-model="Nfe.EmitxLgr"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                    <div class="col-md-3">
                      <Label>{{ "*Número" }}</Label>
                      <input
                        v-model="Nfe.Emitnro"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                    <div class="col-md-3">
                      <Label>{{ "Complemento" }}</Label>
                      <input
                        v-model="Nfe.EmitxCpl"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                    <div class="col-md-3">
                      <Label>{{ "Bairro" }}</Label>
                      <input
                        v-model="Nfe.EmitxBairro"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <!-- <div class="col-md-4">
                     
                      <Label>Cod Municipio</Label>
                      <input
                        v-model="Nfe.EmitcMun"
                        type="text"
                        class="form-control text-left"
                      />
                    </div> -->
                    <div class="col-md-6">
                      <Label>UF</Label>
                      <!-- <input
                        v-model="Nfe.EmitUF"
                        type="text"
                        class="form-control text-left"
                      /> -->
                      <multiselect
                        v-model="select_uf"
                        :options="estados"
                        label="nome"
                        @select="select_cidade_por_uf($event)"
                      >
                      </multiselect>
                      <!-- usar um multiselec aqui para prenehcer apeasn o municipio -->
                    </div>
                    <div class="col-md-6">
                      <Label>Nome do município </Label>
                      <!-- <input
                        v-model="Nfe.EmitxMun"
                        type="text"
                        class="form-control text-left"
                      /> -->
                      <multiselect
                        v-model="select_municipio"
                        :options="cidades_ibge1"
                        label="nome"
                        @select="seleciona_cod_nome_municipio($event)"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <Label>CEP</Label>
                      <input
                        v-model="Nfe.EmitCEP"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                    <!-- colocar um modal para nçao precisa iforma o codido e o nome do pais -->
                    <div class="col-md-3">
                      <Label>Cod Pais</Label>
                      <input
                        v-model="Nfe.EmitcPais"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                    <div class="col-md-3">
                      <Label>Nome Pais</Label>
                      <input
                        v-model="Nfe.EmitxPais"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                    <div class="col-md-3">
                      <Label>Telefone</Label>
                      <input
                        v-model="Nfe.Emitfone"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <span class="fonte-tab">Dados Destinatário </span>
            </template>
            <div class="row">
              <div class="col-sm-6 col-md-12">
                <div class="form-group">
                  <label for="insc_municipal" class="col-form-label"
                    >Código/ Nome</label
                  >
                  <div class="col">
                    <multiselect
                      v-model="select_destinatario"
                      placeholder="Selecione"
                      label="pes_fantasia"
                      track-by="descricao"
                      :options="pessoas"
                      :option-height="104"
                      :custom-label="customLabel_identificador_vendedor"
                      :show-labels="false"
                      deselect-label="ENTER para remover"
                      select-label="Pressione ENTER"
                      @select="select_nome_destinatario($event)"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title"
                            >{{ props.option.id }} -
                            {{ props.option.pes_apelido }}</span
                          >
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title"
                            >{{ props.option.id }} -
                            {{ props.option.pes_apelido }}</span
                          >
                        </div>
                      </template>
                      <span slot="noResult"
                        >Opa! Nenhum registro encontrado</span
                      >
                      <button
                        @click="abrir_modal_cliente()"
                        slot="noResult"
                        class="btn btn-primary ml-2"
                      >
                        Cadastrar cliente
                      </button>
                      <template>
                        <span slot="noOptions" class="mr-2"
                          >Lista Vazia Cadastre o Cliente</span
                        >
                        <button
                          @click="abrir_modal_cliente()"
                          slot="noOptions"
                          class="btn btn-primary ml-2"
                        >
                          Cadastrar cliente
                        </button>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <Label>Nome</Label>
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.DestxNome"
                />
              </div>
              <div class="col-md-3">
                <Label>{{ "inf dest Contribuinte do ICMS" }}</Label>
                <!-- <input
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.DestindIEDest"
                /> -->
                <!--  -->
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="select_DestindIEDest"
                  @change="seleciona_DestindIEDest($event)"
                >
                  <option value="-1">selecione um valor</option>
                  <option
                    v-for="(item, index) in array_dest"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.nome }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <Label>{{ "*Inscrição estadual" }}</Label>
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.DestIE"
                />
              </div>
              <div class="col-md-3">
                <Label>Inscrição na SUFRAMA</Label>
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.DestISUF"
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-4">
                <Label>Inscrição Municipal</Label>
                <input
                  v-model="Nfe.DestIM"
                  type="text"
                  class="form-control text-left"
                />
              </div>
              <div class="col-md-4">
                <Label>E-mail</Label>
                <input
                  v-model="Nfe.Destemail"
                  type="text"
                  class="form-control text-left"
                />
              </div>
              <div class="col-md-4">
                <Label>CNPJ/CPF</Label>
                <input
                  v-model="Nfe.DestCNPJ"
                  type="text"
                  class="form-control text-left"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.endDestinatario variant="light"
                    >Endereço Destinatário</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="endDestinatario"
                  accordion="pedido-accordion"
                  role="tabpanel"
                >
                  <div class="row">
                    <div class="col-md-3">
                      <Label>{{ "*Logradouro" }}</Label>
                      <input
                        v-model="Nfe.DestxLgr"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                    <div class="col-md-3">
                      <Label>{{ "*Número" }}</Label>
                      <input
                        v-model="Nfe.Destnro"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                    <div class="col-md-3">
                      <Label>{{ "Complemento" }}</Label>
                      <input
                        v-model="Nfe.DestxCpl"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                    <div class="col-md-3">
                      <Label>{{ "Bairro" }}</Label>
                      <input
                        v-model="Nfe.DestxBairro"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <!-- <div class="col-md-4">
                      
                      <Label>Cod Municipio</Label>
                      <input
                        v-model="Nfe.DestcMun"
                        type="text"
                        class="form-control text-left"
                      />
                    </div> -->
                    <div class="col-md-6">
                      <!-- usar um multiselec aqui para prenehcer apeasn o municipio -->
                      <!-- <Label>Nome Municipio</Label>
                      <input
                        v-model="Nfe.DestxMun"
                        type="text"
                        class="form-control text-left"
                      /> -->
                      <Label>UF</Label>
                      <!-- <input
                        v-model="Nfe.DestUF"
                        type="text"
                        class="form-control text-left"
                      /> -->
                      <multiselect
                        v-model="select_uf_dest"
                        :options="estados"
                        label="nome"
                        @select="select_cidade_por_uf_destinatario($event)"
                      >
                      </multiselect>
                    </div>
                    <div class="col-md-6">
                      <Label>Nome do município </Label>
                      <multiselect
                        v-model="select_municipio_dest"
                        :options="cidades_ibge1"
                        label="nome"
                        @select="seleciona_cod_nome_municipio_dest($event)"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <Label>CEP</Label>
                      <input
                        v-model="Nfe.DestCEP"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                    <!-- colocar um modal para nçao precisa iforma o codido e o nome do pais -->
                    <div class="col-md-3">
                      <Label>Cod Pais</Label>
                      <input
                        v-model="Nfe.DestcPais"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                    <div class="col-md-3">
                      <Label>Nome Pais</Label>
                      <input
                        v-model="Nfe.DestxPais"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                    <div class="col-md-3">
                      <Label>Telefone</Label>
                      <input
                        v-model="Nfe.Destfone"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <span class="fonte-tab">Produtos </span>
            </template>
            <b-table
              bordered
              hover
              :items="produtos"
              id="doc-itens"
              :fields="fields"
            >
              <template #head(excluir)="data">
                <span
                  class="text-primary"
                  style="margin-left: 18px; margin-right: 18px"
                  >{{ data.label.toUpperCase() }}</span
                >
              </template>
              <template #cell(excluir)="row">
                <p>
                  <a id="tam" href="#" @click="add_produto(row.item, row.index)"
                    ><i class="fas fa-plus-circle"></i
                  ></a>
                  <a
                    id="tam"
                    href="#"
                    @click.prevent="impostos(row.item, row.index)"
                    class="mr-2 ml-2"
                    ><i
                      class="fas fa-dollar-sign"
                      :class="
                        (imposto_salvo == true && posicao_item == row.index) ||
                        imposto_salvos.includes(row.index)
                          ? 'fas fa-dollar-sign cor'
                          : 'fas fa-dollar-sign'
                      "
                    ></i
                  ></a>
                  <!-- fazer logica aqui; -->
                  <a id="tam" href="#" @click="dlt_produto(row.item, row.index)"
                    ><i class="fas fa-trash"></i
                  ></a>
                </p>
              </template>
              <template #cell(descricao)="row">
                <!-- <multiselect
                  style="margin-left: 0px; margin-right: 70px"
                  :options="produtos_array"
                  v-model="row.item.descricao"
                  label="produto_descricao"
                  @select="seleciona_produto($event, row.index)"
                >
                </multiselect> -->
                <!-- CASO DE PROBLEMA VOLTA PARA O MULTISELECT , E CHAMA A FUNÇÃO seleciona_produto -->
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="row.item.produto_id"
                  @change="seleciona_produto2($event, row.index)"
                >
                  <!-- criei o produto_id so para receber o id , mas oque é utilizado é o produto.descriçaõ -->
                  <option value="-1">selecione um item</option>
                  <option
                    v-for="(item, index) in produtos_array"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.produto_nome }}
                  </option>
                </select>
              </template>
              <template #head(descricao)="data">
                <span
                  class="text-primary"
                  style="margin-left: 0px; margin-right: 60px"
                  >{{ data.label.toUpperCase() }}</span
                >
              </template>
              <template #cell(unidade)="row">
                <multiselect
                  style="width: 108px"
                  v-model="row.item.unidade"
                  :options="unidades"
                  label="DescricaoUn"
                  :disabled="
                    produtos[row.index].produto_id == -1 ||
                    produtos[row.index].produto_id == undefined
                      ? true
                      : false
                  "
                >
                </multiselect>
              </template>
              <template #cell(cfop)="row">
                <multiselect
                  style="width: 110px"
                  v-model="row.item.cfop"
                  :options="listCFOP1"
                  label="id"
                  :disabled="
                    produtos[row.index].produto_id == -1 ||
                    produtos[row.index].produto_id == undefined
                      ? true
                      : false
                  "
                >
                </multiselect>
              </template>
              <template #head(cfop)="data">
                <span style="margin-left: 0px; margin-right: 30px">{{
                  data.label.toUpperCase()
                }}</span>
              </template>
              <template #cell(quantidade)="row">
                <input
                  :disabled="
                    produtos[row.index].produto_id == -1 ||
                    produtos[row.index].produto_id == undefined
                      ? true
                      : false
                  "
                  min="1"
                  type="number"
                  style="width: 50px"
                  class="form-control text-left"
                  v-model="row.item.quantidade"
                  @input="calcular_valorBruto(row.item, row.index)"
                />
              </template>
              <template #head(quantidade)="data">
                <span style="margin-left: 0px; margin-right: 6px">{{
                  data.label
                }}</span>
              </template>
              <template #cell(unitario)="row">
                <input
                  :disabled="
                    produtos[row.index].produto_id == -1 ||
                    produtos[row.index].produto_id == undefined
                      ? true
                      : false
                  "
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.unitario"
                  placeholder="digite"
                  @input="formatInput_valorUnitario(row.item, row.index)"
                />
              </template>
              <template #cell(bruto)="row">
                <input
                  disabled
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.bruto"
                  placeholder="digite"
                  @input="formatInput_valorBruto(row.item, row.index)"
                />
              </template>
              <!--  -->
              <template #cell(desconto)="row">
                <input
                  :disabled="
                    produtos[row.index].produto_id == -1 ||
                    produtos[row.index].produto_id == undefined
                      ? true
                      : false
                  "
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.desconto"
                  placeholder="Digite"
                  @input="formatInput_Desconto(row.item, row.index)"
                />
              </template>
              <template #cell(liquido)="row">
                <div v-if="veri_liquido">
                  <input
                    disabled
                    type="text"
                    class="form-control text-left"
                    v-model="row.item.liquido"
                    placeholder="Digite"
                  />
                </div>
              </template>
            </b-table>
          </b-tab>
          <!-- disabled -->
          <b-tab>
            <template #title>
              <span class="fonte-tab">Frete/Transportadora </span>
            </template>
            <div class="row">
              <div class="col-md-12">
                <Label>{{ "*Tipos de Frete" }}</Label>
                <multiselect
                  :options="fretes"
                  v-model="select_tp_frete"
                  label="name"
                  @select="seleciona_frete($event)"
                >
                </multiselect>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.transp_ID variant="light"
                    >Dados da Transportadora</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="transp_ID"
                  accordion="transp-accordion"
                  role="tabpanel"
                >
                  <div class="row">
                    <div class="col-md-4">
                      <Label>Nome</Label>
                      <input
                        v-model="Nfe.transpxNome"
                        type="text"
                        class="form-control text-left"
                        placeholder="Digite"
                      />
                    </div>
                    <div class="col-md-4">
                      <Label>IE </Label>
                      <input
                        v-model="Nfe.trnasIE"
                        type="text"
                        class="form-control text-left"
                        placeholder="Digite"
                      />
                    </div>
                    <div class="col-md-4">
                      <Label>{{ "*Endereço" }}</Label>
                      <input
                        v-model="Nfe.trnaspxEnder"
                        type="text"
                        class="form-control text-left"
                        placeholder="Digite"
                      />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-4">
                      <Label>{{ "*Municipio" }}</Label>
                      <input
                        v-model="Nfe.transpxMun"
                        type="text"
                        class="form-control text-left"
                        placeholder="Digite"
                      />
                    </div>
                    <div class="col-md-4">
                      <Label>{{ "UF" }}</Label>
                      <input
                        v-model="Nfe.transpUF"
                        type="text"
                        class="form-control text-left"
                        placeholder="Digite"
                      />
                    </div>
                    <div class="col-md-4">
                      <Label>{{ "*CNPJ/CPF " }}</Label>
                      <!-- trnaspCPF -->
                      <input
                        v-model="Nfe.trnaspCNPJ"
                        type="text"
                        class="form-control text-left"
                        placeholder="Digite"
                      />
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <span class="fonte-tab" @click.prevent="soma_itens()"
                >Pagamento</span
              >
            </template>
            <div class="row">
              <div class="col-md-6">
                <Label>
                  <b-badge
                    data-name="Total"
                    class="field-status fonte"
                    :variant="
                      select_tp_pagamento != null && select_tp_pagamento != ''
                        ? 'success'
                        : 'danger'
                    "
                  >
                    {{ "*Tipo de Pagamento" }}
                  </b-badge>
                </Label>
                <multiselect
                  @select="tipo_pag($event)"
                  label="tiponome"
                  :options="tp_pagamentos"
                  v-model="select_tp_pagamento"
                >
                </multiselect>
              </div>
              <div class="col-md-6">
                <Label>
                  <b-badge
                    data-name="Total"
                    class="field-status fonte"
                    :variant="
                      select_form_pagamento != null &&
                      select_form_pagamento != ''
                        ? 'success'
                        : 'danger'
                    "
                  >
                    {{ "*Forma de Pagamento" }}
                  </b-badge></Label
                >
                <multiselect
                  @select="forma_pag($event)"
                  :options="form_pagamentos"
                  v-model="select_form_pagamento"
                  label="name"
                >
                </multiselect>
              </div>
            </div>
            <div class="row mt-2" v-if="card">
              <div class="col-md-3">
                <Label>
                  <b-badge
                    data-name="Total"
                    class="field-status fonte"
                    :variant="
                      Nfe.dPag != null && Nfe.dPag != '' ? 'success' : 'danger'
                    "
                  >
                    {{ "*Data pagamento" }}
                  </b-badge></Label
                >
                <input
                  type="datetime-local"
                  class="form-control text-left"
                  v-model="Nfe.dPag"
                  placeholder="Digite"
                />
              </div>
              <div class="col-md-3">
                <Label>
                  <b-badge
                    data-name="Total"
                    class="field-status fonte"
                    :variant="
                      Nfe.CNPJ != null && Nfe.CNPJ != '' ? 'success' : 'danger'
                    "
                  >
                    {{ "*CNPJ da Cred. do Cartão" }}
                  </b-badge></Label
                >
                <input
                  placeholder="Digite"
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.CNPJ"
                />
              </div>
              <div class="col-md-3">
                <!-- bandeiras , tBand -->
                <Label>
                  <b-badge
                    data-name="Total"
                    class="field-status fonte"
                    :variant="
                      select_band_card != null &&
                      select_band_card != '' &&
                      select_band_card != -1
                        ? 'success'
                        : 'danger'
                    "
                  >
                    {{ "*Bandeira do Cartão" }}
                  </b-badge></Label
                >
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="select_band_card"
                  @change="seleciona_bandeira($event)"
                >
                  <option value="-1">selecione um valor</option>
                  <option
                    v-for="(item, index) in bandeiras"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.nome }}
                  </option>
                </select>
                <!-- <input type="text" class="form-control text-left" v-model="cartao.tBand"> -->
              </div>
              <div class="col-md-3">
                <Label>
                  <b-badge
                    data-name="Total"
                    class="field-status fonte"
                    :variant="
                      Nfe.cAut != null && Nfe.cAut != '' ? 'success' : 'danger'
                    "
                  >
                    {{ "*Número de Autorização" }}
                  </b-badge></Label
                >
                <input
                  placeholder="Digite"
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.cAut"
                />
              </div>
            </div>
            <!-- Campos Opcionais -->
            <div class="row mt-2 mb-2" v-if="card">
              <div class="col-md-3">
                <Label>CNPJ da Estabelecimento </Label>
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.CNPJPag"
                  placeholder="Digite"
                />
              </div>
              <div class="col-md-3">
                <Label>UF da Estabelecimento </Label>
                <input
                  placeholder="Digite"
                  type="text"
                  maxlength="2"
                  class="form-control text-left"
                  v-model="Nfe.UFPag"
                />
              </div>
              <div class="col-md-3">
                <Label>CNPJ do est benef do pagamento </Label>
                <input
                  placeholder="Digite"
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.CNPJReceb"
                />
              </div>
              <div class="col-md-3">
                <Label>id o Termnial Pagamento </Label>
                <input
                  placeholder="Digite"
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.idTermPag"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <Label>{{ "*Valor Pago" }}</Label>
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.vPag"
                  placeholder="Digite o Valor"
                  @input="formatInputFloat($event, Nfe, 'vPag')"
                />
              </div>
              <div class="col-md-6">
                <Label>{{ "*Valor Do Troco" }}</Label>
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="Nfe.vTroco"
                  placeholder="Digite o Valor"
                  @input="formatInputFloat($event, Nfe, 'vTroco')"
                />
              </div>
            </div>
            <div class="row mt-4" v-if="duplicatas">
              <div class="col-md-12">
                <b-table
                  bordered
                  hover
                  :items="array_duplicatas"
                  id="doc-itens"
                  :fields="fields_duplicatas"
                >
                  <template #cell(nDup)="row">
                    <input
                      type="text"
                      class="form-control text-left"
                      v-model="row.item.nDup"
                      placeholder="Digite"
                    />
                  </template>
                  <template #cell(dVenc)="row">
                    <input
                      type="datetime-local"
                      class="form-control text-left"
                      v-model="row.item.dVenc"
                    />
                  </template>
                  <template #cell(vDup)="row">
                    <input
                      type="text"
                      class="form-control text-left"
                      v-model="row.item.vDup"
                      placeholder="Digite"
                      @input="formatInputFloat($event, row.item, 'vDup')"
                    />
                  </template>
                </b-table>
              </div>
            </div>
          </b-tab>
          <b-tab title="">
            <template #title>
              <span class="fonte-tab">Inf Adicionais da NF-e</span>
            </template>
            <div class="row">
              <div class="col-md-12">
                <Label>{{
                  "Informações Adicionais de Interesse do Fisco"
                }}</Label>
                <textarea
                  v-model="Nfe.infAdFisco"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  class="form-control text-left"
                >
                </textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <Label>{{ "Outras Informações Complementares" }}</Label>
                <textarea
                  v-model="Nfe.infCpl"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  class="form-control text-left"
                ></textarea>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" v-if="impostos_modal">
        <ImpostoSimples
          :posicao_no_vetor="posicao_no_vetor"
          @impostos_salvos="impostos_salvos"
          @back="back"
          :impostos_modal="impostos_modal"
          :item="item1"
          :array_produto="produtos"
          :obj_nfe="Nfe"
          :Uf_dest_new="Uf_dest_new"
        ></ImpostoSimples>
      </div>
    </div>
    <div class="row" v-if="devolucao_bol">
      <div class="col-md-12">
        <Devolucao @recebe_vlr_devolucao="recebe_vlr_devolucao" />
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-md-12 text-center">
        <button class="btn btn-secondary mr-4" @click.prevent="emitirNfe()">
          Emitir NFe
        </button>
        <button class="btn btn-secondary" @click.prevent="salvarNfe()">
          Salvar NFe
        </button>
        <!-- <button class="btn btn-secondary ml-2" @click.prevent="cancelarNfe()">
          Cancelar NFe
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/helpers/easyindustriaapi/config";
import Multiselect from "vue-multiselect";
import { listCFOPs } from "@/components/defaults/cfops";
import ImpostoSimples from "./impostosNfeSimples.vue";
//import Vue from "vue";
import Devolucao from "./devolucao/devolucao.vue";
export default {
  components: {
    Multiselect,
    ImpostoSimples,
    Devolucao,
  },
  props: {
    Lista_Produtos: { type: Array },
    listUnidades: { type: Array },
    historicos: { type: Array },
    p1: { type: Array },
    movimentos: { type: Array },
    estoques: { type: Array },
    pagamentos: { type: Array },
    uploads: { type: Array },
    nfes_array: { type: Array },
    empresa_current: { type: Object },
    objeto_xml: { type: Object },
  },
  data() {
    return {
      Uf_dest_new: null,
      vet_variaveis_produtos: [
        {
          variaveis_produtos: true,
        },
      ],
      //variaveis_produtos:true,
      fileContent: null,
      tabble: true,
      veri_liquido: false,
      fields_duplicatas: [
        // {
        //   label: "Código",
        //   key: "id",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "Número",
          key: "nDup",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Data de Vencimento",
          key: "dVenc",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Valor da Duplicata",
          key: "vDup",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      array_duplicatas: [
        {
          id: 1,
          nDup: null,
          dVenc: null,
          vDup: null,
        },
      ],
      duplicatas: false,
      select_band_card: null,
      bandeiras: [
        {
          id: 1,
          nome: "Visa",
        },
        {
          id: 2,
          nome: "MasterCard",
        },
        {
          id: 3,
          nome: "American Express",
        },
        {
          id: 4,
          nome: "Sorocred",
        },
        {
          id: 5,
          nome: "Diners Club",
        },
        {
          id: 6,
          nome: "Elo",
        },
        {
          id: 7,
          nome: "Hipercard",
        },
        {
          id: 8,
          nome: "Aura",
        },
        {
          id: 9,
          nome: "Cabal",
        },
        {
          id: 99,
          nome: "Outros",
        },
      ],
      cartao: {},
      card: false,
      devolucao_bol: false,
      nfes: [],
      customTitle: `<span class="custom-tab-title">Gerais</span>`,
      atual_hora: null,
      atual_empresa: null,
      array_dest: [
        {
          id: 1,
          nome: "Contribuinte de ICMS",
        },
        {
          id: 2,
          nome: "Contribuinte Isento de Inscrição",
        },
        {
          id: 9,
          nome: "Não Contribuinte",
        },
      ],
      select_DestindIEDest: null,
      crts: [
        {
          id: 1,
          nome: "Simples Nacional",
        },
        {
          id: 2,
          nome: "Simples Nacional que ultrapassaram o limite de receita bruta fixado e não podem recolher pelo regime simplificado",
        },
        {
          id: 3,
          nome: "optantes pelo regime normal (demais regimes tributários",
        },
      ],
      select_crt: null,
      select_municipio: null,
      select_municipio_dest: null,
      select_uf: null,
      select_uf_dest: null,
      cidades_ibge1: [],
      estados: [],
      select_desc_emitente: null,
      select_cnpj: null,
      vlr_uni: null,
      select_destinatario: null,
      imposto_salvo: false,
      imposto_salvos: [],
      //  serve para mudar a cor de uma botão especifico, se não usar aplicar a cor para todos os botões
      posicao_item: null,
      tp_pagamentos: [],
      select_form_pagamento: null,
      form_pagamentos: [
        {
          id: 0,
          name: "A vista",
          tpag: "00",
        },
        {
          id: 1,
          name: "A prazo",
          tpag: "01",
        },
      ],
      select_tp_pagamento: null,
      select_tp_frete: null,

      fretes: [
        {
          id: 0,
          name: " Por conta do emitente",
        },
        {
          id: 1,
          name: " Por conta do destinatárioremetente",
        },
        {
          id: 2,
          name: "Por conta de terceiros",
        },
        {
          id: 3,
          name: "Transporte Proprio Por Conta do Remetente",
        },
        {
          id: 4,
          name: "Transporte Proprio Por Conta do Destinatário",
        },
        {
          id: 9,
          name: "Sem frete./ sem Ocorrenticia de transporte",
        },
      ],
      // Novos fretes ver com Paulo
      // // 0 - Contratação do Frete por conta do Remetente (CIF);
      // 1 - Contratação do Frete por conta do Destinatário (FOB);
      // 2 - Contratação do Frete por conta de Terceiros;
      // 9 - Sem Ocorrência de Transporte;
      // 3 - Transporte Próprio por conta do Remetente;
      // 4 - Transporte Próprio por conta do Destinatário.
      item1: null,
      posicao_no_vetor: null,
      unidades: [],
      produtos: [
        {
          id: 1,
          descricao: "",
          unidade: "",
          cfop: "",
          quantidade: 1,
          unitario: "",
          bruto: "",
          desconto: "",
          new_nfe_objeto: {},
          liquido: null,
        },
      ],
      produtos_array: [],
      fields: [
        // {
        //   label: "Código",
        //   key: "id",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "Item",
          key: "descricao",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "UN",
          key: "unidade",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "CFOP",
          key: "cfop",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Qtd",
          key: "quantidade",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Vlr Unitario",
          key: "unitario",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Vlr Bruto",
          key: "bruto",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Desconto",
          key: "desconto",
          tdClass: "text-center",
          thClass: "text-center",
        },

        // {
        //   label: "Ativo",
        //   key: "ativo",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Número", key: "numero", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Série", key: "serie", tdClass: 'text-center', thClass: 'text-center', formatter: "formatterCurrBR" },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        {
          // label: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Ações\u00A0\u00A0\u00A0",
          label: "Acões",
          key: "excluir",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      veri_intermed: true,
      local_estoques: [],
      listCFOP1: [],
      tp_movimentos: [],
      consumidores: [
        {
          id: 0,
          nome: "Não",
        },
        {
          id: 1,
          nome: "Sim",
        },
      ],
      atendimentos: [
        {
          id: 0,
          nome: "Não se aplica",
        },
        {
          id: 1,
          nome: "Operação Presencial",
        },
        {
          id: 2,
          nome: "Operação Não Presencial - Pela internet",
        },
        {
          id: 3,
          nome: "Operação Não Presencial - tele atendiemtno",
        },
        {
          id: 4,
          nome: "NFC-e em Operação com entregaa domicilio",
        },
        {
          id: 5,
          nome: "Operação Presencial - Fora do estabelecimento",
        },
        {
          id: 9,
          nome: "Operação Não Presencial - Outros",
        },
      ],
      pessoas: [],
      historicos_padr: [],
      nome: "ola",
      Nfe: {
        vet: [],
        cUF: 29,
        cNF: Math.random() * (999999 - 111111) + 111111,
        natOp: "Venda",
        mod: null,
        serie: null,
        nNF: null,
        dhEmi: "",
        dhSaiEnt: "",
        // etrada ou saida(tpNF),
        tpNF: 1,
        // dentro ou fora do estado(idDest),
        idDest: 1,
        // codigo municipio
        cMunFG: 2916609, //
        // tipo de impresão (1-retrato , 2-paisagem)
        tpImp: 1,
        // tipo da emissão
        tpEmis: 1,
        // digitp Verificador
        cDV: 2,
        // tipo do ambiente
        tpAmb: null,
        // finalidade
        finNFe: null,
        //  se é consumidor final ou não,
        indFinal: null,
        // tipo de operação , se foi presencial ou pela internet
        indPres: null,
        // indIntermed : 0,
        procEmi: 0,
        verProc: "3.10.31",
        dhCont: null,
        xJust: null,
        // Emitente//Node com os dados do emitente;
        EmitxNome: "",
        EmitxFant: "",
        EmitIE: "", /// inscrição estadual
        EmitIEST: "",
        EmitIM: "",
        EmitCNAE: "",
        EmitCRT: "1",
        EmitCNPJ: "",
        EmitCPF: "",
        // // Node com o endereço do emitente
        EmitxLgr: "",
        Emitnro: "",
        EmitxCpl: "",
        EmitxBairro: "",
        EmitcMun: "",
        EmitxMun: "",
        EmitUF: "",
        EmitCEP: "",
        EmitcPais: "",
        EmitxPais: "",
        Emitfone: "",
        // // Node com os dados do destinatário

        DestxNome: "",
        DestindIEDest: "", // 1 para contribuinte, e 2 para não contribuente (isento)
        DestIE: null,
        DestISUF: "",
        DestIM: "",
        Destemail: "",
        DestCNPJ: "",
        DestCPF: "",

        //   Node de endereço do destinatário
        DestxLgr: "",
        Destnro: "",
        DestxCpl: "",
        DestxBairro: "",
        DestcMun: "",
        DestxMun: "",
        DestUF: "",
        DestCEP: "",
        DestcPais: "",
        DestxPais: "",
        Destfone: "",

        // a produtos;

        item: 1, //item da NFe
        // cod prod
        cProd: "7898162003655",
        // cod Barra
        cEAN: "7898162003655",
        // cBarra : "",
        xProd: "MANGUEIRA CJ JARDIM CR 30M ACQUAFL",
        NCM: "39173100",
        cBenef: "",
        EXTIPI: "",
        CFOP: 5102,
        uCom: "UN", // unidade comercial
        qCom: "1.0000", //quantidade comercial
        vUnCom: "130.00",
        vProd: "130.00", // quantidade tributavel * vUnTrib
        cEANTrib: "7898162003655",
        cBarraTrib: "",
        uTrib: "UN",
        qTrib: "1.0000", // quantidade tributavel * vUnTrib
        vUnTrib: "130.0000000000",
        // vFrete : 1.00,
        // vSeg : 1.00,
        // vDesc : 1.00,
        // vOutro : 1.00,
        indTot: "1",
        // xPed : "",
        // nItemPed : "",
        // nFCI = "", nota de importação;

        // //  informacao adicional do Produto

        infProditem: 1, //item da NFe
        infProdinfAdProd: "",

        // Node inicial dos Tributos incidentes no Produto ou Serviço do item da NFe
        itemImposto: 1, //item da NFe
        vTotTrib: 0.0,

        // // Node referente Tributação ICMS pelo Simples Nacional do item da NFe
        itemSN: 1, //item da NFe // item Simples nacinal;
        orig: null,
        CSOSN: null,
        // so usa esses ters de cima para simple nacional,
        pCredSN: 0.0,
        vCredICMSSN: 0.0,
        modBCST: null,
        pMVAST: null,
        pRedBCST: null,
        vBCST: null,
        pICMSST: null,
        //vICMSST : null,
        vBCFCPST: null, //incluso no layout 4.00
        pFCPST: null, //incluso no layout 4.00
        vFCPST: null, //incluso no layout 4.00
        vBCSTRet: null,
        pST: null,
        vICMSSTRet: null,
        vBCFCPSTRet: null, //incluso no layout 4.00
        pFCPSTRet: null, //incluso no layout 4.00
        vFCPSTRet: null, //incluso no layout 4.00
        modBC: null,
        vBC: null,
        pRedBC: null,
        pICMS: null,
        vICMS: null,
        pRedBCEfet: null,
        vBCEfet: null,
        pICMSEfet: null,
        vICMSEfet: null,
        vICMSSubstituto: null,

        // Node referente ao IPI do item da NFe

        //item: $i + 1, //item da NFe
        clEnq: null,
        CNPJProd: null,
        cSelo: null,
        qSelo: null,
        cEnq: "999",
        ipiCST: "53",
        vIPIitem: 150.0,
        ipivBC: "0.00",
        pIPI: 15.0,
        qUnid: null,
        vUnid: null,

        // Node PIS do item da NFe
        itemPis: 1, //item da NFe // para indicar o item que vai aplicar o imposto,
        CST: "08",
        pisvBC: 260.0, // psi base de calculo;
        pPIS: 1.65,
        vPIS: this.pisvBC * (this.pPIS / 100), // isso não afeto o backend , o calculo é feito lá
        qBCProd: null,
        vAliqProd: null,

        // // Node COFINS do item da NFe

        confinsitem: 1, //item da NFe
        confinsCST: "01",
        confinsvBC: "0.00",
        pCOFINS: "0.00",
        vCOFINS: "0.00",
        confinsqBCProd: null,
        confinsvAliqProd: null,

        // // Node dos totais referentes ao ICMS

        totIcmsvBC: "0.00",
        totIcmsvICMS: "0.00",
        vICMSDeson: "0.00",
        totIcmsvBCST: "0.00",
        vST: "0.00",
        totIcmsvProd: "260.00",
        vFrete: "0.00",
        vSeg: "0.00",
        vDesc: "0.00",
        vII: "0.00",
        vIPI: "0.00",
        totIcmsvPIS: "0.0",
        totIcmsvCOFINS: "0.00",
        vOutro: "0.00",
        vNF: "260.00",
        vIPIDevol: "",
        totIcmsvTotTrib: "0.00",
        vFCP: "0.00",
        totIcmsvFCPST: "0.00",
        totIcmsvFCPSTRet: "0.00",
        vFCPUFDest: "0.00",
        vICMSUFDest: "53.55",
        vICMSUFRemet: "0.00",
        qBCMono: "100.0",
        vICMSMono: "0.00",
        qBCMonoReten: "100.0",
        vICMSMonoReten: "0",
        qBCMonoRet: "100.0",
        vICMSMonoRet: "0.00",

        // Node indicativo da forma de frete
        modFrete: 0,

        // Node com os dados da transportadora
        transpxNome: "",
        transpIE: "",
        transpxEnder: "",
        transpxMun: "",
        transpUF: "",
        transpCNPJ: "", //só pode haver um ou CNPJ ou CPF, se um deles é especificado o outro deverá ser null
        transpCPF: null,

        // Node referente as formas de pagamento OBRIGATÓRIO para NFCe a partir do layout 3.10 e também obrigatório para NFe (modelo 55) a partir do layout 4.00
        // pagamento;
        vTroco: 0.0, //incluso no layout 4.00, obrigatório informar para NFCe (65)

        // DETAHLE DO PAGAMENTO
        // Node com o detalhamento da forma de pagamento OBRIGATÓRIO para NFCe e NFe layout4.00

        indPag: "0", //0: Pagamento à Vista 1: Pagamento à Prazo
        tPag: "02",
        vPag: null, //Obs: deve ser informado o valor pago pelo cliente
        dPag: null,
        CNPJ: null,
        tBand: null,
        cAut: null,
        tpIntegra: null, //incluso na NT 2015/002
        CNPJPag: null, //NT 2023.004 v1.00
        UFPag: null, //NT 2023.004 v1.00
        CNPJReceb: null, //NT 2023.004 v1.00
        idTermPag: null, //NT 2023.004 v1.00
        //
        //referente as informações adicionais da NFe

        infAdFisco: "",
        infCpl: "",
        //  info suplemneto
        qrcode: "123123",
        urlChave: "asdasd1232",

        // repossavel tecnico
        RespCNPJ: "08664531000190", //CNPJ da pessoa jurídica responsável pelo sistema utilizado na emissão do documento fiscal eletrônico
        RespxContato: "Paulo Henrique Andrade", //Nome da pessoa a ser contatada
        Respemail: "contato@sidsolucoes.com.br", //E-mail da pessoa jurídica a ser contatada
        Respfone: "87999658729", //Telefone da pessoa jurídica/física a ser contatada
        RespCSRT: "G8063VRTNDMO886SFNK5LDUDEI24XJ22YIPO", //Código de Segurança do Responsável Técnico
        RespidCSRT: "01", //Identificador do CSRT
      },
      finalidades: [
        {
          id: 1,
          nome: "NF-e Normal",
        },
        {
          id: 2,
          nome: "NF-e Complementar",
        },
        {
          id: 3,
          nome: "NF-e de Ajuste",
        },
        {
          id: 4,
          nome: "Devolucão/Retorno",
        },
      ],
      select_finalidade: null,
      select_atendimento: null,
      select_consumidor: null,
      select_tp_movimento: null,
      select_cfop: null,
      impostos_modal: false,
      array_uploads: [],
    };
  },
  created() {
    // empresa_current , //
    this.atual_empresa = this.empresa_current;
    this.select_crt = 1;
    this.select_atendimento = this.atendimentos[0];
    this.Nfe.indPres = this.atendimentos[0].id;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.tp_movimentos = this.movimentos;
    this.historicos_padr = this.historicos;
    this.pessoas = this.p1;
    this.listCFOP1 = listCFOPs;
    this.local_estoques = this.estoques;
    this.produtos_array = this.Lista_Produtos;
    this.unidades = this.listUnidades;
    this.tp_pagamentos = this.pagamentos;
    this.array_uploads = this.uploads;
    this.nfes = this.nfes_array;
    this.Nfe.serie = 1;
    this.Nfe.mod = 55;
    this.select_consumidor = this.consumidores[0];
    this.Nfe.indFinal = this.consumidores[0].id;
    this.select_tp_frete = this.fretes[0];
    this.Nfe.tpAmb = this.uploads[0].uhomprod;
  },
  mounted() {
    console.log("Mostrando o tipo de Ambiente setado pela tablea uploadas");
    console.log(this.Nfe.tpAmb);
    // console.log("mostando a empresa autal no NFE simples");
    // console.log(this.atual_empresa);
    console.log("msotrando obejto xml nfe simples");
    console.log(this.objeto_xml);

    if (
      this.objeto_xml != null &&
      this.objeto_xml != undefined &&
      this.objeto_xml != ""
    ) {
      this.fileContent = this.objeto_xml.conteudo;
      this.ler_xml();
    }
    this.hora_atual();
    setInterval(this.hora_atual(), 1000);
    window.addEventListener("scroll", this.handleScroll);
    //console.log("mostrando os tipo de pagamentos");
    //console.log(this.pagamentos);
    //this.valor_inicial();
    this.estados_ibge();
    console.log("mostrando os certificados no simpleNfes");
    console.log(this.array_uploads);
    console.log(this.p1);
    console.log(this.currentEmpresa);
    this.preenche_num_nfe();
  },
  beforeDestroy() {
    // Certifique-se de remover o listener quando o componente for destruído para evitar vazamentos de memória
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    recaulculo_cst20(index) {
      console.log(index);
      console.log("Estou recalculo cst20");
      console.log(this.produtos[index].new_nfe_objeto);
      if (
        this.produtos[index].new_nfe_objeto != null &&
        this.produtos[index].new_nfe_objeto != undefined &&
        this.produtos[index].new_nfe_objeto != ""
      ) {
        if (
          this.produtos[index].new_nfe_objeto.pICMS != null &&
          this.produtos[index].new_nfe_objeto.pICMS != undefined &&
          this.produtos[index].new_nfe_objeto.pICMS != "" &&
          this.produtos[index].new_nfe_objeto.pFCP != null &&
          this.produtos[index].new_nfe_objeto.pFCP != undefined &&
          this.produtos[index].new_nfe_objeto.pFCP != ""
        ) {
          console.log("esta entrando no if");
          console.log(this.produtos[index].new_nfe_objeto);
          console.log(this.produtos[index].new_nfe_objeto.vBC);
          console.log(this.produtos[index].new_nfe_objeto.pICMS);
          console.log(this.produtos[index].new_nfe_objeto.vICMS);
          console.log(this.produtos[index].new_nfe_objeto.pFCP);
          console.log(this.produtos[index].new_nfe_objeto.vFCP);
          this.produtos[index].new_nfe_objeto.vBC =
            this.new_convert_srting_float(this.produtos[index].bruto);
          console.log("motrando novo vBC");
          console.log(this.produtos[index].new_nfe_objeto.vBC);
          console.log("mostrando Novo valor ICMS");
          let new_vlr_icms =
            (this.produtos[index].new_nfe_objeto.vBC *
              this.produtos[index].new_nfe_objeto.pICMS) /
            100;
          this.produtos[index].new_nfe_objeto.vICMS = new_vlr_icms;
          console.log(this.produtos[index].new_nfe_objeto.vICMS);
          console.log("Mostrando NOvo valor de FCP");
          let new_vlr_fcp =
            (this.produtos[index].new_nfe_objeto.vBC *
              this.produtos[index].new_nfe_objeto.pFCP) /
            100;
          this.produtos[index].new_nfe_objeto.vFCP = new_vlr_fcp;
          console.log(this.produtos[index].new_nfe_objeto.vFCP);
        }
      }
    },
    recaulculo_cst51(index) {
      console.log(index);
      console.log("Estou recalculo cst51");
      console.log(this.produtos[index].new_nfe_objeto);
      if (
        this.produtos[index].new_nfe_objeto != null &&
        this.produtos[index].new_nfe_objeto != undefined &&
        this.produtos[index].new_nfe_objeto != ""
      ) {
        if (
          this.produtos[index].new_nfe_objeto.pICMS != null &&
          this.produtos[index].new_nfe_objeto.pICMS != undefined &&
          this.produtos[index].new_nfe_objeto.pICMS != "" &&
          this.produtos[index].new_nfe_objeto.pFCP != null &&
          this.produtos[index].new_nfe_objeto.pFCP != undefined &&
          this.produtos[index].new_nfe_objeto.pFCP != ""
        ) {
          console.log("esta entrando no if");
          console.log(this.produtos[index].new_nfe_objeto);
          console.log(this.produtos[index].new_nfe_objeto.vBC);
          console.log(this.produtos[index].new_nfe_objeto.pICMS);
          console.log(this.produtos[index].new_nfe_objeto.vICMS);
          console.log(this.produtos[index].new_nfe_objeto.pFCP);
          console.log(this.produtos[index].new_nfe_objeto.vFCP);
          this.produtos[index].new_nfe_objeto.vBC =
            this.new_convert_srting_float(this.produtos[index].bruto);
          console.log("motrando novo vBC");
          console.log(this.produtos[index].new_nfe_objeto.vBC);
          console.log("mostrando Novo valor ICMS");
          let new_vlr_icms =
            (this.produtos[index].new_nfe_objeto.vBC *
              this.produtos[index].new_nfe_objeto.pICMS) /
            100;
          this.produtos[index].new_nfe_objeto.vICMS = new_vlr_icms;
          console.log(this.produtos[index].new_nfe_objeto.vICMS);
          console.log("Mostrando NOvo valor de FCP");
          let new_vlr_fcp =
            (this.produtos[index].new_nfe_objeto.vBC *
              this.produtos[index].new_nfe_objeto.pFCP) /
            100;
          this.produtos[index].new_nfe_objeto.vFCP = new_vlr_fcp;
          console.log(this.produtos[index].new_nfe_objeto.vFCP);
        }
      }
    },
    recalculo_cst00(index) {
      console.log("Estou recalculo cst00");
      console.log(this.produtos[index].new_nfe_objeto);
      if (
        this.produtos[index].new_nfe_objeto != null &&
        this.produtos[index].new_nfe_objeto != undefined &&
        this.produtos[index].new_nfe_objeto != ""
      ) {
        if (
          this.produtos[index].new_nfe_objeto.pICMS != null &&
          this.produtos[index].new_nfe_objeto.pICMS != undefined &&
          this.produtos[index].new_nfe_objeto.pICMS != "" &&
          this.produtos[index].new_nfe_objeto.pFCP != null &&
          this.produtos[index].new_nfe_objeto.pFCP != undefined &&
          this.produtos[index].new_nfe_objeto.pFCP != ""
        ) {
          console.log("esta entrando no if");
          console.log(this.produtos[index].new_nfe_objeto);
          console.log(this.produtos[index].new_nfe_objeto.vBC);
          console.log(this.produtos[index].new_nfe_objeto.pICMS);
          console.log(this.produtos[index].new_nfe_objeto.vICMS);
          console.log(this.produtos[index].new_nfe_objeto.pFCP);
          console.log(this.produtos[index].new_nfe_objeto.vFCP);
          this.produtos[index].new_nfe_objeto.vBC =
            this.new_convert_srting_float(this.produtos[index].bruto);
          console.log("motrando novo vBC");
          console.log(this.produtos[index].new_nfe_objeto.vBC);
          console.log("mostrando Novo valor ICMS");
          let new_vlr_icms =
            (this.produtos[index].new_nfe_objeto.vBC *
              this.produtos[index].new_nfe_objeto.pICMS) /
            100;
          this.produtos[index].new_nfe_objeto.vICMS = new_vlr_icms;
          console.log(this.produtos[index].new_nfe_objeto.vICMS);
          console.log("Mostrando NOvo valor de FCP");
          let new_vlr_fcp =
            (this.produtos[index].new_nfe_objeto.vBC *
              this.produtos[index].new_nfe_objeto.pFCP) /
            100;
          this.produtos[index].new_nfe_objeto.vFCP = new_vlr_fcp;
          console.log(this.produtos[index].new_nfe_objeto.vFCP);
        }
      }
      console.log("valor bruto");
      console.log(this.produtos[index].bruto); // deve converte
      console.log("Quantidade");
      console.log(this.produtos[index].quantidade); // deve converte;
    },
    async salvarNfe(objeto) {
      if (typeof this.Nfe.vPag != "number") {
        this.Nfe.vPag = 0;
      }
      this.Nfe.vet = this.produtos;
      objeto = this.Nfe;
      objeto.empresa_id = this.currentEmpresa.id;
      objeto.status = 2;
      console.log("estuo em salvar Nfe não autorizadas");
      console.log(objeto);
      try {
        let response = await http.post(
          "nfe/salvarnfe?empresa_id=" + this.currentEmpresa.id,
          objeto
        );
        if (response.status == 200) {
          // console.log(response.data);
          // let msg = response.data;
          console.log(response.data);
          this.makeToast("success", "Registros salvos");
          this.voltar();
        }
      } catch (e) {
        console.log(e.data);
        this.makeToast("danger", e.data);
      }
    },
    selecinando_xml_nfe(objeto) {
      console.log("estou no objeto xml nfe simples");
      console.log(objeto);
    },
    selectFile2() {
      document.getElementById("fileInput").click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];

      if (file) {
        this.readFileContent(file)
          .then((content) => {
            this.fileContent = content;
            this.ler_xml();
          })
          .catch((error) => {
            console.error("Erro ao ler o conteúdo do arquivo:", error);
          });
      }
    },

    readFileContent(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          resolve(e.target.result);
        };

        reader.onerror = (e) => {
          reject(e.target.error);
        };

        reader.readAsText(file);
      });
    },

    ler_xml() {
      // console.log("estou em ler xml ");
      console.log(this.fileContent);
      if (typeof this.fileContent === "string") {
        console.log("é uma srting");
      }
      // xml em string //
      let string_xml = this.fileContent;
      // varivel que vai receber o conteudo convertido de string para xml
      let xmlDocument = new DOMParser().parseFromString(string_xml, "text/xml");
      console.log(xmlDocument);
      let nomes = xmlDocument.querySelectorAll("xProd");
      let listaNomes = [];

      nomes.forEach((nome) => {
        listaNomes.push(nome.textContent);
      });
      console.log(listaNomes);
      let detElements = xmlDocument.querySelectorAll("det");
      let detArray = [];

      detElements.forEach((det) => {
        let detObj = {
          nItem: det.getAttribute("nItem"),
          cProd: det.querySelector("cProd").textContent,
          xProd: det.querySelector("xProd").textContent,
          uCom: det.querySelector("uCom").textContent,
          qCom: parseFloat(det.querySelector("qCom").textContent),
          vUnCom: parseFloat(det.querySelector("vUnCom").textContent),
          vProd: parseFloat(det.querySelector("vProd").textContent),
        };
        detArray.push(detObj);
      });

      console.log(detArray); // array de objeto de det , que é o objeto com as informaççoes dos produtos
      let total_prod = xmlDocument.querySelector("total");

      let totalObj = {
        vProd: parseFloat(total_prod.querySelector("vProd").textContent),
        vFrete: parseFloat(total_prod.querySelector("vFrete").textContent),
        vDesc: parseFloat(total_prod.querySelector("vDesc").textContent),
        vOutro: parseFloat(total_prod.querySelector("vOutro").textContent),
        vNF: parseFloat(total_prod.querySelector("vNF").textContent),
      };
      console.log(totalObj);

      let emitElement = xmlDocument.querySelector("emit");
      let emitObj = {
        CNPJ: emitElement.querySelector("CNPJ").textContent,
        xNome: emitElement.querySelector("xNome").textContent,
        xFant: emitElement.querySelector("xFant").textContent,
        enderEmit: {
          xLgr: emitElement.querySelector("enderEmit > xLgr").textContent,
          nro: emitElement.querySelector("enderEmit > nro").textContent,
          xCpl: emitElement.querySelector("enderEmit > xCpl").textContent,
          xBairro: emitElement.querySelector("enderEmit > xBairro").textContent,
          cMun: emitElement.querySelector("enderEmit > cMun").textContent,
          xMun: emitElement.querySelector("enderEmit > xMun").textContent,
          UF: emitElement.querySelector("enderEmit > UF").textContent,
          CEP: emitElement.querySelector("enderEmit > CEP").textContent,
          cPais: emitElement.querySelector("enderEmit > cPais").textContent,
          xPais: emitElement.querySelector("enderEmit > xPais").textContent,
        },
      };
      console.log("mostrando os valores do emitente ");
      console.log(emitObj);
      // ---------------------------------------
      let destElement = xmlDocument.querySelector("dest");
      let destObj = {
        CNPJ: destElement.querySelector("CNPJ").textContent,
        xNome: destElement.querySelector("xNome").textContent,
        xFant: destElement.querySelector("xFant").textContent,
        enderDest: {
          xLgr: destElement.querySelector("enderDest > xLgr").textContent,
          nro: destElement.querySelector("enderDest > nro").textContent,
          xCpl: destElement.querySelector("enderDest > xCpl").textContent,
          xBairro: destElement.querySelector("enderDest > xBairro").textContent,
          cMun: destElement.querySelector("enderDest > cMun").textContent,
          xMun: destElement.querySelector("enderDest > xMun").textContent,
          UF: destElement.querySelector("enderDest > UF").textContent,
          CEP: destElement.querySelector("enderDest > CEP").textContent,
          cPais: destElement.querySelector("enderDest > cPais").textContent,
          xPais: destElement.querySelector("enderDest > xPais").textContent,
        },
      };
      console.log("mostrando os valores do destinatario");
      console.log(destObj);
      console.log(destObj.xNome);

      // Processar a tag <ide>
      let ideElement = xmlDocument.querySelector("ide");
      let ideObj = {
        cNF: ideElement.querySelector("cNF").textContent,
        natOp: ideElement.querySelector("natOp").textContent,
        dhEmi: ideElement.querySelector("dhEmi").textContent,
        dSaida: ideElement.querySelector("dSaida").textContent,
      };

      console.log("dados do Ide");
      console.log(ideObj);

      if (destObj.xNome) {
        console.log("ola");
      }

      let objeto_p = this.pessoas.find((p) => p.pes_cpfcnpj == destObj.CNPJ);

      console.log("Mostrando Obejto P");
      console.log(objeto_p);
      if (!objeto_p) {
        this.makeToast("danger", "Destinatario Não Encontrado, Cadastre");
      }
      this.select_destinatario = objeto_p;
      this.select_nome_destinatario(objeto_p);

      // let produto2 = {
      //   id: 1,
      //   descricao: "",
      //   unidade: "",
      //   cfop: "",
      //   quantidade: 1,
      //   unitario: "",
      //   bruto: "",
      //   desconto: "",
      //   new_nfe_objeto: {},
      //   liquido: null,
      // };
      this.produtos = [];
      detArray.forEach((d, index) => {
        console.log(d.xProd + index);
        let obj_pdt = this.produtos_array.find(
          (p) => p.produto_cod_int == d.cProd
        );
        console.log(obj_pdt);

        if (!obj_pdt) {
          this.makeToast("danger", "Produto Não Encontrado Cadastrar");
        }
        let unidade_obj = this.unidades.find(
          (u) => u.DescricaoUn == obj_pdt.um
        );
        let cfop_obj = this.listCFOP1.find((c) => c.id == obj_pdt.produto_cfop);

        let obj_prod_item = {
          id: 1,
          produto_id: obj_pdt.id,
          descricao: obj_pdt,
          unidade: unidade_obj,
          cfop: cfop_obj,
          quantidade: 1,
          unitario: "",
          bruto: "",
          desconto: "",
          new_nfe_objeto: {},
          liquido: null,
        };

        this.produtos.push({ ...obj_prod_item });
      });
      // let NCM = xmlDocument.querySelector("NCM");
      // let GTIN = xmlDocument.querySelector("cEAN");

      // console.log(nome.textContent);
      // let nome_em_srting = nome.textContent.toString();
      // let NCM_em_srting = NCM.textContent.toString();
      // let GTIN_em_srting = GTIN.textContent.toString();
      // console.log("Nome" + nome_em_srting);
      // this.produto.produto_nome = nome_em_srting;
      // this.produto.produto_ncm = NCM_em_srting;
      // this.produto.produto_descricao = nome_em_srting;
      // this.produto.produto_cean = GTIN_em_srting;
    },
    valores_card() {
      let resp;

      if (
        this.Nfe.dPag != null &&
        this.Nfe.CNPJ != null &&
        this.select_band_card != null &&
        this.Nfe.cAut != null
      ) {
        resp = 1;
      } else {
        resp = 0;
      }
      return resp;
    },
    seleciona_bandeira(event) {
      console.log("estou em bandeiras");
      //console.log(event.target.value);
      // let id = event.target.value;
      this.Nfe.tBand = event.target.value;

      if (
        event.target.value == 1 ||
        event.target.value == 2 ||
        event.target.value == 3 ||
        event.target.value == 4 ||
        event.target.value == 5 ||
        event.target.value == 6 ||
        event.target.value == 7 ||
        event.target.value == 8 ||
        event.target.value == 9
      ) {
        this.Nfe.tBand = "0" + event.target.value;
      } else {
        this.Nfe.tBand = event.target.value;
      }

      console.log(this.Nfe.tBand);
      //Nfe.tBand
    },
    preenche_emit_padrao() {
      if (this.array_uploads.length > 0) {
        if (this.pessoas.length > 0) {
          let padrao = this.pessoas.find(
            (p) => p.pes_razao == this.array_uploads[0].razao
          );
          console.log("mostrando Pessoas do EMitente func");
          console.log(padrao);
          this.select_desc_emitente = padrao;
          this.select_nome_emitente(padrao);
        }
      }
    },
    preenche_num_nfe() {
      if (this.nfes.length > 0) {
        this.Nfe.nNF = this.nfes[this.nfes.length - 1].nNf + 1;
      }
    },
    hora_atual() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
      this.atual_hora = formattedDateTime;
      this.Nfe.dhSaiEnt = this.atual_hora;
      this.Nfe.dhEmi = this.atual_hora;
      this.Nfe.dPag = this.atual_hora;
    },
    converte_hora_dPag() {
      const dateTime = this.Nfe.dPag;
      const date = dateTime.split("T")[0];
      console.log(date); // Saída: 2024-06-04
      this.Nfe.dPag = date;
    },
    verifica_valores_gerais() {
      let respota;

      if (
        this.Nfe.nNF != null &&
        this.Nfe.nNF != undefined &&
        this.Nfe.nNF != "" &&
        this.Nfe.mod != null &&
        this.Nfe.mod != undefined &&
        this.Nfe.mod != "" &&
        this.Nfe.serie != null &&
        this.Nfe.serie != undefined &&
        this.Nfe.serie != "" &&
        this.Nfe.dhEmi != null &&
        this.Nfe.dhEmi != undefined &&
        this.Nfe.dhEmi != "" &&
        this.Nfe.dhSaiEnt != null &&
        this.Nfe.dhSaiEnt != undefined &&
        this.Nfe.dhSaiEnt != "" &&
        this.Nfe.finNFe != null &&
        this.Nfe.finNFe != undefined &&
        this.Nfe.finNFe != "" &&
        this.Nfe.mod != null &&
        this.Nfe.mod != undefined &&
        this.Nfe.mod != ""
      ) {
        // para valores preencdiso nessa variavel;

        if (this.Nfe.indPres === 0 || this.Nfe.indPres > 0) {
          if (
            this.Nfe.indPres != null &&
            this.Nfe.indPres != undefined &&
            this.Nfe.indPres != ""
          ) {
            respota = 1;
          }
        } else {
          respota = 0;
        }
        if (this.Nfe.indFinal === 0 || this.Nfe.indFinal > 0) {
          if (
            this.Nfe.indFinal != null &&
            this.Nfe.indFinal != undefined &&
            this.Nfe.indFinal != ""
          ) {
            respota = 1;
          }
        } else {
          respota = 0;
        }
      } else {
        respota = 0;
      }
      // para valores indefinidos

      return respota;
    },
    seleciona_DestindIEDest(event) {
      if (event.target.value == -1) {
        this.Nfe.DestindIEDest = null;
      } else {
        console.log(event.target.value);
        this.Nfe.DestindIEDest = event.target.value;
      }
    },
    seleciona_crt(event) {
      if (event.target.value == -1) {
        this.Nfe.EmitCRT = null;
      } else {
        this.Nfe.EmitCRT = event.target.value;
      }

      console.log("estou no crt");
      console.log(event);
    },
    async estados_ibge() {
      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id
        // e  só consigo fazer o get por id ou estado ou nome ;
        let response = await http.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
        );

        if (response.status === 200) {
          this.estados = response.data;
          // console.log("ibge");
          this.preenche_emit_padrao();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            // this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            // this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            // this.offLoader();
            break;
        }
      }
    },
    seleciona_cod_nome_municipio(objeto) {
      console.log("estou na funçõa para selecioran cod and nome municipio");
      //  id , nome
      console.log(objeto);
      this.Nfe.EmitxMun = objeto.nome
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toUpperCase();
      this.Nfe.EmitcMun = objeto.id;
      this.Nfe.cMunFG = objeto.id;
    },
    seleciona_cod_nome_municipio_dest(objeto) {
      console.log(
        "estou na funçõa para selecioran cod and nome municipio destinatario"
      );
      //  id , nome
      console.log(objeto);
      this.Nfe.DestxMun = objeto.nome;
      this.Nfe.DestcMun = objeto.id;
    },
    select_cidade_por_uf(objeto) {
      console.log("estou na uf metodo");
      this.Nfe.EmitUF = objeto.sigla;
      console.log(objeto);
      this.Nfe.cUF = objeto.id;
      console.log("estou no Seleciona UF EMitente ");
      console.log(this.Nfe.cUF);

      this.cidades_ibge(objeto);
    },
    select_cidade_por_uf_destinatario(objeto) {
      console.log("estou na uf metodo destinatario");
      this.Uf_dest_new = objeto.sigla;
      this.Nfe.DestUF = objeto.sigla;
      console.log(objeto);

      this.cidades_ibge(objeto);
    },
    async cidades_ibge(objeto) {
      let uf = objeto.sigla;
      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id
        // e  só consigo fazer o get por id ou estado ou nome ;
        let response = await http.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados/" +
            uf +
            "/municipios"
        );

        if (response.status === 200) {
          this.cidades_ibge1 = response.data;
          //console.log("ibge");
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            //this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            ///this.offLoader();
            break;
          default:
            this.makeToast("danger", error.message);
            //this.offLoader();
            break;
        }
      }
    },
    select_nome_emitente(event) {
      console.log("esttou no selcionar Emitennte , vendo padrao");
      console.log(event);
      this.Nfe.EmitxNome = event.pes_razao;
      this.Nfe.EmitxFant = event.pes_fantasia;
      this.Nfe.EmitIE = event.pes_ie; // inscrição estadual ,
      this.Nfe.EmitIEST = ""; // inscrição estadula st
      this.Nfe.EmitIM = event.pes_im ? event.pes_im : ""; // se tiver  , incrição municipal
      this.Nfe.EmitCNAE = "";
      this.Nfe.EmitCRT = "1";
      //  28267948000127
      this.Nfe.EmitCNPJ = event.pes_cpfcnpj;

      this.Nfe.EmitxLgr = event.pes_endereco; // logradouro,
      this.Nfe.Emitnro = event.pes_numero; // numero
      this.Nfe.EmitxCpl = event.pes_complemento; // complemento
      this.Nfe.EmitxBairro = event.pes_bairro ? event.pes_bairro : ""; //bairro

      this.Nfe.EmitcMun = event.cidade_id; // codifo do municipio,
      this.Nfe.EmitxMun = event.pes_cidade; // nome do municipio,

      this.Nfe.EmitUF = event.pes_uf;
      this.Nfe.EmitCEP = event.pes_cep;
      this.Nfe.EmitcPais = event.pais_id; // codigo pais
      this.Nfe.EmitxPais = "BRASIL"; // nome pais
      this.Nfe.Emitfone =
        event.pes_celular != null && event.pes_celular != ""
          ? event.pes_celular
          : event.pes_telefone;

      // ----------------------------

      let estado_id = event.estado_id;
      let cidade_id = event.cidade_id;

      console.log("mosstrnado cidadade e estados Emitente");
      console.log(estado_id);
      console.log(cidade_id);

      let objeto_estado = this.estados.find((e) => e.id == estado_id);

      this.select_uf = objeto_estado;
      console.log("mostrando Objeto estado , padrao");
      console.log(objeto_estado);
      this.cidades_ibge(objeto_estado);

      this.Nfe.cMunFG = cidade_id;
      this.Nfe.cUF = estado_id;

      setTimeout(() => {
        let cidade_objeto = this.cidades_ibge1.find((c) => c.id == cidade_id);
        this.select_municipio = cidade_objeto;

        this.seleciona_cod_nome_municipio(cidade_objeto);
        this.select_cidade_por_uf(objeto_estado);
      }, 1000);
    },

    select_nome_destinatario(event) {
      console.log("estou no destinatario");
      console.log(event);
      this.Nfe.DestxNome = event.pes_razao
        ? event.pes_razao
        : event.pes_apelido;
      //console.log(this.DestxNome)
      // this.Nfe.DestindIEDest = "1";
      this.Nfe.DestIE = event.pes_ie;
      this.Nfe.DestISUF = "";
      this.Nfe.DestIM = event.pes_im;
      this.Nfe.Destemail = "";
      this.Nfe.DestCNPJ = event.pes_cpfcnpj;

      if (this.Nfe.DestCNPJ.length == 11) {
        this.Nfe.DestCPF = this.Nfe.DestCNPJ;
      } else {
        this.Nfe.DestCPF = null;
      }

      //   Node de endereço do destinatário
      this.Nfe.DestxLgr = event.pes_endereco;
      this.Nfe.Destnro = event.pes_numero;
      this.Nfe.DestxCpl = event.pes_complemento;
      this.Nfe.DestxBairro = event.pes_bairro;
      this.Nfe.DestcMun = event.cidade_id;
      this.Nfe.DestxMun = event.pes_cidade;
      this.Nfe.DestUF = event.pes_uf;
      this.Nfe.DestCEP = event.pes_cep;
      this.Nfe.DestcPais = event.pais_id;
      this.Nfe.DestxPais = "Brasil";
      this.Nfe.Destfone = event.pes_telefone;

      if (
        this.Nfe.DestIE != null &&
        this.Nfe.DestIE != "" &&
        this.Nfe.DestIE != undefined
      ) {
        this.Nfe.DestindIEDest = 1;
        this.select_DestindIEDest = 1;
      } else {
        this.Nfe.DestindIEDest = 9;
        this.select_DestindIEDest = 9;
      }

      let estado_id = event.estado_id;
      let cidade_id = event.cidade_id;

      console.log(estado_id);

      let objeto_estado = this.estados.find((e) => e.id == estado_id);

      this.select_uf_dest = objeto_estado;

      this.cidades_ibge(objeto_estado);

      setTimeout(() => {
        let cidade_objeto = this.cidades_ibge1.find((c) => c.id == cidade_id);
        this.select_municipio_dest = cidade_objeto;

        this.seleciona_cod_nome_municipio_dest(cidade_objeto);
        this.select_cidade_por_uf_destinatario(objeto_estado);
      }, 1000);
    },
    handleScroll() {
      // Armazene a posição atual da barra de rolagem
      this.scrollPosition = window.scrollY || window.pageYOffset;
    },
    seleciona_destinatario(event) {
      console.log(event);
    },
    seleciona_frete(event) {
      console.log(event);
      this.Nfe.modFrete = event.id;
      //this.Nfe.
    },
    valor_inicial() {
      if (
        this.produtos.length == 1 &&
        this.produtos[0].unitario != "" &&
        this.produtos[0].unitario != undefined &&
        this.produtos[0].unitario != null
      ) {
        this.Nfe.vPag = this.produtos[0].unitario * this.produtos[0].quantidade;
      }
    },
    soma_itens() {
      console.log("estou no soma de valores brutos do vertor de produtos");

      // this.produtos.forEach((p) => {
      //   let valor_pago = this.Nfe.vPag;
      //   soma = this.Nfe.vPag;
      //   if (typeof valor_pago == "string") {
      //     valor_pago = this.new_convert_srting_float(valor_pago);
      //   }
      //   soma =
      //     this.new_convert_srting_float(soma) +
      //     this.new_convert_srting_float(p.unitario) * p.quantidade;
      // });
      let soma = 0;
      this.produtos.forEach((p) => {
        let bruto = p.bruto;
        if (typeof bruto == "string") {
          bruto = this.new_convert_srting_float(bruto);
          soma = soma + bruto;
        }
      });
      this.Nfe.vPag = null;
      this.Nfe.vPag = this.formatterCurrBR(soma);
    },

    sub_itens(objeto, index) {
      let valor_pago = this.Nfe.vPag;
      let unitario = this.produtos[index].unitario;
      let quantidade = this.produtos[index].quantidade;

      //this.Nfe.vPag = this.Nfe.vPag - objeto.unitario;

      valor_pago =
        this.new_convert_srting_float(valor_pago) -
        this.new_convert_srting_float(unitario) * quantidade;
      //console.log(valor_pago);
      this.Nfe.vPag = valor_pago;
    },
    tipo_pag(objeto) {
      // console.log(objeto);
      this.Nfe.tPag = objeto.tpag;
      if (objeto.tpag == "15") {
        this.duplicatas = true;
      } else {
        this.duplicatas = false;
      }
      if (objeto.tpag == "01") {
        console.log(objeto.tpag);
        this.select_form_pagamento = this.form_pagamentos[0];
        this.Nfe.indPag = this.form_pagamentos[0].id;
      }

      if (objeto.tpag == "03") {
        this.card = true;
      } else {
        this.card = false;
      }
    },
    forma_pag(event) {
      // console.log(event);
      this.Nfe.indPag = event.id;
    },
    soma_de_valores_impostos_ibpt(index) {
      console.log("estou no soma_de_valores_impostos_ibpt");
      console.log(this.produtos[index].new_nfe_objeto);
    },
    async doPut(produto) {
      
      // this.onLoader();
      // this.changeValues(produto, ",", ".");

      console.log("dentro do update prod NF ITEM ");
      console.log(produto);
      console.log(produto.new_nfe_objeto.select_cson.id)
      console.log(produto.new_nfe_objeto.select_cst_confins)
      
       let objeto_produto = produto.descricao
      objeto_produto.produto_cst = produto.new_nfe_objeto.select_cson.id
      objeto_produto.produto_cstcofins = produto.new_nfe_objeto.select_cst_confins.id
      objeto_produto.produto_cstipi = produto.new_nfe_objeto.selec_cst_ipi.id
      objeto_produto.produto_cstpis = produto.new_nfe_objeto.select_cst.id

      try {

        let response = await http.put('/produto/' + objeto_produto.id + '?empresa_id=' + this.currentEmpresa.id, objeto_produto);
        //console.log(response);

        if (response.status === 200) {
          console.log("entrou aqui");
          // this.getData();
          //this.back();
          setTimeout(() => {
            this.offLoader();
          }, 800);
          this.makeToast('success', 'Imposto do Item Atualiazado');
        }
      } catch (error) {
        this.makeToast('danger', error.response);
      }
    },
    impostos_salvos(valor_cst_cson, new_nfe_objeto, index) {
      this.soma_itens();

      this.imposto_salvo = true;
      this.posicao_item = index;
      this.produtos[index].new_nfe_objeto = { ...new_nfe_objeto };
      // this.$set[(this.produtos, 'new_nfe_objeto', objeto)];
      this.doPut(this.produtos[index]);
      this.Nfe.orig = this.produtos[index].new_nfe_objeto.orig;
      this.Nfe.select_origem =
        this.produtos[index].new_nfe_objeto.select_origem;

      this.imposto_salvos.push(this.posicao_item);
      this.soma_de_valores_impostos_ibpt(index);

      if (
        this.produtos[index].new_nfe_objeto.select_cson != null &&
        this.produtos[index].new_nfe_objeto.select_cson != undefined &&
        this.produtos[index].new_nfe_objeto.select_cson != ""
      ) {
        if (this.produtos[index].new_nfe_objeto.select_cson.id == 101) {
          this.veri_liquido = true;
          let objeto = {
            label: "Liquido",
            key: "liquido",
            tdClass: "text-center",
            thClass: "text-center",
          };

          let index1 = this.fields.findIndex(
            (field) => field.key === "desconto"
          );

          // Adicionar o novo objeto após o campo "Desconto"
          if (index1 !== -1) {
            this.fields.splice(index1 + 1, 0, objeto);
            this.produtos[index].liquido =
              this.produtos[index].new_nfe_objeto.vCredICMSSN;
            console.log("mostrando os valores liquidos");
            console.log(this.produtos[index].liquido);
          } else {
            // nunca vai entrar aqui
            // Se o campo "Desconto" não for encontrado, adicionar o objeto ao final
            this.fields.push(objeto);
          }
        } else {
          let index1 = this.fields.findIndex(
            (field) => field.key === "liquido"
          );

          // remove o liquido apartir do index encontrado
          if (index1 !== -1) {
            // quando não acha  o elementoo retorna -1
            this.fields.splice(index1, 1);
          }
          this.veri_liquido = false;
        }
      }
      // if (this.imposto_salvo == true) {
      //   console.log(this.imposto_salvo);
      // }
      // index será usado para inserir os impostos no index correto do vetor
      console.log("estou nos impostos salvos");
      console.log(index);
      // console.log(valor_cst_cson);
      console.log("mostrando vetro que foi salvo");
      //  console.log(this.produtos);

      if (
        valor_cst_cson != null &&
        valor_cst_cson != "" &&
        valor_cst_cson != undefined
      ) {
        this.Nfe.CSOSN = valor_cst_cson;
      }
      if (
        new_nfe_objeto.CST != null &&
        new_nfe_objeto.CST != "" &&
        new_nfe_objeto.CST != undefined
      ) {
        this.produtos[index].CST = new_nfe_objeto.CST;
        // esse else foi feito para quando tiver mais te um item sem o cst selecionado;
        //this.Nfe.CSOSN = new_nfe_objeto.CST;
      }
      if (
        new_nfe_objeto.confinsCST != null &&
        new_nfe_objeto.confinsCST != "" &&
        new_nfe_objeto.confinsCST != undefined
      ) {
        this.produtos[index].confinsCST = new_nfe_objeto.confinsCST;
      }
      if (
        new_nfe_objeto.ipiCST != null &&
        new_nfe_objeto.ipiCST != "" &&
        new_nfe_objeto.ipiCST != undefined
      ) {
        this.produtos[index].ipiCST = new_nfe_objeto.ipiCST;
      }

      if (valor_cst_cson == 101) {
        // esse dois só serão usdados caso só tenha um produtos , talvez nemm preccise;
        this.Nfe.pCredSN = new_nfe_objeto.pCredSN;
        this.Nfe.vCredICMSSN = new_nfe_objeto.vCredICMSSN;
        // valores dos impostos inseridos nos index corretos , esse vetor vai para o backend
        // para inseir multiplos produtos no xml;
        this.produtos[index].pCredSN = new_nfe_objeto.pCredSN;
        this.produtos[index].vCredICMSSN = new_nfe_objeto.pCredSN;
        // console.log("estou no codigo 101 e os prods são:");
        // console.log(this.produtos);
        this.produtos[index].pCredSN = new_nfe_objeto.pCredSN;
        this.produtos[index].vCredICMSSN = new_nfe_objeto.pCredSN;

        //this.Nfe.vet = this.produtos;
        // console.log(this.Nfe.vet);
      } else {
        //console.log("esotu no else");
        // this.produtos[index].vv = 1;
        // this.Nfe.vet = [...this.produtos];
      }
      if (valor_cst_cson == 500) {
        this.Nfe.orig = new_nfe_objeto.orig;

        this.Nfe.vBCSTRet = new_nfe_objeto.vBCSTRet; //  esse campo  são obrigatorios para simples nacional
        this.Nfe.vICMSSTRet = new_nfe_objeto.vICMSSTRet; // esse campo são obrigatorois para simples nacional
        this.Nfe.vBCFCPST = new_nfe_objeto.vBCFCPST; //
        this.Nfe.pFCPST = new_nfe_objeto.pFCPST;
        this.Nfe.vFCPST = new_nfe_objeto.vFCPST;
        this.Nfe.pST = new_nfe_objeto.pST;
        this.Nfe.vICMSSubstituto = new_nfe_objeto.vICMSSubstituto;
        this.Nfe.pRedBCEfet = new_nfe_objeto.pRedBCEfet;
        this.Nfe.vBCEfet = new_nfe_objeto.vBCEfet;
        this.Nfe.pICMSEfet = new_nfe_objeto.pICMSEfet;
        this.Nfe.vICMSEfet = new_nfe_objeto.vICMSEfet;

        this.produtos[index].produto_picms = new_nfe_objeto.produto_picms;
        this.produtos[index].vBCSTRet = new_nfe_objeto.vBCSTRet;
        this.produtos[index].vICMSSTRet = new_nfe_objeto.vICMSSTRet;

        //console.log("estou no 500 picms");
        // console.log(this.produtos[index]);
      } else if (valor_cst_cson == 400) {
        console.log("estou no não tributado");
      } else if (valor_cst_cson == 102) {
        console.log("estou no 102");
      }
      this.Nfe.vet = [...this.produtos];
      console.log("MOSTRANDO OS VALORES DO VETOR QUE ESTA DENTRO DE PRODUTO");
      console.log(this.Nfe.vet);
      this.makeToast("info", `impostos salvos - CSOSN : ${this.Nfe.CSOSN}`);
    },
    back() {
      this.impostos_modal = false;
    },
    voltar() {
      this.$emit("back");
    },
    impostos(objeto, index) {
      if (
        !(
          this.produtos[index].produto_id != -1 &&
          this.produtos[index].produto_id != undefined
        )
      ) {
        this.makeToast("danger", "Selecione um Item");
        //    setTimeout(() => {
        //   const alturaPagina = document.body.scrollHeight;
        //   window.scrollTo({
        //     top: alturaPagina,
        //     behavior: "smooth",
        //   });
        // }, 100);
      } else {
        if (
          !(
            this.produtos[index].unitario != null &&
            this.produtos[index].unitario != undefined && this.produtos[index].unitario != ""
          )
        ) {
          this.makeToast("danger", "Preencha o Valor Unitario");
        } else {
          console.log(objeto);
          console.log(index);
          this.posicao_no_vetor = index;
          console.log("esotu em impostos");
          this.impostos_modal = true;
        }
      }
    },
    calcular_valorBruto(objeto, index) {
      let valorUnitario = this.produtos[index].unitario;
      let quantidade = this.produtos[index].quantidade;
      let resultado;

      if (typeof valorUnitario == "string") {
        if (valorUnitario.includes(".") && valorUnitario.includes(",")) {
          valorUnitario = parseFloat(
            valorUnitario.replace(".", "").replace(",", ".")
          );
        } else if (
          !valorUnitario.includes(".") &&
          valorUnitario.includes(",")
        ) {
          valorUnitario = parseFloat(valorUnitario.replace(",", "."));
        }
      } else {
        valorUnitario = this.produtos[index].unitario;
      }
      quantidade = parseInt(quantidade);
      resultado = valorUnitario * quantidade;
      this.produtos[index].bruto = this.formatterCurrBR(resultado);
      if (
        this.produtos[index].new_nfe_objeto != null &&
        this.produtos[index].new_nfe_objeto != undefined &&
        this.produtos[index].new_nfe_objeto != ""
      ) {
        if (
          typeof this.produtos[index].new_nfe_objeto.vCredICMSSN == "number"
        ) {
          let valor = this.produtos[index].new_nfe_objeto.vCredICMSSN;
          let restt = quantidade * valor;
          this.produtos[index].liquido = restt;
          console.log("logica para impostos de 101");
          console.log(this.produtos[index].liquido);
        }
      }
      // if (this.produtos.length == 1) {
      //   this.Nfe.vPag = this.formatterCurrBR(resultado);
      // }
      if (
        this.produtos[index].new_nfe_objeto.cst_reg_normal != null &&
        this.produtos[index].new_nfe_objeto.cst_reg_normal != undefined &&
        this.produtos[index].new_nfe_objeto.cst_reg_normal != ""
      ) {
        if (this.produtos[index].new_nfe_objeto.cst_reg_normal.id_es === "00") {
          this.recalculo_cst00(index);
        } else if (
          this.produtos[index].new_nfe_objeto.cst_reg_normal.id_es === "20"
        ) {
          this.recaulculo_cst20(index);
        } else if (
          this.produtos[index].new_nfe_objeto.cst_reg_normal.id_es === "51"
        ) {
          this.recaulculo_cst51(index);
        }
      }
    },
    formatInput_valorUnitario(objeto, index) {
      //console.log(index)
      // Remova todos os caracteres não numéricos do valor de entrada
      // produtos

      this.vlr_uni = this.produtos[index].unitario;

      let numericValue = this.produtos[index].unitario.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.produtos[index].unitario = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      this.calcular_valorBruto(objeto, index);
      this.soma_itens();

      if (
        this.produtos[index].new_nfe_objeto.cst_reg_normal != null &&
        this.produtos[index].new_nfe_objeto.cst_reg_normal != undefined &&
        this.produtos[index].new_nfe_objeto.cst_reg_normal != ""
      ) {
        if (this.produtos[index].new_nfe_objeto.cst_reg_normal.id_es === "00") {
          this.recalculo_cst00(index);
        } else if (
          this.produtos[index].new_nfe_objeto.cst_reg_normal.id_es === "20"
        ) {
          this.recaulculo_cst20(index);
        } else if (
          this.produtos[index].new_nfe_objeto.cst_reg_normal.id_es === "51"
        ) {
          this.recaulculo_cst51(index);
        }
      }
      //this.recalculo_cst00(index);
    },
    formatInput_Desconto(objeto, index) {
      //console.log(index)
      // Remova todos os caracteres não numéricos do valor de entrada
      // produtos
      let numericValue = this.produtos[index].desconto.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.produtos[index].desconto = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_valorBruto(objeto, index) {
      //console.log(index)
      // Remova todos os caracteres não numéricos do valor de entrada
      // produtos
      let numericValue = this.produtos[index].bruto.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.produtos[index].bruto = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    seleciona_finalidade(event) {
      this.Nfe.finNFe = event.id;
      if (event.id == 4) {
        this.devolucao_bol = true;
        this.Nfe.natOp = "DEVOLUCAO";
      } else if (event.id == 1) {
        this.Nfe.natOp = "Venda";
        this.devolucao_bol = false;
      } else if (event.id == 2) {
        this.devolucao_bol = true;
        this.Nfe.natOp = "Complementar";
      } else if (event.id == 3) {
        this.devolucao_bol = true;
        this.Nfe.natOp = "Ajuste";
      } else {
        this.devolucao_bol = false;
      }
    },
    recebe_vlr_devolucao(objeto) {
      console.log("estou na devolução chave em simples");
      this.Nfe.chave = objeto.chave;
      console.log(this.Nfe.chave);
    },
    remove_finalidade(event) {
      console.log(event);
      this.Nfe.finNFe = null;
      this.devolucao_bol = false;
    },
    seleciona_tp_movimento(event) {
      this.Nfe.tpNF = event.id;
    },
    seleciona_atendimento(event) {
      this.Nfe.indPres = event.id;
    },
    remove_atendimento(event) {
      console.log("Estou em remove atendiemtos");
      console.log(event);
      this.Nfe.indPres = null;
      console.log(this.Nfe.indPres);
    },
    seleciona_consumidor(event) {
      if (event.id == 1) {
        //sim
        this.Nfe.tpNF = 1;
      } else {
        this.Nfe.tpNF = 1;
      }

      this.Nfe.indFinal = event.id;
    },
    remove_consumidor(event) {
      console.log(event);
      this.Nfe.indFinal = null;
      this.Nfe.tpNF = null;
    },
    seleciona_produto(event, index) {
      this.item1 = event; // recebe o objeto produto para enviar para o componente Impostos Simples
      console.log(index);
      console.log(event);

      let produtoCFop = event.produto_cfop;
      let produto_unidade_id = event.unidade_id;
      let cfop_selecionado = this.listCFOP1.find((c) => c.id == produtoCFop);
      let unidade_selecionada = this.unidades.find(
        (u) => u.id == produto_unidade_id
      );
      console.log("cfop selecionado foi");
      console.log(cfop_selecionado);
      this.produtos[index].cfop = cfop_selecionado;
      this.produtos[index].unidade = unidade_selecionada;
    },
    seleciona_produto_xml(objeto) {
      console.log("Estou no Produto XML");
      console.log(objeto);
    },
    seleciona_produto2(event, index) {
      console.log("ESTOU NO SELECIONA PRODUTO 2");
      console.log(event.target.value);

      // if(this.produtos.length > 1){
      //    let obj_va = {
      //     variaveis_produtos : true
      //    }
      //    this.vet_variaveis_produtos.push({...obj_va})
      //  }
      console.log(this.vet_variaveis_produtos);

      if (event.target.value == -1) {
        // this.variaveis_produtos = true

        this.vet_variaveis_produtos[index].variaveis_produtos = true;
      } else {
        this.vet_variaveis_produtos[index].variaveis_produtos = false;
        // this.variaveis_produtos = false
      }
      let seleciona_prod = this.produtos_array.find(
        (p) => p.id == event.target.value
      );
      console.log("Prodduto Selecionaddo");
      console.log(seleciona_prod);
      this.item1 = seleciona_prod; // recebe o objeto produto para enviar para o componente Impostos Simples
      console.log(index);
      console.log(event);
      this.produtos[index].descricao = seleciona_prod;

      let produtoCFop = seleciona_prod.produto_cfop;
      let produto_unidade_id = seleciona_prod.unidade_id;
      let cfop_selecionado = this.listCFOP1.find((c) => c.id == produtoCFop);
      let unidade_selecionada = this.unidades.find(
        (u) => u.id == produto_unidade_id
      );
      console.log("cfop selecionado foi");
      console.log(cfop_selecionado);
      this.produtos[index].cfop = cfop_selecionado;
      this.produtos[index].unidade = unidade_selecionada;
    },
    add_produto(objeto, index) {
      if (
        this.produtos[index].produto_id === -1 ||
        this.produtos[index].produto_id == undefined
      ) {
        this.makeToast("danger", "Selecione um Item");
        setTimeout(() => {
          const alturaPagina = document.body.scrollHeight;
          window.scrollTo({
            top: alturaPagina,
            behavior: "smooth",
          });
        }, 100);
      } else {
        console.log(objeto, index);
        let produto2 = {
          id: 1,
          descricao: "",
          unidade: "",
          cfop: "",
          quantidade: 1,
          unitario: "",
          bruto: "",
          desconto: "",
          new_nfe_objeto: {},
          liquido: null,
        };
        // let produto = { ...this.produtos[index] }; // cria um clone de cada objeto, para não pegar o mesmo
        // endereço de memoria
        //this.soma_itens(produto);
        //this.produtos.push({ ...this.produtos[index] }); // caso de erro volta para esse;
        this.produtos.push(produto2);
        //const scrollPositionBeforeAdd = window.scrollY || window.pageYOffset;

        // Adiciona o produto
        if (this.produtos.length > 1) {
          // adicnao um elemento do vetor para verificar se o item ja foi selecionado ;
          let obj_va = {
            variaveis_produtos: true,
          };
          this.vet_variaveis_produtos.push({ ...obj_va });
        }
        console.log(this.vet_variaveis_produtos);
        // Restaura a posição da barra de rolagem após adicionar o produto

        // window.scrollTo(0, document.body.scrollHeight);
        setTimeout(() => {
          const alturaPagina = document.body.scrollHeight;
          window.scrollTo({
            top: alturaPagina,
            behavior: "smooth",
          });
        }, 100); // Ajuste o tempo conforme necessário
      }
    },
    dlt_produto(objeto, index) {
      if (this.produtos.length > 1) {
        console.log("mostrando deletar Produto");
        console.log(objeto, index);
        // this.sub_itens(objeto);
        this.sub_itens(objeto, index);
        this.produtos.splice(index, 1);
        // this.produtos.pop();
      }
      setTimeout(() => {
        const alturaPagina = document.body.scrollHeight;
        window.scrollTo({
          top: alturaPagina,
          behavior: "smooth",
        });
      }, 100); // Ajuste o tempo conforme necessário
    },
    select_tp_mov_preench(event) {
      console.log(event);
    },
    customLabel_identificador_vendedor({ id, pes_apelido }) {
      return `${id} – ${pes_apelido}`;
    },
    intermed_veri(event) {
      // console.log("entour no intermed")
      //console.log(this.tp_itermediador);
      console.log(event);

      if (event == 1) {
        this.veri_intermed = false;
      } else {
        this.veri_intermed = true;
      }
      // if (this.tp_itermediador == false) {
      //   this.veri_intermed = true;
      //   console.log(this.veri_intermed)
      // } else if (this.dados_pedido.tp_itermediador == 1) {
      //   this.veri_intermed = false;
      // } else if (this.dados_pedido.tp_itermediador == 2) {
      //   this.veri_intermed = false;
      // } else {
      //   this.veri_intermed = true;
      // }
    },
    customLabelHistorico({ id, descricao }) {
      return `${id} – ${descricao}`;
    },
    async pesquisarNfe() {
      try {
        let response = await http.post("/nfe/pesquisa");
        if (response.status == 200) {
          // console.log(response.data);
          let msg = response.data;
          this.makeToast("success", msg);
        } else {
          console.log(response.data);
        }
      } catch (e) {
        console.log(e.data);
        this.makeToast("danger", e.data);
      }
    },
    converter_bruto_unitario() {
      let vetor = this.produtos;
      vetor.forEach((objeto, index) => {
        let unitario = objeto.unitario;
        let bruto = objeto.bruto;

        if (typeof unitario == "string") {
          if (unitario.includes(".") && unitario.includes(",")) {
            unitario = parseFloat(unitario.replace(".", "").replace(",", "."));
            this.produtos[index].unitario = unitario;
          } else if (!unitario.includes(".") && unitario.includes(",")) {
            unitario = parseFloat(unitario.replace(",", "."));
            this.produtos[index].unitario = unitario;
          }
        } else {
          this.produtos[index].unitario = unitario;
        }

        if (typeof bruto == "string") {
          if (bruto.includes(".") && bruto.includes(",")) {
            bruto = parseFloat(bruto.replace(".", "").replace(",", "."));
            this.produtos[index].bruto = bruto;
          } else if (!bruto.includes(".") && bruto.includes(",")) {
            bruto = parseFloat(bruto.replace(",", "."));
            this.produtos[index].bruto = bruto;
          }
        } else {
          this.produtos[index].bruto = bruto;
        }
      });
    },

    verifica_destinatario() {
      let resultado;
      if (
        this.Nfe.DestxNome != null &&
        this.Nfe.DestxNome != undefined &&
        this.Nfe.DestxNome != ""
      ) {
        resultado = true;
      } else {
        resultado = false;
      }

      return resultado;
    },
    verifica_campos_dest_emit() {
      if (
        this.Nfe.DestxNome != "" &&
        this.Nfe.DestxNome != undefined &&
        this.Nfe.DestxNome != null &&
        this.Nfe.DestIE != "" &&
        this.Nfe.DestIE != undefined &&
        this.Nfe.DestIE != null &&
        this.Nfe.DestCNPJ != "" &&
        this.Nfe.DestCNPJ != undefined &&
        this.Nfe.DestCNPJ != null &&
        this.Nfe.DestxLgr != "" &&
        this.Nfe.DestxLgr != undefined &&
        this.Nfe.DestxLgr != null &&
        this.Nfe.Destnro != "" &&
        this.Nfe.Destnro != undefined &&
        this.Nfe.Destnro != null &&
        this.Nfe.DestxBairro != "" &&
        this.Nfe.DestxBairro != undefined &&
        this.Nfe.DestxBairro != null &&
        this.Nfe.DestcMun != "" &&
        this.Nfe.DestcMun != undefined &&
        this.Nfe.DestcMun != null &&
        this.Nfe.DestxMun != "" &&
        this.Nfe.DestxMun != undefined &&
        this.Nfe.DestxMun != null &&
        this.Nfe.DestUF != "" &&
        this.Nfe.DestUF != undefined &&
        this.Nfe.DestUF != null &&
        this.Nfe.DestCEP != "" &&
        this.Nfe.DestCEP != undefined &&
        this.Nfe.DestCEP != null &&
        this.Nfe.DestcPais != "" &&
        this.Nfe.DestcPais != undefined &&
        this.Nfe.DestcPais != null &&
        this.Nfe.DestxPais != "" &&
        this.Nfe.DestxPais != undefined &&
        this.Nfe.DestxPais != null
      ) {
        console.log("Ola");
      } else {
        this.makeToast("danger", "Dados do Destinatario Não preenchidos");
      }

      if (
        this.Nfe.EmitxNome != "" &&
        this.Nfe.EmitxNome != undefined &&
        this.Nfe.EmitxNome != null &&
        this.Nfe.EmitxFant != "" &&
        this.Nfe.EmitxFant != undefined &&
        this.Nfe.EmitxFant != null &&
        this.Nfe.EmitIE != "" &&
        this.Nfe.EmitIE != undefined &&
        this.Nfe.EmitIE != null &&
        this.Nfe.EmitCNPJ != "" &&
        this.Nfe.EmitCNPJ != undefined &&
        this.Nfe.EmitCNPJ != null &&
        this.Nfe.EmitxLgr != "" &&
        this.Nfe.EmitxLgr != undefined &&
        this.Nfe.EmitxLgr != null &&
        this.Nfe.Emitnro != "" &&
        this.Nfe.Emitnro != undefined &&
        this.Nfe.Emitnro != null &&
        this.Nfe.EmitxBairro != "" &&
        this.Nfe.EmitxBairro != undefined &&
        this.Nfe.EmitxBairro != null &&
        this.Nfe.EmitcMun != "" &&
        this.Nfe.EmitcMun != undefined &&
        this.Nfe.EmitcMun != null &&
        this.Nfe.EmitxMun != "" &&
        this.Nfe.EmitxMun != undefined &&
        this.Nfe.EmitxMun != null &&
        this.Nfe.EmitUF != "" &&
        this.Nfe.EmitUF != undefined &&
        this.Nfe.EmitUF != null &&
        this.Nfe.EmitCEP != "" &&
        this.Nfe.EmitCEP != undefined &&
        this.Nfe.EmitCEP != null &&
        this.Nfe.EmitcPais != "" &&
        this.Nfe.EmitcPais != undefined &&
        this.Nfe.EmitcPais != null &&
        this.Nfe.EmitxPais != "" &&
        this.Nfe.EmitxPais != undefined &&
        this.Nfe.EmitxPais != null &&
        this.Nfe.EmitxPais != "" &&
        this.Nfe.EmitxPais != undefined &&
        this.Nfe.EmitxPais != null &&
        this.Nfe.Emitfone != "" &&
        this.Nfe.Emitfone != undefined &&
        this.Nfe.Emitfone != null
      ) {
        console.log("Ola");
      } else {
        this.makeToast("danger", "Dados do Emitente não preenchidos");
      }

      //EmitxNome: "CLAUDIA MARIA DOS ANJOS",
      //   EmitxFant: "MAGAZINE ATRAENTE",
      // EmitIE: "073027200",
      // EmitIEST: "",
      // EmitIM: "",
      //  EmitCNAE: "",
      //   EmitCRT: "1",
      // EmitCNPJ: "28267948000127",
      // // Node com o endereço do emitente
      // EmitxLgr: "CENTRO",
      // Emitnro: "55",
      // EmitxCpl: "",
      // EmitxBairro: "AV PRINC TOME",
      // EmitcMun: "2611101",
      // EmitxMun: "PETROLINA",
      // EmitUF: "PE",
      // EmitCEP: "56300001",
      //EmitcPais: "1058",
      // EmitxPais: "Brasil",
      // Emitfone: "8781766784",
    },
    verifica_uf_dest_emitente() {
      // idDest

      if (this.Nfe.EmitUF == this.Nfe.DestUF) {
        this.Nfe.idDest = 1;
      } else {
        this.Nfe.idDest = 2;
      }
    },
    verificar_cpf_cnpj() {
      if (this.Nfe.DestCNPJ.length == 11) {
        this.Nfe.DestCPF = this.Nfe.DestCNPJ;
        //this.Nfe.DestCNPJ = ""
      } else {
        this.Nfe.DestCPF = null;
      }

      //  EmitCNPJ: "28267948000127",
      //     EmitCPF: "",
      if (this.Nfe.EmitCNPJ.length == 11) {
        this.Nfe.EmitCPF = this.Nfe.EmitCNPJ;
        //this.Nfe.EmitCNPJ = ''
      } else {
        this.Nfe.EmitCPF = null;
      }
    },
    coverte_valor_tot_nfe() {
      let valor = this.Nfe.vPag;
      this.Nfe.vPag = this.new_convert_srting_float(valor);
    },
    getData() {
      this.$emit("back2");
    },
    async emitirNfe() {
      //console.log(this.Nfe)
      console.log("mostrando valores do cartão");
      console.log(this.Nfe.CNPJ);
      console.log(this.Nfe.tBand);
      console.log(this.Nfe.cAut);
      console.log(this.Nfe.tpIntegra);
      this.Nfe.empresa_id = this.atual_empresa.id;
      console.log("mostrando CRT");
      console.log(this.Nfe.EmitCRT);
      this.verificar_cpf_cnpj();

      if (this.valores_card() == 0 && this.Nfe.tPag == "03") {
        this.makeToast(
          "danger",
          "Preecha os campos em vermelho do referente ao carão  de credito"
        );
      } else {
        if (this.verifica_valores_gerais() == 0) {
          this.makeToast("danger", "Preecha os campos em vermelho");
        } else {
          this.verifica_campos_dest_emit();
          this.verifica_uf_dest_emitente();
          console.log("mostrando iddest na função de emitrNfe");
          console.log(this.Nfe.idDest);
          // select_form_pagamento
          if (
            this.item1 != null &&
            this.item1 != "" &&
            this.item1 != undefined &&
            this.vlr_uni != null &&
            this.vlr_uni != "" &&
            this.vlr_uni != undefined
          ) {
            if (
              this.Nfe.DestxNome != null &&
              this.Nfe.DestxNome != undefined &&
              this.Nfe.DestxNome != ""
            ) {
              // this.makeToast("success", "Preencha os dados de destinatario");

              let cst_itens = this.Nfe.CSOSN;

              if (
                cst_itens != null &&
                cst_itens != undefined &&
                cst_itens != "" &&
                this.Nfe.select_origem != null &&
                this.Nfe.select_origem != undefined &&
                this.Nfe.select_origem != ""
              ) {
                this.converter_bruto_unitario();
                this.Nfe.vet = [...this.produtos];
                console.log(this.Nfe.vet);

                if (
                  this.select_tp_pagamento != null &&
                  this.select_tp_pagamento != undefined &&
                  this.select_tp_pagamento != "" &&
                  this.select_form_pagamento != null &&
                  this.select_form_pagamento != undefined &&
                  this.select_form_pagamento != ""
                ) {
                  try {
                    this.converte_hora_dPag();
                    this.coverte_valor_tot_nfe();
                    let response = await http.post("/nfe/gerar", this.Nfe);
                    if (response.status == 200) {
                      console.log(response.data);
                      let msg = response.data;
                      //  console.log(response.data);
                      //this.makeToast('success',msg)
                      console.log("mostrando os valores do retornno da sefaz");
                      console.log(msg);
                      console.log(msg.protNFe.infProt);
                      this.makeToast("success", msg.protNFe.infProt.xMotivo);
                      if (msg.protNFe.infProt.cStat == 100) {
                        // console.log(msg.protNFe.infProt.xml_txt);
                        // console.log(msg.protNFe.infProt);
                        this.NfeAutorizadas(msg.protNFe.infProt);
                        setTimeout(() => {
                          this.getData();
                        }, 1000);
                      }
                    }
                  } catch (e) {
                    console.log(e.data);
                    this.makeToast("danger", e.data);
                    this.makeToast("danger", "verificar Impostos");
                  }
                } else {
                  this.makeToast(
                    "danger",
                    "Selecione o Tipo de Pagamento ou Forma de Pagamento"
                  );
                }
              } else {
                this.makeToast(
                  "danger",
                  "Selecione o CST do ICMS do Item e a Origem do Item "
                );
              }
            } else {
              this.makeToast("danger", "Preencha os valores de Destinatario");
            }
          } else {
            this.makeToast(
              "danger",
              "Selecione um Item e preencha os valores Unitarios"
            );
          }
        }
      }
    },
    async NfeAutorizadas(objeto) {
      objeto.empresa_id = this.currentEmpresa.id;
      objeto.status = 1;
      console.log(objeto);
      try {
        let response = await http.post("/nfe/logs", objeto);
        if (response.status == 200) {
          // console.log(response.data);
          // let msg = response.data;
          console.log(response.data);
          this.makeToast("success", "Registros salvos");
        }
      } catch (e) {
        console.log(e.data);
        this.makeToast("danger", e.data);
      }
    },
    async cancelarNfe() {
      try {
        let response = await http.get("/nfe/cancelar");
        if (response.status == 200) {
          // console.log(response.data);
          let msg = response.data;
          console.log(msg);
          this.makeToast("success", msg);
        } else {
          console.log(response.data);
        }
      } catch (e) {
        console.log(e.data);
        this.makeToast("danger", e.data);
      }
    },

    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
  },
};
</script>

<style  scoped>
.largura {
  width: 140px;
}

#tam {
  font-size: 23px;
}
.tama clasnho {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  width: 50%;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}
.cor {
  color: #d3d3d3;
}
/* .btn {
 
} */
.overflow-y-auto {
  overflow-y: auto; /* Adiciona barra de rolagem vertical quando o conteúdo ultrapassa a altura */
  max-height: 200px; /* Defina a altura máxima que deseja para a área de conteúdo */
}

.fonte {
  font-size: 11px;
}
.fonte-tab {
  font-size: 15px;
}

.cor2 {
  background-color: red;
}
</style>
