<script>
import appConfig from "@/app.config";
import { http } from '@/helpers/easyindustriaapi/config';
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import { listCFOPs } from "../../../../components/defaults/cfops";

export default {
  page: {
    title: "Alterar Compra",
    meta: [{name: "description", content: appConfig.description}]
  },
  props: {
    currentEmpresa: { type: Object },
    currentPedido: { type: Object },
    listTabelas: { type: Object },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      titleBody: "Alterar Pedido de Compra",
      submitted: false,
      pedido: {},
      listFornecedores: [
        {
          id: 0,
          name: 'Carregando...',
        }
      ],
      listTiposPagamento: [
        {
          id: 0,
          name: 'Dinheiro',
        }
      ],
      CFOPs: [],
      listHistoricosPadroes: [],
      listTpsMovimentos: [],
      listLocaisEstoque: [],
      fornecedorSelecionado: null,
      tipoPagamentoSelecionado: null,
      cfopSelecionado: null,
      historicoPdrSelecionado: null,
      tpMovimentoSelecionado: null,
      estoqueOrigemSelecionado: null,
      estoqueDestinoSelecionado: null,
    }
  },
  validations() {
    return {
      pedido: {
        nnf: { required },
        pessoa_id: { required },
        tipopgto_id: { required },
        qtde_parcelas: { required },
        historicopdr_id: { required },
        tpmovimento_id: { required },
        loc_id: { required },
      }
    }
  },
  mounted() {
    this.setNewTitle();

    // if (this.pedido.id)
    //   this.getData();

    var fornecedores = this.listTabelas.pessoas;
    this.CFOPs = listCFOPs;
    var tiposPagamento = this.listTabelas.tiposPagamento;
    var historicosPadroes = this.listTabelas.historicosPadroes;
    var tpsMovimentos = this.listTabelas.tpsMovimentos;
    var locaisEstoque = this.listTabelas.locaisEstoque;
    this.listFornecedores = fornecedores.map((item) => {
      return {
        name: item.pes_apelido,
        id: item.id,
        uf: item.pes_uf
      };
    });
    this.listTiposPagamento = tiposPagamento.map((item) => {
      return {
        name: item.tiponome,
        id: item.id
      };
    });
    this.listHistoricosPadroes = historicosPadroes.map((item) => {
      return {
        name: item.descricao,
        id: item.id
      };
    });
    this.listTpsMovimentos = tpsMovimentos.map((item) => {
      return {
        name: item.nometpmov,
        id: item.id,
        cfop: item.cfop,
        serie: item.serie,
      };
    });
    this.listLocaisEstoque = locaisEstoque.map((item) => {
      return {
        name: item.descricao,
        id: item.id,
        ind_est: item.ind_est,
      };
    });
    this.setPedido();
  },
  methods: {
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    setNewTitle() {
      this.$emit("newTitle", 'Alterar Pedido de Compra');
    },
    getData() {
      this.$emit('onLoader');
      http.get('/pedido/' + this.pedido.id + '/item');
      this.$emit('offLoader');
    },
    selectFornecedor(value) {
      this.pedido.pessoa_id = value ? value.id : null;
    },
    selectTipoPagamento(value) {
      this.pedido.tipopgto_id = value ? value.id : null;
    },
    selectCFOP(value) {
      this.pedido.cfop = value ? value.id : null;
    },
    selectHistoricoPdr(value) {
      this.pedido.historicopdr_id = value ? value.id : null;
    },
    selectTpMovimento(value) {
      this.pedido.tpmovimento_id = value ? value.id : null;
      var cfop = null;
      if (value) {
        if (this.currentEmpresa.uf === this.fornecedorSelecionado.uf) { 
          cfop = value.cfop.replace('*', '1').replace('.', '');
        } else {
          cfop = value.cfop.replace('*', '2').replace('.', '');
        }

        this.pedido.serie = value.serie;
        this.pedido.cfop = cfop;
        this.CFOPs.filter(item => {
          if (item.id === parseInt(cfop)) {
            this.cfopSelecionado = item;
          }
        });
      }
    },
    selectEstoqueOrigem(value) {
      this.pedido.loc_origem_id = value ? value.id : null;
      if (value) {
        this.listLocaisEstoque.filter(item => {
          if (item.id === value.id) {
            this.estoqueOrigemSelecionado = item;
          }
        });
      }
    },
    selectEstoqueDestino(value) {
      this.pedido.loc_id = value ? value.id : null;

      if (value) {
        this.listLocaisEstoque.filter(item => {
          if (item.id === value.id) {
            this.estoqueDestinoSelecionado = item;
          }
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    formPedido(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.pedido.$error)
      {
        this.makeToast('danger', 'Erro de validação!');
      } else {
        this.$emit("doPost", this.pedido);
      }
    },
    setPedido() {
      this.pedido = this.currentPedido;
      this.fornecedorSelecionado = {
        id: this.pedido.destinatario.id,
        name: this.pedido.destinatario.pes_apelido,
        uf: this.pedido.destinatario.pes_uf
      }
      this.tpMovimentoSelecionado = {
        name: this.pedido.tipomovimento.nometpmov,
        id: this.pedido.tipomovimento.id,
        cfop: this.pedido.tipomovimento.cfop,
        serie: this.pedido.tipomovimento.serie,
      };
      this.selectTpMovimento(this.tpMovimentoSelecionado);

      this.tipoPagamentoSelecionado = {
        name: this.pedido.tipopagamento.tiponome,
        id: this.pedido.tipopagamento.id
      };

      if (this.pedido.historicopadrao) {
        this.historicoPdrSelecionado = {
          name: this.pedido.historicopadrao.descricao,
          id: this.pedido.historicopadrao.id
        };
      }

      if (this.pedido.localestoque) {
        this.estoqueDestinoSelecionado = {
          name: this.pedido.localestoque.descricao,
          id: this.pedido.localestoque.id,
          ind_est: this.pedido.localestoque.ind_est
        };
      }

      if (this.pedido.localestoqueorigem) {
        this.estoqueOrigemSelecionado = {
          name: this.pedido.localestoqueorigem.descricao,
          id: this.pedido.localestoqueorigem.id,
          ind_est: this.pedido.localestoqueorigem.ind_est
        };
      }
    },
  },
}
</script>

<template>
  <div class="card">
    <div class="accordion" role="tablist">
      <form role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido-dados-gerais variant="light">Dados gerais</b-button>
          </b-card-header>
          <b-collapse id="pedido-dados-gerais" visible accordion="pedido-accordion" role="tabpanel">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="pedido_id" class="col-form-label">Código</label>
                    <div class="col">
                      <input disabled v-model="pedido.id" class="form-control text-right" type="text"
                        placeholder="Gerado pelo sistema" id="pedido_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="pessoa_id" class="col-form-label">Fornecedor</label>
                    <div class="col">
                      <multiselect v-model="fornecedorSelecionado" :options="listFornecedores" placeholder="Selecione"
                        track-by="id" deselect-label="Pressione ENTER para remover" label="name"
                        @input="selectFornecedor">
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{ option.name}}</strong>
                        </template>
                      </multiselect>
                      <b-form-invalid-feedback v-if="submitted && $v.pedido.pessoa_id.$error" :state="($v.pedido.pessoa_id.required)
                      ">
                        {{ $t('mesages.validation.selectone') }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="tpmovimento_id" class="col-form-label">Tipo de Movimento</label>
                    <div class="col">
                      <multiselect v-model="tpMovimentoSelecionado" :options="listTpsMovimentos" placeholder="Selecione"
                        track-by="id" deselect-label="Pressione ENTER para remover" label="name"
                        @input="selectTpMovimento">
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{ option.id }}-{{ option.name }}</strong>
                        </template>
                      </multiselect>
                      <b-form-invalid-feedback v-if="submitted && $v.pedido.tpmovimento_id.$error" :state="($v.pedido.tpmovimento_id.required)
                      ">
                        {{ $t('mesages.validation.selectone') }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="nnf" class="col-form-label">Nº Documento</label>
                    <div class="col">
                      <input v-model="pedido.nnf" type="text" class="form-control text-right" placeholder="1..9" id="nnf"
                       :class="{ 'is-invalid': submitted && $v.pedido.nnf.$error }">
                      <div v-if="submitted && $v.pedido.nnf.$error" class="invalid-feedback">
                        <span v-if="!$v.pedido.nnf.required"> {{ $t('mesages.validation.required') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                    <label for="serie" class="col-form-label">Série</label>
                    <div class="col">
                      <input v-model="pedido.serie" type="text" class="form-control text-right" placeholder="1..9" id="serie">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                    <label for="modelnf" class="col-form-label">Modelo</label>
                    <div class="col">
                      <input v-model="pedido.modelnf" type="text" class="form-control text-right" placeholder="1, 55, 65" id="modelnf">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <label for="cfop" class="col-form-label">C.F.O.P.</label>
                    <div class="col">
                      <multiselect v-model="cfopSelecionado" :options="CFOPs" placeholder="Selecione"
                        track-by="id" deselect-label="Pressione ENTER para remover" select-label="Pressione ENTER"
                        label="id" @input="selectCFOP">
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{option.id}}-{{option.name}}</strong>
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="dataemissao" class="col-form-label">Emissão</label>
                    <div class="col">
                      <input v-model="pedido.dataemissao" type="datetime-local" class="form-control text-right" id="dataemissao">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="dh_saient" class="col-form-label">Entrada</label>
                    <div class="col">
                      <input v-model="pedido.dh_saient" type="datetime-local" class="form-control text-right" id="dh_saient">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="tipopgto_id" class="col-form-label">Forma de Pagamento</label>
                    <div class="col">
                      <multiselect v-model="tipoPagamentoSelecionado" :options="listTiposPagamento" placeholder="Selecione"
                        track-by="id" deselect-label="Pressione ENTER para remover" label="name"
                        @input="selectTipoPagamento">
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{ option.id }}-{{ option.name }}</strong>
                        </template>
                      </multiselect>
                      <b-form-invalid-feedback v-if="submitted && $v.pedido.tipopgto_id.$error" :state="($v.pedido.tipopgto_id.required)
                      ">
                        {{ $t('mesages.validation.selectone') }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                    <label for="qtde_parcelas" class="col-form-label">Qtde Parcelas</label>
                    <div class="col">
                      <input v-model="pedido.qtde_parcelas" type="text" class="form-control text-right" placeholder="1..9" id="qtde_parcelas"
                       :class="{ 'is-invalid': submitted && $v.pedido.qtde_parcelas.$error }">
                      <div v-if="submitted && $v.pedido.qtde_parcelas.$error" class="invalid-feedback">
                        <span v-if="!$v.pedido.qtde_parcelas.required"> {{ $t('mesages.validation.required') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="historicopdr_id" class="col-form-label">Histórico Padrão</label>
                    <div class="col">
                      <multiselect v-model="historicoPdrSelecionado" :options="listHistoricosPadroes" placeholder="Selecione"
                        track-by="id" deselect-label="Pressione ENTER para remover" label="name"
                        @input="selectHistoricoPdr">
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{ option.id }}-{{ option.name }}</strong>
                        </template>
                      </multiselect>
                      <b-form-invalid-feedback v-if="submitted && $v.pedido.historicopdr_id.$error" :state="($v.pedido.historicopdr_id.required)
                      ">
                        {{ $t('mesages.validation.selectone') }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="loc_origem_id" class="col-form-label">Estoque origem</label>
                    <div class="col">
                      <multiselect v-model="estoqueOrigemSelecionado" :options="listLocaisEstoque" placeholder="Selecione"
                        track-by="id" deselect-label="Pressione ENTER para remover" label="name"
                        @input="selectEstoqueOrigem" select-label="Pressione ENTER para selecionar">
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{ option.id }}-{{ option.name }}</strong>
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="loc_id" class="col-form-label">Estoque Destino</label>
                    <div class="col">
                      <multiselect v-model="estoqueDestinoSelecionado" :options="listLocaisEstoque" placeholder="Selecione"
                        track-by="id" deselect-label="Pressione ENTER para remover" label="name"
                        @input="selectEstoqueDestino">
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{ option.id }}-{{ option.name }}</strong>
                        </template>
                      </multiselect>
                      <b-form-invalid-feedback v-if="submitted && $v.pedido.loc_id.$error" :state="($v.pedido.loc_id.required)
                      ">
                        {{ $t('mesages.validation.selectone') }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido-complementares variant="light">Complementares</b-button>
          </b-card-header>
          <b-collapse id="pedido-complementares">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="infcpl" class="col-form-label">Informações complementares</label>
                    <div class="col">
                      <b-form-textarea v-model="pedido.infcpl"
                        id="infcpl"
                        size="sm"
                        placeholder="Digite aqui..."
                      ></b-form-textarea>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="chnfe" class="col-form-label">Chave da NF-e</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="chnfe">
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
          <b-card-header v-if="this.pedido.id" header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido-itens variant="light">Itens</b-button>
          </b-card-header>
          <b-collapse v-if="this.pedido.id" id="pedido-itens" visible accordion="pedido-accordion" role="tabpanel">
          </b-collapse>
        </b-card>
      </form>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click="formPedido">Gravar pedido</button>
      </div>
    </div>
  </div>
</template>