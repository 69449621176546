<script>
import appConfig from "@/app.config";
import { FormWizard, TabContent } from "vue-form-wizard";
import {
  authEasyMethods,
  notificationMethods
} from "@/state/helpers";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Entrada de Insumos por pesagem",
    meta: [{name: "description", content: appConfig.description}]
  },
  components: { FormWizard, TabContent, Multiselect },
  property: {
    newCompra: { type: Object },
    oldItems: {},
    listTabelas: {},
  },
  data() {
    return {
      loader: {
        get: false
      },
      currentUser: null,
      currentEmpresa: null,
      submitted: false,
      currentCompra: {
        pessoa_id: required
      },
      titleBody: "Listagem das Compras",
      pessoaSelecionada: null,
      listPessoas: [],
    }
  },
  created() {
    this.currentUser    = JSON.parse(localStorage.getItem('user'));
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
  },
  mounted() {
    console.log(this.listTabelas);
    var fornecedores = this.listTabelas.fornecedores;
    this.listPessoas = fornecedores.map((item) => {
      return {
        name: item.pes_apelido,
        id: item.id
      }
    });
  },
  computed: {
    // notification() {
    //   return this.$store ? this.$store.state.notification : null;
    // },
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    selectPessoa(value) {
      this.currentCompra.pessoa_id = value ? value.id : null;
    },
  },
}
</script>

<template>
  <div class="card">
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <form-wizard shape="tab" color="#556ee6">
          <tab-content icon="mdi mdi-account-circle">
            <div class="row">
              <div class="col-12">
                <div class="form-group row mb-3">
                  <label for="pessoa_id" class="col-md-3 col-form-label">Fornecedor:</label>
                  <div class="col-md-9">
                    <multiselect v-model="pessoaSelecionada" :options="listPessoas" placeholder="Selecione"
                      track-by="id" deselect-label="Pressione ENTER para remover" label="pessoa_id"
                      @input="selectPessoa">
                      <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                      <span slot="noOptions">
                        Deu ruim! Nenhum registro para a lista
                      </span>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
          </tab-content>
          <tab-content icon="mdi mdi-face-profile">Tab 2</tab-content>
          <tab-content icon="mdi mdi-checkbox-marked-circle-outline">Tab 3</tab-content>
          <button slot="prev" class="btn btn-primary">Voltar</button>
          <button slot="next" class="btn btn-primary">Avançar</button>
          <button slot="finish" class="btn btn-primary">Finalizar</button>
        </form-wizard>
      </b-card>
    </div>
  </div>
</template>