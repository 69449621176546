<template>
  <b-modal
    v-model="show_modal"
    size="xl"
    title="Carta de Correção NF-e"
    hide-footer
    @hidden="back"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <widgetCarta :objeto="carta_objeto" />
                <Label class="fonte"
                  ><b-badge
                    data-name="Total"
                    class="field-status"
                    variant="info"
                  >
                    {{ "*Carta de Correção :" }}
                  </b-badge></Label
                >
                <textarea
                  v-model="texto"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  class="form-control text-left"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-md-12 text-center">
        <div class="card">
          <div class="card-body">
            <button class="btn btn-secondary" @click.prevent="enviar()">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import widgetCarta from "./widgetCarta.vue"
export default {
  props: {
    modal: { type: Boolean },
    carta: { type: Object },
  },
  components:{
    widgetCarta
  },
  data() {
    return {
      show_modal: false,
      carta_objeto: null,
      texto: null,
    };
  },
  created() {
    this.show_modal = this.modal;
    this.carta_objeto = this.carta;
  },
  mounted() {},
  methods: {
    back() {
      this.$emit("back");
    },
    enviar() {
      this.carta_objeto.texto = this.texto;
      this.$emit("cartaCorrecao", this.carta_objeto);
    },
  },
};
</script>

<style  scoped>
.fonte {
  font-size: 20px;
}
</style>
