<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <b-table
            striped
            hover
            :items="certificados"
            responsive
            id="doc-itens"
            :fields="fields"
          >
            <template #cell(excluir)="row">
              <button
                @click="seleciona_certificado(row.item)"
                class="btn btn-success"
              >
                Selecionar
              </button>
              <button
                @click="deleta_certificado(row.item)"
                class="btn btn-danger ml-2"
              >
                Excluir
              </button>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    array_certificados: { type: Array },
  },
  data() {
    return {
      certificados: [],
      fields: [
        {
          label: "Código",
          key: "id",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Nome",
          key: "nome",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Razão",
          key: "razao",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "CNPJ",
          key: "cnpj",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Uf",
          key: "uf",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Ações",
          key: "excluir",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
    };
  },
  created() {
    this.certificados = this.array_certificados;
  },

  methods: {
    seleciona_certificado(objeto) {
      console.log("estou no certificado");
      console.log(objeto);
      this.makeToast('info','Selecionado o certificado ' + objeto.nome)

      this.post_certificado(objeto)
    },
    deleta_certificado(objeto){
        this.$emit('deleta_certificado',objeto)
    },
    post_certificado(objeto){
      this.$emit('post_certificado',objeto)
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
  },
};
</script>

<style>
</style>